import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import {
  UncontrolledTooltip,
  Button,
  FormFeedback,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from 'reactstrap';
import { toast, Zoom } from 'react-toastify';
import { verifyEmailNotInUse,verifyPhoneNotInUse } from 'utils/API';
import ImageUpload from './ImageUpload.js';
import Validator from 'utils/Validator.js';
const AddProduct = (props) => {
  return (
    <>
      <div className="main">
        <Container className="p-3">
          <h3>Register new Member</h3>
          <br></br>
          <div>
            <Row>
              <Col md="5" sm="5">
                <h6 >
                  Member's Image<span className="icon-danger">* </span>{' '}
                
                <FontAwesomeIcon
                    icon={['fas', 'question-circle']}
                    id={'userImage'}
                  />
                  <UncontrolledTooltip
                    trigger="hover"
                    popperClassName="tooltip-second"
                    placement="right"
                    container="body"
                    target="userImage">
                    You must submit an up to date photo. Any member under 17 will need a new photo submitted every renewal period (12 months)
                  </UncontrolledTooltip>
                  </h6>
                <ImageUpload setImage={props.setImage} />
              </Col>
              <Col md="6">
                <h6>
                  Member's Full Name <span className="icon-danger">* </span>
                  <FontAwesomeIcon
                    icon={['fas', 'question-circle']}
                    id={'usernamesTT'}
                  />
                  <UncontrolledTooltip
                    trigger="hover"
                    popperClassName="tooltip-second"
                    placement="right"
                    container="body"
                    target="usernamesTT">
                    Enter the New Member's First and Last Name (required)
                  </UncontrolledTooltip>
                </h6>

                <h6 className="m-1">
                  First <span className="icon-danger">*</span>
                </h6>

                <Input
                  className="border-input" required
                  value={props.first}
                  defaultValue={''}
                  onChange={(e) => {
                    props.setFirst(e.target.value);
                  }}
                  placeholder="First Name"
                  type="text"
                />
                <h6 className="m-1">
                  Last <span className="icon-danger">*</span>
                </h6>
                <Input
                  className="border-input"
                  value={props.last}
                  defaultValue={''}
                  onChange={(e) => {
                    props.setLast(e.target.value);
                  }}
                  placeholder="Last Name"
                  type="text"
                />

                <Button
                  block
                  className="btn-round mt-3"
                  color="danger"
                  outline
                  type="reset"
                  onClick={() => {
                    window.location = `/Members`;
                  }}>
                  Cancel
                </Button>
              </Col>
              <Col md="12" sm="12">
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="exampleSelect"
                        className="m-1"
                        style={{ fontWeight: 'bold' }}>
                        Sex <span className="icon-danger">* </span>{' '}
                        <FontAwesomeIcon
                          icon={['fas', 'question-circle']}
                          id={'currencyToolTip'}
                        />
                        <UncontrolledTooltip
                          trigger="hover"
                          popperClassName="tooltip-second"
                          placement="right"
                          container="body"
                          target="currencyToolTip">
                          The Sex of the New member (required)
                        </UncontrolledTooltip>
                      </Label>
                      <Input
                        type="select"
                        name="select"
                        value={props.sex}
                        onChange={(e) => {
                          props.setSex(e.target.value);
                        }}
                        id="exampleSelect">
                        <option value="-1">Select...</option>
                        <option value={'M'}>Male</option>
                        <option value={'F'}>Female</option>
                        <option value={'O'}>Other</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label style={{ fontWeight: 'bold' }}>
                        Date of Birth <span className="icon-danger">* </span>
                      </Label>
                      <InputGroup style={{ border: '#000' }}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <FontAwesomeIcon icon={['far', 'calendar-alt']} />
                          </InputGroupText>
                        </InputGroupAddon>
                        <DatePicker
                          className="form-control"
                          placeholderText="MM / DD / YYYY"
                          maxDate={Date.now()}
                          selected={props.dob}
                          onChange={(date) => {
                            props.setDob(date);
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label className="font-weight-bold" for="">
                        Feet <span className="icon-danger">* </span>{' '}
                      </Label>
                      <Input
                        type="select"
                        name="fm-feet"
                        id="fm-feet"
                        onChange={(e) => {
                          
                          if(e.target.value)
                            props.setFeet(e.target.value)
                        
                        
                        }}
                        value={props.feet}
                        required>
                        <option value="-1">Select...</option>
                        <option value="7">7'</option>
                        <option value="6">6'</option>
                        <option value="5">5'</option>
                        <option value="4">4'</option>
                        <option value="3">3'</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label className="font-weight-bold" for="">
                        Inches <span className="icon-danger">* </span>{' '}
                      </Label>
                      <Input
                        type="select"
                        name="fm-inches"
                        id="fm-inches"
                        onChange={(e) =>{
                          
                          if(e.target.value)
                            props.setInches(e.target.value)
                        
                        }}
                        value={props.inches}
                        required>
                        <option value="0">Select...</option>
                        <option Value="0">0"</option>
                        <option value="1">1"</option>
                        <option value="2">2"</option>
                        <option value="3">3"</option>
                        <option value="4">4"</option>
                        <option value="5">5"</option>
                        <option value="6">6"</option>
                        <option value="7">7"</option>
                        <option value="8">8"</option>
                        <option value="9">9"</option>
                        <option value="10">10"</option>
                        <option value="11">11"</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <Label className="font-weight-bold" for="">
                      Weight <span className="icon-danger">* </span>{' '}
                    </Label>
                    <FormGroup>
                      <Input
                        type="number"
                        name="fm-weight"
                        id="fm-weight"
                        placeholder="Weight..."
                        required
                        value={props.weight}
                        onChange={(e) => {
                          props.setWeight(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <h6 className="m-1 text-primary">Email (optional) </h6>

                    <Input placeholder="Email" type="mail" value  = {props.mail} onChange = { (e) => {
                    
                      props.setMail(e.target.value); 
                      

                    
                    
                    }} invalid = {!Validator.validateEmail(props.mail) } />
                              {!Validator.validateEmail(props.mail)  ? <FormFeedback>Invalid email</FormFeedback> :null}
                              
                  
                  </Col>

                  <Col xl="6">
                    <h6 className="m-1 text-primary">Phone (optional)</h6>

                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>+1</InputGroupText>
                      </InputGroupAddon>

                      <Input placeholder="Phone number" type="number" value  = {props.phone} onChange = {(e) => {
                     
                      props.setPhone(e.target.value); 
                  
                    }} invalid = {!Validator.validatePhoneNumber(props.phone) } />
                              {!Validator.validatePhoneNumber(props.phone)  ? <FormFeedback>Invalid Phone number </FormFeedback> :null}
                             
                    </InputGroup>
                  </Col>
                </Row>

                <FormGroup className="mt-3">
                  <h5>
                    Additional notes or notices about this person (optional)
                  </h5>
                  <Input
                    className="textarea-limited"
                    maxLength="150"
                    placeholder="Please list all triggers and possible draws this member may have. 
                    Please list things like door codes or key placements as necessary."
                    rows="7"
                    type="textarea"
                    defaultValue={''}
                    value={props.additional}
                    onChange={(e) => {
                      props.setAdditional(e.target.value);
                    }}
                  />
                  <h5>
                    <small>
                      <span
                        className="pull-right"
                        id="textarea-limited-message">
                        150 characters Max
                      </span>
                    </small>
                  </h5>
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};


export default withRouter(AddProduct);
