import React from 'react';

import { Container } from 'reactstrap';
import { PageTitle } from '../../layout-components';


import ProfileUserDetails from '../../example-components/Profile/ProfileUserDetails';

export default function Profile() {
  return (
    <>
      <PageTitle
        titleHeading="My Profile"
        titleDescription="Manage all aspects of your profile data using this example page.">
        
      </PageTitle>
      <Container>
        
        <ProfileUserDetails />
       
      </Container>
    </>
  );
}
