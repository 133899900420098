import React from 'react';

import PageRecoverCover1 from '../../example-components/PageRecoverCover/PageRecoverCover1';
export default function PageRecoverCover() {
  return (
    <>
      <PageRecoverCover1 />
    </>
  );
}
