import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import defaultImage from "../../../assets/images/placeholder.jpg";
import defaultAvatar from "../../../assets/images/placeholder.jpg";

function ImageUpload(props) {
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.avatar ? defaultAvatar : defaultImage
  );
  const fileInput = React.createRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {

      
      setFile(file);
      props.setImage(file)
      
      if(props.setImageChanged) 
        props.setImageChanged(true) 
      
        setImagePreviewUrl(reader.result);
    };
    if(e.target.files[0])
      reader.readAsDataURL(file);
  
  };
  // const handleSubmit = e => {
  // e.preventDefault();
  // this.state.file is the file/image uploaded
  // in this function you can save the image (this.state.file) on form submit
  // you have to call it yourself
  // };
  const handleClick = () => {
    fileInput.current.click();

  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
    props.setImage(null)
    if(props.setImageChanged) 
        props.setImageChanged(true) 
  };
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={"thumbnail" + (props.avatar ? " img-circle" : "")}>
        <img src={file ? imagePreviewUrl : props.image ? props.image : imagePreviewUrl} alt="..." style={{maxHeight : '170px', maxWidth:'auto'}} />
      </div>
      <div>
        {file === null ? (
          <Button
            className="btn-round"
            color="primary"
            outline
            onClick={handleClick}
          >
            {props.avatar ? "Add Photo" : "Select image"}
          </Button>
        ) : (
          <span>
            <Button
              className="btn-round m-2"
              outline
              color="primary"
              onClick={handleClick}
            >
              Change
            </Button>
            {props.avatar ? <br /> : null}
            <Button
              color="danger"
              className="btn-round btn-link m-2"
              onClick={handleRemove}
            >
              <i className="fa fa-times" />
              Remove
            </Button>
          </span>
        )}
      </div>
    </div>
  );
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
};

export default ImageUpload;
