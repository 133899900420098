module.exports = {
  validateEmail(email) {
    if (!email) return true;

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  validateZipCode(zip) {
    if (!zip || zip == null) return true;

    var zipRe = /^[0-9]{5}(?:-[0-9]{4})?$/;

    return zipRe.test(zip);
  },

  validatePhoneNumber(p) {
    if (!p) return true;

    var phoneRe = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    return phoneRe.test(p);
  },
  validatePasswordRules(password) {
    if (!password) return { isValid: true };

    let issues = [];

    var re = {
      capital: /[A-Z]/,
      digit: /[0-9]/,

      full: /^[A-Za-z0-9]{7,14}$/
    };

    if (!re.capital.test(password)) {
      issues.push('Password Must contain a minimum of 1 capitalized character');
    }
    if (!re.digit.test(password)) {
      issues.push('Password Must contain a minimum of 1 digit');
    }
    if (!re.full.test(password)) {
      issues.push('Password Must be 8 - 14 characters long');
    }

    if (
      !re.capital.test(password) ||
      !re.digit.test(password) ||
      !re.full.test(password)
    ) {
      return {
        isValid: false,
        messages: issues
      };
    } else {
      return { isValid: true };
    }
  },

  passwordsMatch: (one, two) => {
    if (!one || !two) return true;

    if (one === two) return true;

    return false;
  }
};
