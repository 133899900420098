import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import {
  UncontrolledTooltip,
  Button,
  FormFeedback,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,Modal
} from 'reactstrap';
import Validator from 'utils/Validator'
import { BounceLoader } from 'react-spinners';
import {connect} from 'react-redux'
import ImageUpload from '../MembersAdd/ImageUpload';
import {useParams} from 'react-router-dom'
import {uploadImage, updateAMember,verifyPhoneNotInUse,verifyEmailNotInUse} from 'utils/API';
import { toast, Zoom } from 'react-toastify';
import {updateMember} from '../../../reducers/Actions/membersActions'
import { isEmpty, set } from 'lodash';
//import redux updater for member



const AddProduct = (props) => {

      // for user info
  const [modal4, setModal4] = React.useState(false);
  const toggle4 = () => setModal4(!modal4);
  const [imgChange, setImageChanged] = React.useState(false);
  const [first, setFirst] = React.useState('');
  const [last, setLast] = React.useState('');
  const [sex, setSex] = React.useState('M');
  const [dob, setDob] = React.useState(Date.now());
  const [feet, setFeet] = React.useState('1');
  const [inches, setInches] = React.useState('0');
  const [weight, setWeight] = React.useState('');
  const [mail, setMail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [image, setImage] = React.useState('');
  const [additional, setAdditional] = React.useState('');
  const [isLoading, setLoad] = React.useState(false);
  const [Medical, setMed] = React.useState({});
  const [allData, setAllData] = React.useState(null)
  

    let { id } = useParams();

    const setMedical = (obj) => {

      
      for (const property in obj) {


        if (obj.hasOwnProperty(property)) {
          //Now, object[key] is the current value
          if ( obj[property] === false){

            console.log(property)
              delete obj[property];
          }
      }
 }

 setMed(obj)
}

    const checkForChanges = () => {

    if(allData ){
    

      if( 
         first.toLowerCase() == allData.First.toLowerCase()  && 
      
         last.toLowerCase()  == allData.Last.toLowerCase()   &&

         sex.toLowerCase()   == allData.Sex.toLowerCase()    &&

        ( (!isEmpty(allData.Mail) && !isEmpty(mail)) && mail.toLowerCase()   == allData.Mail.toLowerCase() || allData.Mail == mail)  &&

                      dob    == allData.Dob                  &&   

                      feet   == allData.Feet                 &&

                      inches == allData.Inches               &&

                      weight == allData.Weight               &&

                      phone == allData.Phone                 &&

                      image == allData.img                   &&

                      JSON.stringify(Medical) === JSON.stringify(allData.Medical) ||

                      (!isEmpty(mail) && !Validator.validateEmail(mail)) || (!isEmpty(phone) && !Validator.validatePhoneNumber(phone))
                      
      ){
        
      
      return true
      }
      
    }
    return false
  



    }

 

    const fetchMemberDetails = () => {
    if (props.Members && Array.isArray(props.Members)){

        
        const memberData = props.Members.filter(item => item.ID === id)
      
        const data = memberData[0]

        if(!data.hasOwnProperty("Mail"))
          data.Mail = ''
        
          


        if(!data.hasOwnProperty("Phone"))
          data.Phone = ''
        
        
        let d = new Date(data.Dob)
        setAllData({...data, Dob : d})
        setFirst(data.First)
        setLast(data.Last)
        setSex(data.Sex)
        setDob(d)
        setFeet(data.Feet)
        setInches(data.Inches)
        setWeight(data.Weight)
        setMail(data.Mail)
        setPhone(data.Phone)
        setImage(data.img)
        setMedical(data.Medical)
        
        
      }
    }
   
   React.useEffect(() => {fetchMemberDetails()},[])

   console.log(first,last)
  return (
    <>
      <div className="main">
        <Container className="p-3">
          <h3>Register new Member</h3>
          <br></br>
          <div>
            <Row>
              <Col md="5" sm="5">
                <ImageUpload image = {image} setImage={setImage}  setImageChanged={setImageChanged}/>
              </Col>
              <Col md="6">
                <h6>
                  Members Full Name <span className="icon-danger">* </span>
                  <FontAwesomeIcon
                    icon={['fas', 'question-circle']}
                    id={'usernamesTT'}
                  />
                  <UncontrolledTooltip
                    trigger="hover"
                    popperClassName="tooltip-second"
                    placement="right"
                    container="body"
                    target="usernamesTT">
                    Enter the New Members First and Last Name (required)
                  </UncontrolledTooltip>
                </h6>

                <h6 className="m-1">
                  First <span className="icon-danger">*</span>
                </h6>

                <Input
                  className="border-input"
                  value={first}
                  defaultValue={''}
                  onChange={(e) => {
                    setFirst(e.target.value);
                  }}
                  placeholder="First Name"
                  type="text"
                />
                <h6 className="m-1">
                  Last <span className="icon-danger">*</span>
                </h6>
                <Input
                  className="border-input"
                  value={last}
                  defaultValue={''}
                  onChange={(e) => {
                    setLast(e.target.value);
                  }}
                  placeholder="Last Name"
                  type="text"
                />

                <Button
                  block
                  className="btn-round mt-3"
                  color="danger"
                  outline
                  type="reset"
                  onClick={() => {
                    window.location = `/Members`;
                  }}>
                  Cancel
                </Button>
              </Col>
              <Col md="12" sm="12">
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label
                        htmlFor="exampleSelect"
                        className="m-1"
                        style={{ fontWeight: 'bold' }}>
                        Sex <span className="icon-danger">* </span>{' '}
                        <FontAwesomeIcon
                          icon={['fas', 'question-circle']}
                          id={'currencyToolTip'}
                        />
                        <UncontrolledTooltip
                          trigger="hover"
                          popperClassName="tooltip-second"
                          placement="right"
                          container="body"
                          target="currencyToolTip">
                          The Sex of the New member (required)
                        </UncontrolledTooltip>
                      </Label>
                      <Input
                        type="select"
                        name="select"
                        value={sex}
                        onChange={(e) => {
                          setSex(e.target.value);
                        }}
                        id="exampleSelect">
                        <option value={'M'}>Male</option>
                        <option value={'F'}>Female</option>
                        <option value={'O'}>Other</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label style={{ fontWeight: 'bold' }}>
                        Date of Birth <span className="icon-danger">* </span>
                      </Label>
                      <InputGroup style={{ border: '#000' }}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <FontAwesomeIcon icon={['far', 'calendar-alt']} />
                          </InputGroupText>
                        </InputGroupAddon>
                        <DatePicker
                          className="form-control"
                          selected={dob}
                          onChange={(date) => {
                           setDob(date);
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label className="font-weight-bold" for="">
                        Feet
                      </Label>
                      <Input
                        type="select"
                        name="fm-feet"
                        id="fm-feet"
                        onChange={(e) => setFeet(e.target.value)}
                        value={feet}
                        required>
                        <option value="1">1'</option>
                        <option value="2">2'</option>
                        <option value="3">3'</option>
                        <option value="4">4'</option>
                        <option value="5">5'</option>
                        <option value="6">6'</option>
                        <option value="7">7'</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label className="font-weight-bold" for="">
                        Inches
                      </Label>
                      <Input
                        type="select"
                        name="fm-inches"
                        id="fm-inches"
                        onChange={(e) => setInches(e.target.value)}
                        value={inches}
                        required>
                        <option Value="0">0"</option>
                        <option value="1">1"</option>
                        <option value="2">2"</option>
                        <option value="3">3"</option>
                        <option value="4">4"</option>
                        <option value="5">5"</option>
                        <option value="6">6"</option>
                        <option value="7">7"</option>
                        <option value="8">8"</option>
                        <option value="9">9"</option>
                        <option value="10">10"</option>
                        <option value="11">11"</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <Label className="font-weight-bold" for="">
                      Weight
                    </Label>
                    <FormGroup>
                      <Input
                        type="text"
                        name="fm-weight"
                        id="fm-weight"
                        placeholder="Weight..."
                        required
                        value={weight}
                        onChange={(e) => {
                          setWeight(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <h6 className="m-1 text-primary">Email (optional) </h6>

                    <Input  placeholder="Email" type="mail" value  = {mail} onChange = {async (e) => {
                     
                      setMail(e.target.value); 


                    
                    
                    }} invalid = {mail !== "x" && !Validator.validateEmail(mail) } />
                              {mail !== "x" && !Validator.validateEmail(mail)  ? <FormFeedback>Invalid email</FormFeedback> :null}
                             
                  </Col>

                  <Col xl="6">
                    <h6 className="m-1 text-primary">Phone (optional)</h6>

                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>+1</InputGroupText>
                      </InputGroupAddon>

                      <Input placeholder="Phone number" type="number" value  = {phone} onChange = {async (e) => {
                     
                      setPhone(e.target.value); 

                    }} invalid = {phone !== "x" && !Validator.validatePhoneNumber(phone) } />
                              {phone !== "x" && !Validator.validatePhoneNumber(phone)  ? <FormFeedback>Invalid Phone number </FormFeedback> :null}
                             
                    </InputGroup>
                  </Col>
                </Row>

                <FormGroup className="mt-3">
                  <h5>
                    Additional notes or notices about this person (optional)
                  </h5>
                  <Input
                    className="textarea-limited"
                    maxLength="150"
                    placeholder="Additional information here..."
                    rows="7"
                    type="textarea"
                    defaultValue={''}
                    value={additional}
                    onChange={(e) => {
                     setAdditional(e.target.value);
                    }}
                  />
                  <h5>
                    <small>
                      <span
                        className="pull-right"
                        id="textarea-limited-message">
                        150 characters Max
                      </span>
                    </small>
                  </h5>
                </FormGroup>
              </Col>
            </Row>
          </div>

          <div className="main">
          <Container  className= "p-3">
          <h2>Medical Information</h2>
          <h5>Special Needs and/or Disabilities: (check all that apply)</h5>
          <Row>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" checked={Medical.DNR} onChange = {(e) => {  
                  
                 
                      setMedical({...Medical, "DNR" : e.target.checked});
                  
                  
                  }} />
                Do Not Resuscitate (DNR) 

                
                <FontAwesomeIcon
                    icon={['fas', 'info-circle']}
                    id={'dnrTT'}
                    className="ml-2"
                  />
                  <UncontrolledTooltip
                    trigger="hover"
                    popperClassName="tooltip-second"
                    placement="right"
                    container="body"
                    target="dnrTT">
                    <b className="float-left">Requirements :</b> 
                    <br></br>
                    <br></br>
                    <ul>
                      <li className="float-left">You must have all paperwork present </li>
                      <br></br>
                      <br></br>
                      <li className="float-left "> Your paperwork must comply with Michigan's Do Not Resuscitate procedure act</li>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br><br></br>
                    </ul>
                  </UncontrolledTooltip>
              </Label>
            </Col>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" checked={Medical.AutismASD} onChange = {(e) => { 
                  
                 
                    setMedical({...Medical, "AutismASD" :  e.target.checked});
                  
                  
                  }}/>
                Autism/ASD
              </Label>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" checked={Medical.Bipolar}  onChange = {(e) => { 
                  
                

                    setMedical({...Medical, Bipolar :  e.target.checked})

                
                    
                    }}/>
                Bipolar Disorder
              </Label>
            </Col>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" checked={Medical.BrainInjury } onChange = {(e) => {    
                  
                  

                    setMedical({...Medical, "BrainInjury" :  e.target.checked});
                   
                }} />
                Brain Injury / TBI
              </Label>
            </Col>
          </Row>
          <Row>
          <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" checked={Medical.HearingImpairment} onChange = {(e) => {   

                    
                      setMedical({...Medical, "HearingImpairment" :  e.target.checked});
                   
                   
                   }}/>
                Hearing Impairments
              </Label>
            </Col>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" checked={Medical.MedicalPumps } onChange = {(e) => {  
                  
                  
                      setMedical({...Medical, "MedicalPumps" :  e.target.checked});
                  
                  
                  }} />
                Medical Pumps / Ventilators
              </Label>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" checked={Medical.LimbsMissingOrPartiallyMissing } onChange = {(e) => {   
                  
                 
                    setMedical({...Medical, "LimbsMissingOrPartiallyMissing" :  e.target.checked});
                  
                  
                  }}/>
                Limb(s) missing or partially missing
              </Label>
            </Col>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" checked={Medical.VisuallyImpaired } onChange = {(e) => {   
                  
                  
                     setMedical({...Medical, "VisuallyImpaired" :  e.target.checked});
                  
                  
                  }}/>
                Visually Impaired
              </Label>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" checked={Medical.MobilityImpairmentRequiringWheelChair } onChange = {(e) => {    

                    
                      setMedical({...Medical, "MobilityImpairmentRequiringWheelChair" :  e.target.checked});
                  
                  }} />
                Mobility impairment(s) 

                <FontAwesomeIcon
                    icon={['fas', 'info-circle']}
                    id={'mobileTT'}
                    className="ml-2"
                  />
                  <UncontrolledTooltip
                    trigger="hover"
                    popperClassName="tooltip-second"
                    placement="right"
                    container="body"
                    target="mobileTT">
                    requiring wheel chair
                  </UncontrolledTooltip>
              </Label>
            </Col>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" checked={Medical.MentalHealthProblem } onChange = {(e) => {  
                  
                    setMedical({...Medical, "MentalHealthProblem" :  e.target.checked});
                  
                  
                  }} />
                Mental health problems 

                <FontAwesomeIcon
                    icon={['fas', 'info-circle']}
                    id={'mhTT'}
                    className="ml-2"
                  />
                  <UncontrolledTooltip
                    trigger="hover"
                    popperClassName="tooltip-second"
                    placement="right"
                    container="body"
                    target="mhTT">
                    <b className="float-left">Including :</b> 
                    <br></br>
                    <ul>
                      <li className="float-left">Bi-polar </li>
                      <br></br>
                      <li className="float-left"> PTSD </li>
                      <br></br>
                      <li className="float-left"> schizophrenia </li> 
                      <br></br>
                      <li className="float-left"> violent tendencies</li>
                      <br></br>
                    </ul>
                  </UncontrolledTooltip>


              </Label>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" checked={Medical.NonVerbal } onChange = {(e) => {    
                  
                    setMedical({...Medical, "NonVerbal" :  e.target.checked});
                  
                  }}/>
                Non-Verbal (unable to speak)
              </Label>
            </Col>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" checked={Medical.Obesity } onChange = {(e) => {   
                  

                  
                    setMedical({...Medical, "Obesity" :  e.target.checked});
                  
                  }} />
                Obesity (over 250lbs)
              </Label>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" checked={Medical.AlzheimersDementia} onChange = {(e) => {    
                  
                  
                    setMedical({...Medical, "AlzheimersDementia" :  e.target.checked});
                  
                  }} />
                Alzheimer's or Dementia
              </Label>
            </Col>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" checked={Medical.Other} onChange = {(e) => {    
                  
                 
                    setMedical({...Medical, "Other" :  e.target.checked});
                  
                  
                  }} /> 
                Other
                <Input
                  type="textarea"
                  name="other"
                  id="other"
                  placeholder="door code, location and combination of hide-a-key ect..."
                />
              </Label>
            </Col>
          </Row>
        </Container>
        <Button disabled= {checkForChanges() } color="primary" className= "btn-block m-4 w-50 mx-auto"   onClick={toggle4}>Update Member info</Button>

<Modal zIndex={2000} centered isOpen={modal4} toggle={toggle4}>
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-first text-first m-0 d-130">
                <FontAwesomeIcon
                  icon={['far', 'keyboard']}
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">
              Are you sure you want to confirm the updated information for this member ?
            </h4>
            <p className="mb-0 text-black-50">
              You can always change it later.
            </p>
            {isLoading ? (
              <div
                className="d-flex mx-auto align-items-center justify-content-center"
                style={{ width: '150px', height: '80px' }}>
                <BounceLoader color={'var(--primary)'} loading={true} />
              </div>
            ) : null}
            <div className="pt-4">
              <Button
                onClick={toggle4}
                color="neutral-secondary"
                className="btn-pill text-danger mx-1">
                <span className="btn-wrapper--label">Cancel</span>
              </Button>
              <Button
                color="first"
                className="btn-pill mx-1"
               
                onClick={async () => {

                  //AD MORE CHECKS HERE FOR PHONE, EMAIL AND WHAT NOT
                    setLoad(true);
                
                    const updatedMemberData = {
                      Parent_ID: props.UID,
                      ID : id,
                      First: first,
                      Last: last,
                      Sex: sex,
                      Dob: dob,
                      Feet: feet,
                      Inches: inches,
                      Weight: weight,
                      img : image
                    };
                
                    if (phone && phone.trim()) updatedMemberData.Phone = phone.trim();
                
                    if (mail && mail.trim()) updatedMemberData.Mail = mail.trim();
                
                    if (additional && additional.trim())
                    updatedMemberData.Additional = additional.trim();
                    if (Medical) updatedMemberData.Medical = Medical;
                
                    if (imgChange) {
                        if (image && image !== null){
                
                            const userImageUrl = await uploadImage(image);
                            updatedMemberData.img = userImageUrl;
                        }
                        if (image === null){
                            updatedMemberData.image = ''
                        }
                      
                    }
                
                    const request = await updateAMember(updatedMemberData);
                
                    setTimeout(() => {
                      if (request) props.onMemberUpdate(request);
                
                      setLoad(false);
                      window.location = '/Members';
                
                      toast.success('Member has been updated !', {
                        containerId: 'B',
                        transition: Zoom
                      });
                    }, 1000);
                  }}>
                <span className="btn-wrapper--label">Update</span>
              </Button>
            </div>
          </div>
        </Modal>
      </div>
        </Container>
      </div>
    </>
  );
};

const MapStateToProps = state => {

    return {
      UID : state.UserReducer.ID,
      Members : state.UserReducer.Members
      }
    
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      onMemberUpdate : (Member) => {
        dispatch(updateMember(Member)) //change this to edit
      } ,
  
     
  
    }};
  
  export default connect(MapStateToProps,mapDispatchToProps)(AddProduct);


