import React from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { Nav, NavItem } from 'reactstrap';
import { NavLink as NavLinkStrap } from 'reactstrap';

import { connect } from 'react-redux';

const packageJson = require('../../../package.json');

const Footer = (props) => {
  const { footerShadow, footerBgTransparent } = props;

  return (
    <>
      <div
        className={clsx('app-footer text-black-50', {
          'app-footer--shadow': footerShadow,
          'app-footer--opacity-bg': footerBgTransparent
        })}>
        <div className="app-footer--first">
          <Nav>
            {/*<NavItem>
              <NavLinkStrap tag={Link} to="/Homepage" className="rounded-sm">
                Home
              </NavLinkStrap>
            </NavItem>
            <NavItem>*/}
            {/*  <NavLinkStrap tag={Link} to="/Overview" className="rounded-sm">*/}
            {/*    Overview*/}
            {/*  </NavLinkStrap>*/}
            {/*</NavItem>*/}
            {/*<NavItem>*/}
            {/*  <NavLinkStrap tag={Link} to="/Wallets" className="rounded-sm">*/}
            {/*    Wallets*/}
            {/*  </NavLinkStrap>*/}
            {/*</NavItem>*/}
          </Nav>
        </div>
        <div className="app-footer--second">
          <span>911READY CORP{' '}
          </span> ©
          {new Date().getFullYear()} ~ handcrafted with <span className="text-danger px-1">❤</span> by{' '}
          <a
            href={packageJson.author.url}
            target="_blank"
            rel="noopener noreferrer"
            title="pingsoftwareusa.com">
            Ping Software USA
          </a>
          <span> ~ V{packageJson.version}</span>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  footerFixed: state.ThemeOptions.footerFixed,
  footerShadow: state.ThemeOptions.footerShadow,
  footerBgTransparent: state.ThemeOptions.footerBgTransparent
});

export default connect(mapStateToProps)(Footer);
