import React from 'react';

import { Button } from 'reactstrap';

import { loadHouseHolds } from '../../reducers/Actions/houseHoldActions';
import { loadMembers } from '../../reducers/Actions/membersActions';
import { connect } from 'react-redux';
import { receiveLogout } from '../../reducers/Actions/logoutActions';
import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {UserCheck, ChevronRight, Database } from 'react-feather';

import { SidebarWidget } from '..';

//for admins and master admins
{/* <Route exact path="/NewEntries" component={FFRModerator} />
<Route exact path="/UpdatedEntries" component={FFRModerator} />
<Route exact path="/UserList" component={FFRModerator} />  
<Route exact path="/ModeratorList" component={FFRModerator} />  
<Route exact path="/MasterModeratorList" component={FFRModerator} /> 
ListMasterAdmins */}


const SidebarMenu = (props) => {
  const { setSidebarToggleMobile } = props;

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  return (
    <>
      <PerfectScrollbar>
        <div className="sidebar-navigation">
          <SidebarWidget />
          <div className="sidebar-header">
            <span>Navigation</span>
          </div>
          <ul>          
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/NewEntries">
                <span className="sidebar-icon">
                <Database />
                </span>
                 New Jackets
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>

            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/UpdatedEntries">
                <span className="sidebar-icon">
                <Database />
                </span>
                Updated Jackets
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>

            {props.role === "MASTER ADMIN" ? <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/ListAdmins">
                <span className="sidebar-icon">
                  <UserCheck />
                </span>
                 List Admins
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>:null}

            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/UserList">
                <span className="sidebar-icon">
                  <UserCheck />
                </span>
                List Users
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>

            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/ModeratorList">
                <span className="sidebar-icon">
                  <UserCheck />
                </span>
                List Moderators
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>

            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/MasterModeratorList">
                <span className="sidebar-icon">
                  <UserCheck />
                </span>
               List Master Moderators
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>

            <li>
              <Button
                color="danger"
                className="m-2"
                onClick={() => {
                  props.LogOut();
                }}>
                <span className="btn-wrapper--label">Logout</span>
              </Button>
            </li>
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  isAuthenticated: state.UserReducer.isAuthenticated,
  HouseHolds: state.UserReducer.HouseHolds,
  UID: state.UserReducer.ID,
  role: state.UserReducer.Role
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => {
    dispatch(setSidebarToggleMobile(enable));
  },
  LogOut: () => {
    dispatch(receiveLogout());
  },
  onHouseHoldFetch: (HouseHolds) => {
    dispatch(loadHouseHolds(HouseHolds));
  },
  onMembersFetch: (Members) => {
    dispatch(loadMembers(Members));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
