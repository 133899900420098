import React from 'react';
import { Row, Col } from 'reactstrap';
import { PageTitle } from '../../layout-components';

import AddHouseHold from '../../example-components/HouseHolds/HouseHoldsAdd';
import ListHouseHold from '../../example-components/HouseHolds/HouseHoldsList';
import OverviewPageTitleActions from '../../example-components/Overview/OverviewPageTitleActions';
import {connect} from 'react-redux'
import {loadHouseHolds} from '../../reducers/Actions/houseHoldActions'
import {fetchAllHouseHolds} from '../../utils/API'

const Households = (props) => {

  //console.log("zobb" ,localStorage.getItem("state"))
  React.useEffect(() => { 

    async function fetchData() {
       props.onHouseHoldFetch(false)
      
        // here is where you make API call(s) or any side effects
        const houseHolds = await fetchAllHouseHolds(props.UID)
    
       
        setTimeout(() => {
       
          props.onHouseHoldFetch(houseHolds)
       
        }, 1000);
    
      }
    
      fetchData()
       
    
      }, [] ) /** passing empty braces is necessary */

  return (
    <>
      <PageTitle
        titleHeading={`Welcome back, ${props.FirstName}`}
        titleDescription="Here's a list of all your House Holds">
       
      </PageTitle>
      


      <Row>
        <Col xl="5" className="d-flex">
          
        </Col>
        <Col xl="7">
         
         
         
        </Col>
        <Col xl="12">

          <ListHouseHold></ListHouseHold>
         
        </Col>
       
      </Row>
    
    </>
  );
}
const MapStateToProps = state => {

  return {
    FirstName : state.UserReducer.First,
    UID : state.UserReducer.ID,
    isAuthenticated : state.UserReducer.isAuthenticated,
    }
  
}

const mapDispatchToProps = (dispatch) => {
  return {
    onHouseHoldFetch : (HouseHolds) => {
      dispatch(loadHouseHolds(HouseHolds))
    } ,

   

  }};

export default connect(MapStateToProps,mapDispatchToProps)(Households);