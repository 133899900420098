import React from 'react';

import { Button } from 'reactstrap';
//FOR MODS AND MASTER MODS
import { loadHouseHolds } from '../../reducers/Actions/houseHoldActions';
import { loadMembers } from '../../reducers/Actions/membersActions';
import { connect } from 'react-redux';
import { receiveLogout } from '../../reducers/Actions/logoutActions';
import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Database, UserCheck, ChevronRight } from 'react-feather';
import { SidebarWidget } from '..';

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile } = props;

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  return (
    <>
      <PerfectScrollbar>
        <div className="sidebar-navigation">
          <SidebarWidget />
          <div className="sidebar-header">
            <span>Navigation</span>
          </div>
          <ul>
            {props.role === "MASTER MOD" ? <li>
              <NavLink
                  onClick={toggleSidebarMobile}
                  activeClassName="active"
                  className="nav-link-simple"
                  to="/ListMods">
                  <span className="sidebar-icon">
                    <UserCheck />
                  </span>
                  Moderators
                  <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                    <ChevronRight />
                  </span>
              </NavLink>
            </li> : null}
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/NewEntries">
                <span className="sidebar-icon">
                  <Database />
                </span>
                New Jackets
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/UpdatedEntries">
                <span className="sidebar-icon">
                  <Database />
                </span>
                Update Jackets
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/DeactivatedAccounts">
                <span className="sidebar-icon">
                <Database />
                </span>
                Deactivated Accounts
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/DeletedAccounts">
                <span className="sidebar-icon">
                 <Database />
                </span>
                Deleted Accounts
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>
            <li>
              <Button
                color="danger"
                className="m-2"
                onClick={() => {
                  props.LogOut();
                }}>
                <span className="btn-wrapper--label">Logout</span>
              </Button>
            </li>
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  isAuthenticated: state.UserReducer.isAuthenticated,
  HouseHolds: state.UserReducer.HouseHolds,
  UID: state.UserReducer.ID,
  role: state.UserReducer.Role
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => {
    dispatch(setSidebarToggleMobile(enable));
  },
  LogOut: () => {
    dispatch(receiveLogout());
  },
  onHouseHoldFetch: (HouseHolds) => {
    dispatch(loadHouseHolds(HouseHolds));
  },
  onMembersFetch: (Members) => {
    dispatch(loadMembers(Members));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
