import React, { Fragment } from 'react';
import {
  Row,
  Col,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Badge,
  Button
} from 'reactstrap';
import { PageTitle } from '../../layout-components';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchNewEntries, TransferEntry } from '../../utils/API';
import {
  loadNewEntries,
  deleteNewEntry
} from '../../reducers/Actions/entryActions';
import moment from 'moment';
const NewEntriesPage = (props) => {
  //console.log("zobb" ,localStorage.getItem("state"))

  const [modal1, setModal1] = React.useState(false);
  const [modalContent, setModalOneContent] = React.useState(false);

  const formatCamelCase = (data) => {
    const result = data.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  const formatTime = (ti) => {
  
    var t = new Date(ti);
    // console.log(ti, t, moment(t).format('MMM DD  YYYY'));
    return moment(t).format('MMM DD  YYYY');
  };

  const toggle1 = (data) => {
    if (modal1)
      setTimeout(() => {
        setModalOneContent(false);
      }, 500);
    else setModalOneContent(data);

    setModal1(!modal1);
  };

  React.useEffect(() => {
    async function fetchData() {
      try {
        props.onNewEntriesFetch(false);
        let newEntries = [];

        if (props.Role == 'MASTER MOD' || props.Role == 'MOD')
          newEntries = await fetchNewEntries(
            props.Location.City,
            props.Location.State
          );
        else newEntries = await fetchNewEntries();

        props.onNewEntriesFetch(newEntries);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []); /** passing empty braces is necessary */

  return (
    <>
      <Modal zIndex={2000} centered isOpen={modal1} toggle={toggle1}>
        <ModalHeader
          toggle={
            toggle1
          }>{`Medical Data for ${modalContent.Name}`}</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              <Card className="card-box p-4 mb-5">
                <div className="timeline-list pt-0 timeline-list--success">
                  {modalContent
                    ? Object.keys(modalContent.Medical).map(function (key) {
                   
                        return (
                          <div className="timeline-item mb-0 py-0">
                            <div className="timeline-item--content">
                              <div className="timeline-item--icon timeline-icon-alternate" />
                              <h4 className="timeline-item--label mb-0">
                                <div className="font-size-lg d-40 text-center">
                                  <FontAwesomeIcon
                                    icon={['fas', 'star-of-life']}
                                    className="text-first"
                                  />
                                </div>
                                <div className="d-flex align-items-center flex-grow-1 justify-content-between">
                                  <span className="font-size-sm text-black-50">
                                    {' '}
                                    {key === "Other" ? modalContent.Medical[key] : formatCamelCase(key)}
                                  </span>
                                </div>
                              </h4>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </Card>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="mx-auto p-2">
          <Button color="primary" className="ml-auto" onClick={toggle1}>
            Dismiss
          </Button>
        </ModalFooter>
      </Modal>

      <PageTitle
        titleHeading={`Welcome back ${props.FirstName}`}
        titleDescription="This page displays new jackets to be entered."></PageTitle>

      <Fragment>
        <Row>
          {props.NewEntries ? (
            props.NewEntries.length > 0 ? (
              props.NewEntries.map((entry, index) => {
                return (
                  <>
                    <Col xl="4" key={index}>
                      <Card className="card-box mb-5 p-4">
                        <div className="d-flex align-items-center mb-3">
                          <div className="avatar-icon-wrapper rounded-circle mr-3">
                            <div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
                              <div className="rounded-circle overflow-hidden">
                                <img
                                  alt="..."
                                  className="img-fluid"
                                  style={{ maxWidth: '150px', maxHeight: '80px' }}
                                  src={entry.img}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="w-100">
                            <a
                              href="#/"
                              onClick={(e) => e.preventDefault()}
                              className="font-weight-bold font-size-lg"
                              title="...">{`${entry.First} ${entry.Last}`}</a>
                            <span className="text-black-50 d-block">
                              Registered on {formatTime(entry.CreateDate)}
                            </span>
                            <div className="d-flex align-items-center pt-1">
                              <Badge pill color="success">
                                <FontAwesomeIcon icon={['fas', 'user']} />{' '}
                                MEMBER
                              </Badge>
                            </div>
                          </div>
                        </div>
                        <br /><br />
                        <p className="text-black-50 font-size-md mb-0">
                          {entry.Street} <br></br> {entry.City}, {entry.State},
                          {entry.Zip}
                        </p>
                        <div className="my-4 font-size-sm p-3 bg-secondary rounded-sm">
                          <div className="d-flex justify-content-between">
                            <span className="font-weight-bold">Email:</span>
                            <span className="text-black-50">
                              {entry.Mail ? entry.Mail : '(not provided)'}
                            </span>
                          </div>

                          <div className="d-flex justify-content-between py-2">
                            <span className="font-weight-bold">Phone</span>
                            <span className="text-black-50">
                              {entry.Phone ? entry.Phone : '(not provided)'}
                            </span>
                          </div>

                          <div className="d-flex justify-content-between py-2">
                            <span className="font-weight-bold">
                              Date of Birth
                            </span>
                            <span className="text-black-50">
                              {formatTime(entry.Dob)}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between py-2">
                            <span className="font-weight-bold">Sex:</span>
                            <span className="text-black-50">
                              {entry.Sex == 'M' ? 'Male' : 'Female'}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between py-2">
                            <span className="font-weight-bold">Hieght:</span>
                            <span className="text-black-50">
                              {entry.Feet + "'" + entry.Inches + '"'} Ft
                            </span>
                          </div>
                          <div className="d-flex justify-content-between py-2">
                            <span className="font-weight-bold">Weight:</span>
                            <span className="text-black-50">
                              {entry.Weight} LB
                            </span>
                          </div>
                         
                          <div className="d-flex justify-content-between py-2">
                            <span className="font-weight-bold">Additional info:</span>
                            <span className="text-black-50">
                              {entry.additional}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between py-2">
                            <Button
                              color="link"
                              className="m-2 btn-link-primary mx-auto"
                              onClick={() => {
                                const data = {
                                  Name: `${entry.First} ${entry.Last}`,
                                  Medical: entry.Medical
                                };
                                toggle1(data);
                              }}>
                              <span>View Medical Record</span>
                            </Button>
                          </div>
                        </div>
                        <Button
                          block
                          color="neutral-danger"
                          className="text-uppercase font-size-xs"
                          onClick={async () => {
                            try {
                              const done = await TransferEntry(entry.ID);

                              if (done) {
                                props.onEntryDelete(entry.ID);
                              }
                            } catch (error) {
                              return;
                            }
                          }}>
                          <small className="font-weight-bold">
                            Mark as Transfered
                          </small>
                        </Button>
                      </Card>
                    </Col>
                  </>
                );
              })
            ) : (
              <div>
                {' '}
                <p>No New Jackets to display</p>{' '}
              </div>
            )
          ) : (
            <div>
              {' '}
              <p>Loading...</p>{' '}
            </div>
          )}
        </Row>
      </Fragment>
    </>
  );
};
const MapStateToProps = (state) => {
  return {
    FirstName: state.UserReducer.First,
    UID: state.UserReducer.ID,
    Location: state.UserReducer.Location,
    Token: state.UserReducer.Token,
    NewEntries: state.UserReducer.NewEntries,
    Role: state.UserReducer.Role
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onNewEntriesFetch: (NewEntries) => {
      dispatch(loadNewEntries(NewEntries));
    },
    onEntryDelete: (EntryID) => {
      dispatch(deleteNewEntry(EntryID));
    }
  };
};

export default connect(MapStateToProps, mapDispatchToProps)(NewEntriesPage);
