import React from 'react';
import { Row, Col,Button } from 'reactstrap';
import AddHouseHold from '../../../example-components/HouseHolds/HouseHoldsAdd'
import {connect} from 'react-redux'

const Households = (props) => {

  return (
    <>
  <Row>
        <Col xl="5" className="d-flex">
        <Button color = {'danger'} onClick = {()=> {window.location = '/HouseHolds'}}>Cancel New House Registration</Button>
        </Col>
        <Col xl="7">
            
        </Col>
        <Col xl="12">

          <AddHouseHold/>

        </Col>
       
      </Row>
    
    </>
  );
}
const MapStateToProps = state => {

  return {
    FirstName : state.UserReducer.First,
    UID : state.UserReducer.ID,
    isAuthenticated : state.UserReducer.isAuthenticated,
    }
  
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     onHouseHoldFetch : (HouseHolds) => {
//       dispatch(loadHouseHolds(HouseHolds))
//     } ,

   

//   }};

export default connect(MapStateToProps,null)(Households);