import React from 'react';
import {
InputGroup,InputGroupAddon,InputGroupText, Button,Collapse,FormGroup,Input,Badge,FormFeedback } from 'reactstrap';
import InputMask from 'react-input-mask';
import {verifyPhone,verifyPhoneNotInUse} from 'utils/API';
import Validator from 'utils/Validator'
import LaddaButton, {  EXPAND_RIGHT } from 'react-ladda';

const {toast} = require("react-toastify")

export default function LivePreviewExample(props) {

  const [expRight, setExpRight] = React.useState(false);
  const [expRight2, setExpRight2] = React.useState(false);
  const [ phoneNum, setPhoneNum] = React.useState(props.Cell !== 'none' ? props.Cell : '')
  const [phoneIsAvail, setPhoneAvail] = React.useState(true)
  const [finalNum, setFinalNum] = React.useState('')
  const [oneTimeCode, setOneTimeCode] = React.useState(null)
  const [userCode , setUserCode] = React.useState(null)
  
  return (
    <>
    <InputGroup >
        <InputGroupAddon addonType="prepend">
            <InputGroupText>
                +1
            </InputGroupText>
        </InputGroupAddon>

        <Input   invalid = {!Validator.validatePhoneNumber(phoneNum) || !phoneIsAvail} disabled= {props.verified } type="number" className="form-control" placeholder= {'123 456 786'} value= {phoneNum} 
        onChange = {async (e) => {
                      toast.dismiss(68)
                      setPhoneNum(e.target.value); 
                      setPhoneAvail(true)//change
                      
                      if(e.target.value && Validator.validatePhoneNumber(e.target.value) && props.Cell !== e.target.value){

                        const inU = await verifyPhoneNotInUse(e.target.value)
        
                         if (!inU.isAvail){
            
                          setPhoneAvail(false)//change
            
                            toast.error('The Phone number you are trying to use is already associated to an existing account', {
                                position: "top-center",
                                autoClose: 10000,
                                 hideProgressBar: false,
                                 closeOnClick: true,
                                 pauseOnHover: true,
                                 draggable: true,
                                 progress: undefined,
                                 toastId:68
                                  });
                          }


                      }
          
          }} />
        {!Validator.validatePhoneNumber(phoneNum)  ? <FormFeedback>Invalid Phone number </FormFeedback> :null}
                              {!phoneIsAvail ? <FormFeedback>Phone number is already tied to an existing account</FormFeedback> :null}
        <InputGroupAddon addonType="prepend">

          {!props.verified ? 
          
        <LaddaButton className="btn btn-primary" disabled= {!Validator.validatePhoneNumber(phoneNum) || props.verified || !phoneNum || phoneNum === props.Cell}
                                         loading={expRight}
                                         onClick={async () =>{ 
                                           
                                          
                                          setExpRight(true)

                                          try {
            
          
                                            if(Validator.validatePhoneNumber(phoneNum)){
                                              
                                              setFinalNum(phoneNum)
                                            }
                                            else {
                                              return
                                            }
                                  
                                            const code = await verifyPhone(phoneNum)
                                  
                                            if (code){
                                              props.setFlag(true)
                                              setOneTimeCode(code)
                                              toast.success('Code Sent !', {
                                                position: "top-right",
                                                autoClose: 7000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                });

                                               
                                            }
                                             setExpRight( false)
                                          } catch (error) {
                                              
                                            console.error("error")
                                            setExpRight(false)
                                          }
                                        }
                                        
                                        
                                        }
                                         
                                         data-style={EXPAND_RIGHT}
                            >
                                Send code
                            </LaddaButton> : <Badge color="success" className="btn-pill m-1">Verified</Badge>}
          
         </InputGroupAddon>
     
    </InputGroup>
    <Collapse isOpen={props.sent}>
        <div className="p-2">
            <FormGroup>
                <label className="font-weight-bold">
                    {`A One Time Code was sent to ${finalNum}`} 
                </label>
                <InputGroup >
        <InputMask disabled= {props.verified} className="form-control" placeholder= {'one time code'} value= {userCode} onChange = {(e) => {setUserCode(e.target.value) }}/>
        
        <InputGroupAddon addonType="prepend">

          {!props.verified ?
        <LaddaButton className="btn btn-primary"
                                         loading={expRight2}
                                         onClick={async () =>{ 
                                    
                                          try {

                                            setExpRight2(true)

                                            if(userCode === oneTimeCode)
                                            {

                                              //verified

                                              toast.success('Phone number verification complete !', {
                                                position: "top-center",
                                                autoClose: 7000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                });

                                                // disable all inputs and show a tag that its all done
                                                props.setVerified(true)
                                                props.setPhone(finalNum)

                                            }
                                            else {

                                              toast.error('Verification codes do not match', {
                                                position: "top-center",
                                                autoClose: 7000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                });

                                            }

                                            setTimeout(() => {
                                              
                                              setExpRight2(false)


                                            }, 1500);
                                            
                                          } catch (error) {


                                            setExpRight2(false)
                                            
                                          }
                                        
                                        
                                        }}
                                        disabled= {oneTimeCode ? false : true}
                                         data-style={EXPAND_RIGHT}
                            >
                                Verify
                            </LaddaButton> : <Badge color="success" className="btn-pill m-1">Verified</Badge> }
          
         </InputGroupAddon>
     
    </InputGroup>
            </FormGroup>
    </div>
</Collapse>
</>

  )



}