import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {
  Row,
  Col,
  FormFeedback,
  Input,
  Label,
  FormGroup,
  Form
} from 'reactstrap';
import {stateLabelValues} from 'utils/states'
import Validator from '../../utils/Validator'
import {BounceLoader } from 'react-spinners';

const NewMasterMod = (props) => {


  


  return (
    <>
    {props.loading ? ( <div className="text-center py-4 rounded bg-secondary my-4">
                          
                          <div
                            className="d-flex mx-auto align-items-center justify-content-center"
                            style={{ width: '150px', height: '80px' }}>
                            <BounceLoader color={'var(--primary)'} loading={true} />
                          </div>
                        
                                        
                                      </div>) : null}
                                      <Fragment>
        <Form>
          <FormGroup>
            <Label for="fname">First Name <span className="icon-danger">*</span></Label>
            <Input type="text" name="fname" id="fname" placeholder="First Name" 
            type="text"
            value = {props.first}
            onChange = {(e) => {

              props.setFirstName(e.target.value)

            }}/>
          </FormGroup>
          <FormGroup>
            <Label for="lname">Last Name <span className="icon-danger">*</span></Label>
            <Input type="text" name="lname" id="lname" placeholder="Last Name"
            type="text"
            value = {props.last}
            onChange = {(e) => {

              props.setLastName(e.target.value)

            }} />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email <span className="icon-danger">*</span></Label>
           
            <Input placeholder="Email" type="mail" value  = {props.email} onChange = {(e) => {props.setEmail(e.target.value); props.setEmailAvail(true)}} invalid = {!Validator.validateEmail(props.email) || !props.emailIsAvail} />
           {!Validator.validateEmail(props.email)  ? <FormFeedback>Invalid email</FormFeedback> :null}
           {!props.emailIsAvail ? <FormFeedback>Email is already tied to an existing account</FormFeedback> :null}
          
          </FormGroup>
          <FormGroup>
            <Label for="lname">Phone Number <span className="icon-danger">*</span></Label>
            <Input type="tel" name="phone" id="phone" placeholder="Phone Number"
            type="text"
            value = {props.phone}
            onChange = {(e) => {

              props.setPhone(e.target.value)

            }} />
          </FormGroup>            
         
          <Row>
                    <Col md="6" className="mb-3">
                        <Label for="form-2-city">City <span className="icon-danger">*</span></Label>
                        <Input invalid={false} id="form-2-city" placeholder="City" required=""
                        value = {props.primaryLocation && props.primaryLocation.City ? props.primaryLocation.City : ""}
                        onChange = {(e) =>{
                          props.setLocation({...props.primaryLocation, City : e.target.value})
                         
                        }}/>
                            <FormFeedback>required field</FormFeedback>
                    </Col>
            <div className="col-md-3 mb-3">
              <FormGroup>
                <Label for="State">State <span className="icon-danger">*</span></Label>
                  <select className="form-control" id="inputState" value = { props.primaryLocation && props.primaryLocation.State ? props.primaryLocation.State : null} onChange = {(e)=>{props.setLocation({...props.primaryLocation, State : e.target.value})}}>
                    <option >Choose...</option>
                    {stateLabelValues ? stateLabelValues.map((state,index)=>

                         <option key = {index} value={state.value}>{state.label}</option>

                    ): null}
                  </select>
              </FormGroup>
              <FormFeedback>required field</FormFeedback>
            </div>
            <div className="col-md-3 mb-3">
                        <Label for="form-2-zip">Zip <span className="icon-danger">*</span></Label>
                        <Input 
                         invalid = {props.primaryLocation && props.primaryLocation.Zip ? !Validator.validateZipCode(props.primaryLocation.Zip):false}
                         id="form-2-zip" placeholder="Zip" required=""
                        value = {props.primaryLocation && props.primaryLocation.Zip ? props.primaryLocation.Zip : ""}
                        onChange = {(e) =>{
                          props.setLocation({...props.primaryLocation, Zip : e.target.value})
                         
                        }}/>
                            <FormFeedback>Please enter a valid Zip code</FormFeedback>
                    </div>
          </Row>
           
        </Form>
      </Fragment>
    </>
  );
}
const MapStateToProps = state => {

  return {
    FirstName : state.UserReducer.First,
    UID : state.UserReducer.ID,
    isAuthenticated : state.UserReducer.isAuthenticated,
  }

}



export default connect(MapStateToProps)(NewMasterMod);