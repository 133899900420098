import React from 'react';

import { Badge, Button } from 'reactstrap';

import { ArrowDownRight } from 'react-feather';

import Chart from 'react-apexcharts';
const SidebarWidget = () => {
  const chart33Options = {
    chart: {
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      color: 'red',
      curve: 'smooth',
      width: 2
    },
    fill: {
      color: 'red',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.6,
        opacityTo: 0,
        stops: [0, 100]
      }
    },
    legend: {
      show: false
    },
 
    yaxis: {
      min: 0
    }
  };
  const chart33Data = [
    {
      
      data: [47, 45, 54, 38, 56, 24, 65]
    }
  ];

  return (
    <>
      <div className="app-sidebar--widget">
   
        <div>
          <Chart
            options={chart33Options}
            series={chart33Data}
            type="area"
            height={80}
          />
        </div>
      </div>
    </>
  );
};

export default SidebarWidget;
