import React from 'react'
import {Row,Label,Col,FormGroup,FormFeedback,Input,InputGroup,InputGroupAddon,InputGroupText} from 'reactstrap'
import Styles from './Styles'
import { Form, Field } from 'react-final-form'
import Card from './Card'
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate
} from './cardUtils'
import {connect} from 'react-redux'
import Validator from 'utils/Validator'
import {stateLabelValues} from 'utils/states'
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))


const onSubmit = async values => {
  await sleep(300)
  window.alert(JSON.stringify(values, 0, 2))
}

const BillingInfoForm = (props) => (
  <Styles>
    <Form
      onSubmit={onSubmit}
      initialValues= {{
        
        number: props.ccNum || '',
        name : `${props.First} ${props.Last}` || '',
        expiry : props.ccExipry || '',
        cvc : props.cvc || ''

      
      }}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        values,
        active
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            
            <Row>
          <Col xl="6" className="p-0 mx-auto" style={{marginRight : "20px"}}>
         
            <Card
              
              number={values.number || ''}
              name={values.name || ''}
              expiry={values.expiry || ''}
              cvc={values.cvc || ''}
              focused={active}
            />
           </Col></Row>
   
            <div>
              <Field
                name="number"
                component="input"
                type="text"
                pattern="[\d| ]{16,22}"
                placeholder="Card Number"
                format={formatCreditCardNumber}
              />
            </div>
            <div>
              <Field
                name="name"
                component="input"
                type="text"
                placeholder="Name"
              />
            </div>
            <Row>
               <Col xl="12" >
              <Field
              className="m-2"
                name="expiry"
                component="input"
                type="text"
                pattern="\d\d/\d\d"
                placeholder="Valid Thru"
                format={formatExpirationDate}
              />
              
              <Field
              className="m-2"
                name="cvc"
                component="input"
                type="text"
                pattern="\d{3,4}"
                placeholder="CVC"
                format={formatCVC}
              />
            </Col> </Row>
       
            <Label className="font-weight-bold" for="">
              
            <Field
                name="sameAddy"
                component="input"
                type="checkbox"
                label="ayre"
                className= "m-2"
              />
              Use Primary Location as Billing Address
              </Label>

              {!values.sameAddy ? 
              
              <>

            <Row>
               <Col xl="12" >

                <Field
                  className="m-2"
                  name="first"
                  render= {props => (
                    <>
                     
                    <Label for="first-name" className="font-weight-bold" >First Name <span className="icon-danger">*</span></Label>
                          <Input invalid={false} id="first-name" placeholder="First Name" required={true} style={{ textTransform: 'capitalize'}}
                          
                        
                         />
                        <FormFeedback>required field</FormFeedback>
                    
                      </>
                  )}
                  />

                <Field
                  className="m-2"
                  name="last"
                  render= {props => (
                    <>
                     
                  <Label for="last-name" className="font-weight-bold" >Last Name <span className="icon-danger">*</span></Label>
                        <Input invalid={false} id="last-name" placeholder="Last Name" required={true} style={{ textTransform: 'capitalize'}}
                        
                      
                       />
                      <FormFeedback>required field</FormFeedback>
                  
                    </>
                  )}
                  />
              </Col>
          </Row>

          <Row>
          <Col md="12" className="mb-1">
              <Field
                name="phone"
                render = {props => (
                  <>
                  <h6 className="m-1 text-primary">Phone (optional)</h6>

                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>+1</InputGroupText>
                        </InputGroupAddon>

                        <Input placeholder="Phone number" type="number" value  = {props.phone} onChange = {(e) => {
                      
                        props.setPhone(e.target.value); 

                      }} invalid = {!Validator.validatePhoneNumber(props.phone) } />
                                {!Validator.validatePhoneNumber(props.phone)  ? <FormFeedback>Invalid Phone number </FormFeedback> :null}
                              
                      </InputGroup>
                  </>
                )}
                
              />
            </Col></Row>
            <Row>
          <Col md="12" className="mb-1">
              <Field
                name="Street"
                
                render= { props => (
                  <>
                  <Label for="form-2-street" className="font-weight-bold" >House Address <span className="icon-danger">*</span></Label>
                        <Input invalid={false} id="form-2-street" placeholder=" Enter Street Address" required={true} style={{ textTransform: 'capitalize'}}
                        
                      
                       />
                      <FormFeedback>required field</FormFeedback>
                  </>
                )}
              />
            </Col>
            </Row>

            <Row>
               <Col xl="12" >
                <Field
                  className="m-2 mb-1"
                  name="City"
                  render = { props => (
                    <>
                    <Label for="form-2-city" className="font-weight-bold" >City <span className="icon-danger">*</span></Label>
                    <Input invalid={false} id="form-2-city" placeholder="City" required=""/>
                            <FormFeedback>required field</FormFeedback>
                    </>
                  )}
                  /> 

                <Field
                  className="m-2 mb-1"
                  name="State"
                  render = {props => (
                    <>
                    <FormGroup className="w-50">
          <Label for="State" className="font-weight-bold">State <span className="icon-danger">*</span></Label>
            <select className="form-control" id="inputState" value = { props.primaryLocation && props.primaryLocation.State ? props.primaryLocation.State : null} onChange = {(e)=>{props.setLocation({...props.primaryLocation, State : e.target.value})}}>
              <option >Choose...</option>
              {stateLabelValues ? stateLabelValues.map((state,index)=>

                   <option key = {index} value={state.value}>{state.label}</option>

              ): null}
            </select>
        </FormGroup>
        <FormFeedback>required field</FormFeedback>
                    </>
                  )}
                  />
                  </Col>
            </Row>
          <Row>
          <Col xl="12" className="mb-1">
            <Field
                  className="m-2"
                  name="State"
                  render = {props => (
                    <>
                   
                        <Label for="form-2-zip" className="font-weight-bold">Zip <span className="icon-danger">*</span></Label>
                        <Input 
                        invalid = {false}
                         id="form-2-zip" placeholder="Zip" required=""
                        
                        />
                            <FormFeedback>Please enter a valid Zip code</FormFeedback>
                  
                    </>
                  )}
                  />
                  </Col>
                  </Row>
                
               
              
          
              
              </>
              
              : null}
            
                       
          </form>
        )
      }}
    />
    
  </Styles>
)
const MapStateToProps = (state) => {
  return {
    First: state.UserReducer.First,
    UID: state.UserReducer.ID,
    Last: state.UserReducer.Last,
    Cell : state.UserReducer.Cell ,
    Email : state.UserReducer.Email
  };
};

export default connect(MapStateToProps)(BillingInfoForm)