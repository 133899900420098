import React, { useState } from 'react';

import clsx from 'clsx';
import {connect} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  InputGroup,
  InputGroupAddon,InputGroupText,FormFeedback,
  Col,
  Card,
  CardHeader,
  Container,
  Label,
  FormGroup,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Button,Modal
} from 'reactstrap';
import { NavLink as NavLinkStrap } from 'reactstrap';
import {updateUserInfo} from '../../../reducers/Actions/loginActions'
import Validator from 'utils/Validator';
import {updateProfile,verifyEmailNotInUse,verifyPhoneNotInUse} from 'utils/API';
import {BounceLoader} from "react-spinners";
import CellAuth from './CellAuth'
import { toast, Zoom } from 'react-toastify';

const ProfileEditForm = (props) => {

    const [first, setFirst] = React.useState(props.First)
    const [last, setLast] = React.useState(props.Last)
    const [cell, setCell] = React.useState(props.Cell)
    const [email, setEmail] = React.useState(props.Email)
    const [modal4, setModal4] = React.useState(false);
    const [verified, setVerified] = React.useState(false)
    const [ sent, setFlag] = React.useState(false)
    const [emailIsAvail, setEmailAvail] = React.useState(true)
    
  
    const toggle4 = () => {
      
      
      
      setModal4(!modal4); 
  
  
    }
    const [isLoading, setLoad] = React.useState(false)

    return (
        <>
        <Container>
              <div className="text-uppercase font-weight-bold text-primary pt-4 font-size-sm">
                Personal Information
              </div>
              <div className="py-4">
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label className="font-weight-bold" for="">
                        First Name
                      </Label>
                      <Input
                        type="text"
                        name=""
                        id=""
                        placeholder="First name..."
                        value = {first}
                        onChange = {(e) => {setFirst(e.target.value)}}
                      />
                    </FormGroup>
                   
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="font-weight-bold" for="">
                        Last Name
                      </Label>
                      <Input
                        type="text"
                        name=""
                        id=""
                        placeholder="Last name ..."
                        value= {last}
                        onChange = {(e) => {setLast(e.target.value)}}
                      />
                    </FormGroup>
                    
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                  <FormGroup>
                            <label className="font-weight-bold">
                              Mobile Phone (optional)
                            </label>
                            <CellAuth setPhone = {setCell} Cell = {cell} verified={verified} setVerified={setVerified} sent={sent} setFlag={setFlag} ></CellAuth>
                          </FormGroup>
                   
                  </Col>
                  <Col md="6">
                  <FormGroup>
                            <label className="font-weight-bold">
                              Email address
                            </label>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                      @
                                  </InputGroupText>
                              </InputGroupAddon>
                             
                              <Input placeholder="Email" type="mail" value  = {email} onChange = {async (e) => {


                                setEmail(e.target.value); 
                                setEmailAvail(true)

                                if(e.target.value && Validator.validateEmail(e.target.value) && e.target.value !== props.Email){

                                  const inU = await verifyEmailNotInUse(e.target.value)
                  
                                   if (!inU.isAvail){
                      
                                    setEmailAvail(false)
                      
                                      toast.error('Email you are trying to use is already associated to an existing account', {
                                          position: "top-center",
                                          autoClose: 10000,
                                           hideProgressBar: false,
                                           closeOnClick: true,
                                           pauseOnHover: true,
                                           draggable: true,
                                           progress: undefined,
                                           toastId:69
                                            });
                                    }
          
          
                                }
                                
                                }} 
                                invalid = {!Validator.validateEmail(email) || !emailIsAvail} />
                              {!Validator.validateEmail(email)  ? <FormFeedback>Invalid email</FormFeedback> :null}
                              {!emailIsAvail ? <FormFeedback>Email is already tied to an existing account</FormFeedback> :null}
                             
                           
                              
                          </InputGroup>
                      </FormGroup>
                    
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="divider mb-4" />
            
            <div className="divider mt-5 mb-4" />
            <Container className="d-flex align-items-center justify-content-end">
              <div className="py-4">
                <Button color="success" size="lg" className="font-weight-bold" disabled = {props.First.toLowerCase() == first.toLowerCase() && props.Cell == cell && props.Last.toLowerCase() == last.toLowerCase() && props.Email.toLowerCase() == email.toLowerCase() ? true : false}
                onClick = {toggle4 }>
                  Save changes
                </Button>
              </div>
            </Container>





        <Modal zIndex={2000} centered isOpen={modal4} toggle={toggle4}>
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-first text-first m-0 d-130">
                <FontAwesomeIcon
                  icon={['far', 'keyboard']}
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">
              Are you sure you want to confirm the updated information for this member ?
            </h4>
            <p className="mb-0 text-black-50">
              You can always change it later.
            </p>
            {isLoading ? (
              <div
                className="d-flex mx-auto align-items-center justify-content-center"
                style={{ width: '150px', height: '80px' }}>
                <BounceLoader color={'var(--primary)'} loading={true} />
              </div>
            ) : null}
            <div className="pt-4">
              <Button
                onClick={toggle4}
                color="neutral-secondary"
                className="btn-pill text-danger mx-1">
                <span className="btn-wrapper--label">Cancel</span>
              </Button>
              <Button
                color="first"
                className="btn-pill mx-1"
                
                onClick={async () => {
                 
                    setLoad(true)
                    const data = {
                        ID : props.UID
                    }

                    if (props.First.toLowerCase() !== first.toLowerCase() )
                        data.First = first

                    if (props.Cell !== cell ){
                        data.Cell = cell 

                        if (!Validator.validatePhoneNumber(cell)) {

                          toast.error('Please enter a valid Phone number', {
                            position: "top-center",
                            autoClose: 7000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            });

                            toggle4()
                            return

                        }

                        const inU = await verifyPhoneNotInUse(email)

                        if (!inU.isAvail){

                          setEmailAvail(false)

                          toast.error('The Phone number you are trying to use is already associated to an existing account', {
                            position: "top-center",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            });

                         toggle4()
                          return
                        }

                    }

                    if (props.Last.toLowerCase() !== last.toLowerCase() )
                        data.Last = last   
                   
                    if (props.Email.toLowerCase() !== email.toLowerCase() ){
                        data.Email = email   
                        
                        if (!Validator.validateEmail(email)) {

                          toast.error('Please enter a valid email', {
                            position: "top-center",
                            autoClose: 7000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            });

                            toggle4()
                            return

                        }

                        const inU = await verifyEmailNotInUse(email)

                        if (!inU.isAvail){

                          setEmailAvail(false)

                          toast.error('Email you are trying to sign up with is already associated to an existing account', {
                            position: "top-center",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            });

                         toggle4()
                          return
                        }
                    }

                    const request = await updateProfile(data)
                
                    setTimeout(() => {
                      if (request) props.onUpdateUserInfo(data);
                
                      setLoad(false);
                      toggle4()
                      
                
                      toast.success('Your info has been updated !', {
                        containerId: 'B',
                        transition: Zoom
                      });

                      setVerified(false)
                      setFlag(false)

                    
                      console.log(cell)

                    }, 1000);
                  }}>
                <span className="btn-wrapper--label">Update</span>
              </Button>
            </div>
          </div>
        </Modal>
        </>
    )

}
const MapStateToProps = (state) => {
    return {
      First: state.UserReducer.First,
      UID: state.UserReducer.ID,
      Last: state.UserReducer.Last,
      Cell : state.UserReducer.Cell ,
      Email : state.UserReducer.Email
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
     onUpdateUserInfo : (info) => {
         dispatch(updateUserInfo(info))
     }
    };
  };
  
  export default connect(MapStateToProps, mapDispatchToProps)(ProfileEditForm);