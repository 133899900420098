import React from 'react';
import {
InputGroup,InputGroupAddon,InputGroupText, Button,Collapse,FormGroup,Input,Badge,FormFeedback } from 'reactstrap';
import InputMask from 'react-input-mask';
import {verifyPhone} from 'utils/API';
import Validator from '../../../utils/Validator'
import LaddaButton, {  EXPAND_RIGHT } from 'react-ladda';

const {toast} = require("react-toastify")

export default function LivePreviewExample(props) {

  const [expRight, setExpRight] = React.useState(false);
  const [expRight2, setExpRight2] = React.useState(false);
  const [ phoneNum, setPhoneNum] = React.useState('')
  const [ sent, setFlag] = React.useState(false)
  const [finalNum, setFinalNum] = React.useState('')
  const [oneTimeCode, setOneTimeCode] = React.useState(null)
  const [userCode , setUserCode] = React.useState(null)
  const [verified, setVerified] = React.useState(false)
  return (
    <>
    <InputGroup >
        <InputGroupAddon addonType="prepend">
            <InputGroupText>
                +1
            </InputGroupText>
        </InputGroupAddon>

        <Input  invalid = {!Validator.validatePhoneNumber(phoneNum)} disabled= {verified} type="number"className="form-control" placeholder= {'123 456 786'} value= {phoneNum} onChange = {(e) => {setPhoneNum(e.target.value)}} />
                              <FormFeedback>Invalid Mobile phone number</FormFeedback>
        <InputGroupAddon addonType="prepend">

          {!verified ? 
          
        <LaddaButton className="btn btn-primary" disabled= {verified}
                                         loading={expRight}
                                         onClick={async () =>{ 
                                           
                                          
                                          setExpRight(true)

                                          try {
            
          
                                            if(Validator.validatePhoneNumber(phoneNum)){
                                              
                                              setFinalNum(phoneNum)
                                            }
                                            else {
                                              return
                                            }
                                  
                                            const code = await verifyPhone(phoneNum)
                                  
                                            if (code){
                                              setFlag(true)
                                              setOneTimeCode(code)
                                              toast.success('Code Sent !', {
                                                position: "top-right",
                                                autoClose: 7000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                });

                                               
                                            }
                                             setExpRight( false)
                                          } catch (error) {
                                              
                                            console.error("error")
                                            setExpRight(false)
                                          }
                                        }
                                        
                                        
                                        }
                                         disabled= {!Validator.validatePhoneNumber(phoneNum) || verified || !phoneNum}
                                         data-style={EXPAND_RIGHT}
                            >
                                Send code
                            </LaddaButton> : <Badge color="success" className="btn-pill m-1">Verified</Badge>}
          
         </InputGroupAddon>
     
    </InputGroup>
    <Collapse isOpen={sent}>
        <div className="p-2">
            <FormGroup>
                <label className="font-weight-bold">
                    {`A One Time Code was sent to ${finalNum}`} 
                </label>
                <InputGroup >
        <InputMask disabled= {verified} className="form-control" placeholder= {'one time code'} value= {userCode} onChange = {(e) => {setUserCode(e.target.value) }}/>
        
        <InputGroupAddon addonType="prepend">

          {!verified ?
        <LaddaButton className="btn btn-primary"
                                         loading={expRight2}
                                         onClick={async () =>{ 
                                    
                                          try {

                                            setExpRight2(true)

                                            if(userCode === oneTimeCode)
                                            {

                                              //verified

                                              toast.success('Phone number verification complete !', {
                                                position: "top-center",
                                                autoClose: 7000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                });

                                                // disable all inputs and show a tag that its all done
                                                setVerified(true)
                                                props.setPhone(finalNum)

                                            }
                                            else {

                                              toast.error('Verification codes do not match', {
                                                position: "top-center",
                                                autoClose: 7000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                });

                                            }

                                            setTimeout(() => {
                                              
                                              setExpRight2(false)


                                            }, 1500);
                                            
                                          } catch (error) {


                                            setExpRight2(false)
                                            
                                          }
                                        
                                        
                                        }}
                                        disabled= {oneTimeCode ? false : true}
                                         data-style={EXPAND_RIGHT}
                            >
                                Verify
                            </LaddaButton> : <Badge color="success" className="btn-pill m-1">Verified</Badge> }
          
         </InputGroupAddon>
     
    </InputGroup>
            </FormGroup>
    </div>
</Collapse>
</>

  )



}