import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Clock from 'react-live-clock';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import projectLogo from '../../../assets/images/911ready.png';



const HeaderMenu = (props) => {

  return (
    <Fragment>
      <div className="header-nav-menu d-none d-lg-block">

      <div className="bg-white-10 header-nav-wrapper header-nav-wrapper-xl rounded px-4 navbar-dark m-3">
          <div className="app-nav-logo">
            <NavLink
              to="/Homepage"
              title="911 Ready navbar"
              className="app-nav-logo app-nav-logo--light">
              <div className=" shadow-second-sm ">
                <img
                height = {50}
                  alt="911 Ready"
                  src={projectLogo}
                />
              </div>
              <div className="app-nav-logo--text">
                <span>911 Ready</span>

                <p><strong>{moment().format('ddd MMM DD  YYYY')}</strong></p>
              </div>
            </NavLink>
          </div>
          
          <div className="header-nav-actions flex-grow-0 flex-lg-grow-1">
          <div className="app-nav-logo--text">
                <span>EST <FontAwesomeIcon icon={['fas', 'clock']} /> </span>

                <Clock
          
          format={'h:mm:ssa'}
          style={{fontSize: '1.5em'}}
          ticking={true} />
              </div>
     </div>
    </div>
  </div>
      
    </Fragment>
  );
};

export default HeaderMenu;