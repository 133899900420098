import { setOptions } from 'leaflet';
import React from 'react';

import { withRouter } from 'react-router-dom';

// reactstrap components
import { Container, Label, FormGroup, Input,CustomInput } from 'reactstrap';

const AddProduct = (props) => {

console.log(localStorage.getItem('state'))
  React.useEffect(() => {

    props.setAccept(false)
    props.setPerson(false)
    props.setPerm(false)
    props.setIsPerson(false)
    props.setAgree(false)

  },[])

  const selectOption = (choice) => {
    if(!choice) return

    props.setPerson(false)
    props.setPerm(false)
    props.setIsPerson(false)

    switch (choice) {
      case 1:
        props.setPerson(true);
        break;

      case 2:
        props.setPerm(true)
        break;    
    
      case 3:
        props.setIsPerson(true)
        break;
      
      default:
        break;
    }

  }


  return (
    <>
      <div className="main">
        <Container  className= "p-3">
          <h2>WAIVER AND RELEASE OF LIABILITY</h2>
          <p>
            In consideration of the opportunity to participate in the 911READY Registration 
            Program (Program), whose registration
            process requires me to accept this agreement, I hereby agree to the
            following Waiver and Release of Liability (Agreement):
          </p>
          <ul>
            <li>Waiver and Release of Liability:</li>
            <ul>
              <li>
                My participation in the Program is voluntary and subjects me to
                the possibility of delay and/or errors in the entry of my
                registration information and exposure to injury or death during
                an emergency response (collectively, Risks). Accordingly, I
                agree to the following:
              </li>
              <ul>
                <li>
                  I hereby release and hold harmless 911READY, its
                  officers, directors, employees, agents, volunteers, and
                  contractors (collectively, Releases) from any claim, demand,
                  loss, liability, damages, and attorney fees and costs
                  whatsoever arising from, related to, or resulting from these
                  Risks (Claims), including those caused by the negligent acts
                  or omissions of any or all of the Releases.
                </li>
                <li>
                  I recognize there are Risks involved in my participation in
                  Program and attest and certify that I recognize that the
                  potential benefits to me and my family of registering with the
                  Program outweigh those Risks.
                </li>
                <li>
                  This Agreement shall be binding on my estate, heirs,
                  executors, administrators, successors, and assigns, as well as
                  any other party asserting a Claim on my behalf or on behalf of
                  my estate.
                </li>
              </ul>
            </ul>
            <li> General Provisions:</li>
            <ul>
              <li>I hereby expressly agree that</li>
              <ul>
                <li>
                  this Agreement shall be governed and construed according to
                  the laws of the state of Michigan without regard to its
                  conflict of law provisions and
                </li>
                <li>
                  any action or proceeding concerning any Claim or the meaning
                  or effect of any provision of the Agreement shall be conducted
                  only in the federal or state courts located in Wayne County,
                  Michigan, and that for such purposes, I expressly submit to
                  the jurisdiction of such courts.
                </li>
              </ul>
              <li>
                This Agreement contains the entire understanding between and
                among the parties concerning these matters. No waiver,
                modification, or amendment of any of the terms of this Agreement
                shall be effective unless made in writing and signed by the
                party to be charged.
              </li>
              <li>
                I hereby expressly agree that if any portion of this Agreement
                is held invalid, the balance of the Agreement shall nonetheless
                continue in full legal force and effect. {props.accept}
              </li>
            </ul>
          </ul>
          <h5 className="font-weight-bold">
            Accept Waiver and Release of Liability
          </h5>
          <FormGroup>
            <Label className="font-weight-bold" for="">
              &emsp;&emsp;&emsp;&emsp;
              <Input type="checkbox" value = {props.accept} onChange= { (e) =>{ props.setAccept(!props.accept); console.log(props.accept)}}/>I agree to the Waiver and Release of
              Liability
            </Label>
          </FormGroup>
          <h5 className="font-weight-bold">
            I'm authorized to register this resident because (select one):
          </h5>
          <FormGroup>
                 <div>
                   <CustomInput onChange={selectOption(1)} className="mb-3" type="radio" id="exampleCustomRadio" name="customRadio" label="I'm the parent or legal guardian" value= {props.personFor}/>
                   <CustomInput onChange={selectOption(2)} className="mb-3" type="radio" id="exampleCustomRadio2" name="customRadio" label="I received permission from the resident(s)" value ={props.permission} />
                   <CustomInput onChange={selectOption(3)} type="radio" id="exampleCustomRadio3" name="customRadio" label="I'm filling it out for myself" value= {props.isPerson}/>
                  </div>
          </FormGroup>
          
          <h5 className="font-weight-bold">Enter Information Accurately</h5>
          <p>
            The street number, street name, apartment/unit information (if
            applicable), city and zip code must be entered correctly. The
            911READY system will not alert you of an error as you fill in the
            fields. When information is securely sent to emergency dispatchers,
            incorrect entries will not be accepted and you will not be notified
            that your entry contained errors. Carefully check the address
            information you input to ensure your registration is accurately
            recorded.
          </p>
          <p>
            I understand I must enter address information correctly in order to
            be logged into the appropriate dispatch system and that failure to
            do so will prevent this registration from being processed.
          </p>
          <FormGroup>
            <Label className="font-weight-bold" for="">
              &emsp;&emsp;&emsp;&emsp;
              <Input type="checkbox" value = {props.agree} onChange= { (e) =>{ console.log(e) ; props.setAgree(!props.agree) }} />I understand
            </Label>
          </FormGroup>
        </Container>
      </div>
    </>
  );
};

export default (withRouter(AddProduct));
