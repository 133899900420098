import React from 'react';
import { Row, Col } from 'reactstrap';
import EditMember from '../../../example-components/Members/MembersEdit'
import {connect} from 'react-redux'

const Households = (props) => {

  return (
    <>
  <Row>
        <Col xl="5" className="d-flex">
          
        </Col>
        <Col xl="7">
            
        </Col>
        <Col xl="12">

          <EditMember/>

        </Col>
       
      </Row>
    
    </>
  );
}
const MapStateToProps = state => {

  return {
    FirstName : state.UserReducer.First,
    UID : state.UserReducer.ID,
    isAuthenticated : state.UserReducer.isAuthenticated,
    }
  
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     onHouseHoldFetch : (HouseHolds) => {
//       dispatch(loadHouseHolds(HouseHolds))
//     } ,

   

//   }};

export default connect(MapStateToProps,null)(Households);