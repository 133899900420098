export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'



export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
  
  }
}

function failedLogout() {
  return {
    type: LOGOUT_FAILURE,
    payload : {
      isFetching: false,
      isAuthenticated: true
    }
  }
}


// Logs the user out
