import React, { useState } from 'react';
import clsx from 'clsx';
import {Nav,NavItem,Row,Col} from 'reactstrap';
import { NavLink as NavLinkStrap } from 'reactstrap';



export default function EditTabs (props)  {



  return (
    
      
   
          <div className="nav-line-alt w-100">
            <Nav className="nav-line d-flex justify-content-center">
              <NavItem>
                <NavLinkStrap
                  className={clsx('p-2 font-size-md', {
                    active: props.activeTab === '1'
                  })}
                  onClick={() => {
                    props.toggle('1');
                  }}>
                  <span className="font-weight-bold font-size-sm text-uppercase">
                    Personal
                  </span>
                  <div className="divider" />
                </NavLinkStrap>
              </NavItem>

              <NavItem>
                <NavLinkStrap
                  className={clsx('p-2 font-size-md', {
                    active: props.activeTab === '3'
                  })}
                  onClick={() => {
                    props.toggle('3');
                  }}>
                  <span className="font-weight-bold font-size-sm text-uppercase">
                   Location
                  </span>
                  <div className="divider" />
                </NavLinkStrap>
              </NavItem>

              <NavItem>
                <NavLinkStrap
                  className={clsx('p-2 font-size-md', {
                    active: props.activeTab === '2'
                  })}
                  onClick={() => {
                    props.toggle('2');
                  }}>
                  <span className="font-weight-bold font-size-sm text-uppercase">
                    Billing
                  </span>
                  <div className="divider" />
                </NavLinkStrap>
              </NavItem>
            </Nav>
            
          </div>
         
       
    
  );
}



