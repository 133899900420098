import React from 'react';

import { Button,UncontrolledTooltip } from 'reactstrap';

import { loadHouseHolds } from '../../reducers/Actions/houseHoldActions';
import { loadMembers } from '../../reducers/Actions/membersActions';
import { connect } from 'react-redux';
import { receiveLogout } from '../../reducers/Actions/logoutActions';
import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Database, Users, Home, UserCheck, ChevronRight } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SidebarWidget } from '../../layout-components';

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile } = props;
  const[hasPrime, setPrimeFlag] = React.useState(null)

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);



  return (
    <>
      <PerfectScrollbar>
        <div className="sidebar-navigation">
          <SidebarWidget />
          <div className="sidebar-header">
            <span>Navigation</span>
          </div>
          <ul>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/Overview">
                <span className="sidebar-icon">
                  <Database />
                </span>
                Overview
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/Members">
                <span className="sidebar-icon">
                  <Users />
                </span>
                Family Members
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>

            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/HouseHolds">
                <span className="sidebar-icon">
                  <Home />
                </span>
                Locations {props.isPrime !== undefined ? props.isPrime ? null : <FontAwesomeIcon
                          icon={['fas', 'exclamation-triangle']}
                          color= "orange"
                          className="ml-2"
                        />: null}
                      
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>

            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/Profile">
                <span className="sidebar-icon">
                  <UserCheck />
                </span>
                Profile
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>
            {/* 
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/Settings">
                <span className="sidebar-icon">
                  <Settings />
                </span>
                Settings
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li> */}

            <li>
              <Button
                color="danger"
                className="m-2"
                onClick={() => {
                  props.LogOut();
                }}>
                <span className="btn-wrapper--label">Logout</span>
              </Button>
            </li>
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  isAuthenticated: state.UserReducer.isAuthenticated,
  HouseHolds: state.UserReducer.HouseHolds,
  UID: state.UserReducer.ID,
  isPrime : state.UserReducer.isPrime
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => {
    dispatch(setSidebarToggleMobile(enable));
  },
  LogOut: () => {
    dispatch(receiveLogout());
  },
  onHouseHoldFetch: (HouseHolds) => {
    dispatch(loadHouseHolds(HouseHolds));
  },
  onMembersFetch: (Members) => {
    dispatch(loadMembers(Members));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
