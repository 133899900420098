import React, {Fragment} from 'react';
import {Button,Input,Label,FormGroup,Form,FormFeedback } from 'reactstrap';
import {verifyEmailNotInUse,RegisterThenLogin} from '../../../utils/API'
import Validator from '../../../utils/Validator'
import {connect} from 'react-redux'
import {BounceLoader } from 'react-spinners';
const CreateAdmin = (props) => {


  
  return (
    <> 
    {props.loading ? ( <div className="text-center py-4 rounded bg-secondary my-4">
                          
                          <div
                            className="d-flex mx-auto align-items-center justify-content-center"
                            style={{ width: '150px', height: '80px' }}>
                            <BounceLoader color={'var(--primary)'} loading={true} />
                          </div>
                        </div>) : null}
      <Fragment>
        <Form>
          <FormGroup>
            <Label for="fname">First Name <span className="icon-danger">*</span></Label>
            <Input type="text" name="fname" id="fname" placeholder="First Name" 
            value = {props.first}
            onChange = {(e) => {

              props.setFirstName(e.target.value)

            }}/>
          </FormGroup>
          <FormGroup>
            <Label for="lname">Last Name <span className="icon-danger">*</span></Label>
            <Input type="text" name="lname" id="lname" placeholder="Last Name"
            value = {props.last}
            onChange = {(e) => {

              props.setLastName(e.target.value)

            }} />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email <span className="icon-danger">*</span></Label>
            <Input placeholder="Email" type="mail" value  = {props.email} onChange = {(e) => {props.setEmail(e.target.value); props.setEmailAvail(true)}} invalid = {!Validator.validateEmail(props.email) || !props.emailIsAvail} />
            {!Validator.validateEmail(props.email)  ? <FormFeedback>Invalid email</FormFeedback> :null}
            {!props.emailIsAvail ? <FormFeedback>Email is already tied to an existing account</FormFeedback> :null}



          </FormGroup>
          <FormGroup>
            <Label for="lname">Phone Number <span className="icon-danger">*</span></Label>
            <Input type="tel" name="phone" id="phone" placeholder="Phone Number"
            value = {props.phone}
            onChange = {(e) => {

              props.setPhone(e.target.value)

            }} />
          </FormGroup>
        </Form>
      </Fragment>
    </>
  );
}
const MapStateToProps = state => {

  return {
    FirstName : state.UserReducer.First,
    UID : state.UserReducer.ID,
    isAuthenticated : state.UserReducer.isAuthenticated,
  }

}



export default connect(MapStateToProps)(CreateAdmin);