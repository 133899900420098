import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  Col,
  Card,
  Container,
  Button,
  UncontrolledTooltip
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import hero8 from '../../../assets/images/hero-bg/hero-1.jpg';
import illustration1 from '../../../assets/images/dkbg_911Ready_logo.png';
import CryptoHeader from './CryptoHeader.js';

import { CityStateLookup } from './../../../example-pages';

export default function LivePreviewExample() {
  
  const [disableRegister, setDisableRegister] = useState(true);



  return (
    <>
      <div className="hero-wrapper bg-composed-wrapper bg-night-sky">
        <div className="header-top-section pb-2">
          <CryptoHeader />
        </div>
        <div className="hero-wrapper--content">
          <div
            className="bg-composed-wrapper--image "
            style={{ backgroundImage: 'url(' + hero8 + ')' }}
          />
          <div className="bg-composed-wrapper--bg bg-second opacity-5" />
          <div className="bg-composed-wrapper--content">
            <Container className="z-over shadow-container-content-5">
              <Row className="py-5">
                <Col lg="6">
                  <div className="pt-3 text-white pt-xl-5 pr-0 pr-xl-5">
                    <h2 className="display-3 font-weight-bold">911 READY</h2>
                    <p className="font-size-xl py-3 text-white-50">
                      Ensure your loved ones safety and security by signing up
                      today, covered household members will have a subscription
                      fee of only $40.00 for the first member and $10.00 for
                      each additional member per year or a monthly subscription of 
                      $4.00 for the first member and $1.00 for
                      each additional member.
                    </p>
                    <CityStateLookup setDisableRegister={setDisableRegister} />
                    <div className="pt-3 m-2">
                      <Button
                        id="btnRegisterCont"
                        tag={NavLink}
                        disabled={disableRegister}
                        to="/Register"
                        size="lg"
                        className="rounded-sm font-weight-bold shadow-second-sm m-2"
                        color="first">
                        <span className="btn-wrapper--label">Register now</span>
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                        </span>
                      </Button>
                      {disableRegister ? (
                        <UncontrolledTooltip target="btnRegisterCont">
                          Check if your city is participating first!
                        </UncontrolledTooltip>
                      ) : (
                        <></>
                      )}
                      <Button
                        tag={NavLink}
                        to="/Login"
                        size="lg"
                        className="rounded-sm font-weight-bold shadow-second-sm m-2"
                        color="success">
                        <span className="btn-wrapper--label">Login</span>
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                        </span>
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col lg="6" className="d-flex align-items-center">
                  <img
                    src={illustration1}
                    alt="..."
                    className="m-auto img-fluid"
                  />
                </Col>
              </Row>
            </Container>
            <div className="pt-3 m-2"></div>
            <div className="pt-4 m-4"></div>
            <div className="shadow-container-blocks-5 z-below">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path
                  fill="var(--white)"
                  fillOpacity="1"
                  d="M0,288L15,266.7C30,245,60,203,90,202.7C120,203,150,245,180,240C210,235,240,181,270,170.7C300,160,330,192,360,176C390,160,420,96,450,96C480,96,510,160,540,186.7C570,213,600,203,630,186.7C660,171,690,149,720,165.3C750,181,780,235,810,218.7C840,203,870,117,900,69.3C930,21,960,11,990,10.7C1020,11,1050,21,1080,42.7C1110,64,1140,96,1170,96C1200,96,1230,64,1260,48C1290,32,1320,32,1350,69.3C1380,107,1410,181,1425,218.7L1440,256L1440,320L1425,320C1410,320,1380,320,1350,320C1320,320,1290,320,1260,320C1230,320,1200,320,1170,320C1140,320,1110,320,1080,320C1050,320,1020,320,990,320C960,320,930,320,900,320C870,320,840,320,810,320C780,320,750,320,720,320C690,320,660,320,630,320C600,320,570,320,540,320C510,320,480,320,450,320C420,320,390,320,360,320C330,320,300,320,270,320C240,320,210,320,180,320C150,320,120,320,90,320C60,320,30,320,15,320L0,320Z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
