import React from 'react';

import PageRegisterCover1 from '../../example-components/PageRegisterCover/PageRegisterCover1';
export default function PageRegisterCover() {
  return (
    <>
      <PageRegisterCover1 />
    </>
  );
}
