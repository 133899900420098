import React from 'react';
import { Row, Col } from 'reactstrap';
import { PageTitle } from '../../layout-components';
import OverviewFamily from '../../example-components/Overview/OverviewFamily';
import OverviewMap from '../../example-components/Overview/OverviewMap';
import OverviewNews from '../../example-components/Overview/OverviewNews';
import OverviewHouseHolds from '../../example-components/Overview/OverviewHouseHolds';
import OverviewPageTitleActions from '../../example-components/Overview/OverviewPageTitleActions';
import {connect} from 'react-redux'

import {fetchAllHouseHolds, fetchAllMembers} from '../../utils/API'
import {loadHouseHolds} from '../../reducers/Actions/houseHoldActions'
import {loadMembers} from '../../reducers/Actions/membersActions'
const Overview = (props) => {

  //console.log("zobb" ,localStorage.getItem("state"))

  const [coordinates,setCoordinates] = React.useState([])
  React.useEffect(() => { 

async function fetchData() {
   props.onHouseHoldFetch(false)
   props.onMembersFetch(false)
    // here is where you make API call(s) or any side effects
    const houseHolds = await fetchAllHouseHolds(props.UID)

    const members = await fetchAllMembers(props.UID)
    setTimeout(() => {
   
      props.onHouseHoldFetch(houseHolds)
      props.onMembersFetch(members)
    }, 1000);

    let hh = []
   

    if(houseHolds && Array.isArray(houseHolds)){

      for(let i =0 ; i< houseHolds.length ; i++){

        hh[i] = {Name : houseHolds[i].Name , Latitude : houseHolds[i].Geo.Lat , Longitude : houseHolds[i].Geo.Lng}

      }

      setCoordinates(hh)
    }

  }

  fetchData()
   

  }, [] ) /** passing empty braces is necessary */

  return (
    <>
      <PageTitle
        titleHeading={`Welcome back, ${props.FirstName}`}
        titleDescription="This page shows an overview for your account summary.">
       
      </PageTitle>

      <Row>
        <Col xl="5" className="d-flex">
          <OverviewFamily />
        </Col>
        <Col xl="7">
         
          <OverviewHouseHolds />
         
        </Col>
        <Col xl="12">

          <OverviewMap
          coordinates = {coordinates}
           containerElement={<div style={{ height: `600px` }} />}
           mapElement={<div style={{ height: `100%` }} />} />
        </Col>
       
      </Row>
    
    </>
  );
}
const MapStateToProps = state => {

  return {
    FirstName : state.UserReducer.First,
    UID : state.UserReducer.ID,
    isAuthenticated : state.UserReducer.isAuthenticated,
    }
  
}

const mapDispatchToProps = (dispatch) => {
  return {
    onHouseHoldFetch : (HouseHolds) => {
      dispatch(loadHouseHolds(HouseHolds))
    } ,
    onMembersFetch : (Members) => {
      dispatch(loadMembers(Members))
    }
   

  }};

export default connect(MapStateToProps,mapDispatchToProps)(Overview);