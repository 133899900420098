import { createStore, combineReducers } from 'redux';
import reducers from './reducers';
import throttle from 'lodash/throttle';
import { loadState, saveState } from './reducers/localStorage'
//load persisted state here

const persistedState = loadState();

export default function configureStore() {
  const str =  createStore(
    combineReducers({
      ...reducers
    }),
    persistedState
  );

  str.subscribe(throttle(() => {
    saveState({
      UserReducer : str.getState().UserReducer,
    });
  }, 1000));

  return str
}