import React, { useState } from 'react';

import clsx from 'clsx';
import {connect} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  InputGroup,
  InputGroupAddon,InputGroupText,FormFeedback,
  Col,
  Card,
  CardHeader,
  Container,
  Label,
  FormGroup,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Button,Modal
} from 'reactstrap';
import { NavLink as NavLinkStrap } from 'reactstrap';
import {updateUserInfo} from '../../../reducers/Actions/loginActions'
import Validator from 'utils/Validator';
import {updateProfile,verifyEmailNotInUse,verifyPhoneNotInUse} from 'utils/API';
import {BounceLoader} from "react-spinners";
import CellAuth from './CellAuth'
import { toast, Zoom } from 'react-toastify';
import EditTabs from './EditTabs';
import ProfileEditForm from './ProfileEditForm';
import LocationEditForm from './LocationEditForm';
import BillingInfoForm from './Billing/BillingInfoForm';
const LivePreviewExample = (props) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };


  return (
    <>
      
        <CardHeader className="d-flex align-items-center justify-content-between card-header-alt p-0">

          <EditTabs activeTab= {activeTab} toggle={toggle}/>
        
        </CardHeader>

        <TabContent className="px-0 py-0 py-lg-5" activeTab={activeTab}>

          <TabPane tabId="1">

            <ProfileEditForm></ProfileEditForm>

          </TabPane>

          <TabPane tabId="3">
            
            <Container>

              <LocationEditForm/>
              
            </Container>
          </TabPane>

          <TabPane tabId="2">

            <Container>

              <BillingInfoForm/>
              
            </Container>
          </TabPane>
          
        
        </TabContent>
      
      
    </>
  );
}

const MapStateToProps = (state) => {
    return {
      First: state.UserReducer.First,
      UID: state.UserReducer.ID,
      Last: state.UserReducer.Last,
      Cell : state.UserReducer.Cell ,
      Email : state.UserReducer.Email
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
     onUpdateUserInfo : (info) => {
         dispatch(updateUserInfo(info))
     }
    };
  };
  
  export default connect(MapStateToProps, mapDispatchToProps)(LivePreviewExample);
