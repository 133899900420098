import React from 'react';
import {connect} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  Col,
  FormGroup,
  Input,
  UncontrolledTooltip,
  Nav,
  NavItem,
  Button
} from 'reactstrap';
import { NavLink as NavLinkStrap,FormFeedback } from 'reactstrap';
import illustration1 from '../../../assets/images/911Ready_logo.png';
import { NavLink } from 'react-router-dom';
import hero8 from '../../../assets/images/hero-bg/hero-2.jpg';
import {fetchAllHouseHolds,fetchAllMembers,login} from '../../../utils/API'
import Validator from '../../../utils/Validator'
import {requestLogin, receiveLogin} from '../../../reducers/Actions/loginActions'
import {loadHouseHolds} from '../../../reducers/Actions/houseHoldActions'
import {loadMembers} from '../../../reducers/Actions/membersActions'
import {BounceLoader } from 'react-spinners';
const LivePreviewExample = (props) => {

  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [remember, setRemember] = React.useState(false)
  const [loading, setLoading] = React.useState(false)


 
  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <Row className="min-vh-100 no-gutters">
                    <Col lg="7" xl="6" className="d-flex align-items-center">
                      <Col md="10" lg="8" xl="7" className="mx-auto">
                        <div className="py-4">
                          <div className="text-center">
                            <h1 className="display-4 mb-1 font-weight-bold">
                              Login
                            </h1>
                            <p className="font-size-lg mb-0 text-black-50">
                              Fill in the fields below to login to your account
                            </p>
                          </div>
                          <div className="text-center py-4 rounded bg-secondary my-4">
                          {loading ? (
              <div
                className="d-flex mx-auto align-items-center justify-content-center"
                style={{ width: '150px', height: '80px' }}>
                <BounceLoader color={'var(--primary)'} loading={true} />
              </div>
            ) : null}
            </div>
                          {/* <div className="text-center text-black-50 mb-4">
                            or sign in with credentials
                          </div> */}
                          <div>
                            <div className="form-group mb-3">
                              <Input placeholder="Email" type="email" value  = {email} onChange = {(e) => {setEmail(e.target.value)}} invalid = {!Validator.validateEmail(email)} />
                              <FormFeedback>Invalid email</FormFeedback>
                            </div>
                            <FormGroup>
                              <Input placeholder="Password" type="password" value  = {password} onChange = {(e) => {setPassword(e.target.value)}} />
                            </FormGroup>
                            <div className="d-flex justify-content-between">
                              <div className="custom-control custom-control-alternative d-flex align-items-center custom-checkbox">
                                <input
                                  className="custom-control-input"
                                  id=" customCheckLogin55"
                                  type="checkbox"
                                  value = {remember}
                                  onChange = {() => {setRemember(!remember)}}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor=" customCheckLogin55">
                                  <span>Remember me</span>
                                </label>
                              </div>
                              <div>
                                <Button
                                 tag={NavLink}
                                  to="/Recover"
                                  className="text-first">
                                  Recover password
                                </Button>
                              </div>
                            </div>
                            <div className="text-center py-4">
                              <Button
                              loading= {true}
                              disabled = {!email || !password || !Validator.validateEmail(email)}
                                color="primary"
                                className="font-weight-bold w-50 my-2" onClick = { async () => {
                                
                                  //incase anyone tries to be smart and inspect element to change disbaled attribute (2nd layer)
                                  if(!email || !password || !Validator.validateEmail(email)) return

                                  setLoading(true)
                                  props.onLoginClick()
                                  let user = await login(email,password,remember)

                                  
                                  setTimeout(() => {
                                    
                                    setLoading(false)
                                  
                                  }, 1000);

                                  if (user && user.ID){

                                    console.log("AHHHH",user)

                                    props.onLoginSuccess(user)


                                    setTimeout(async () => {
                                    
                                      
                                    props.onHouseHoldFetch(await fetchAllHouseHolds(user.ID))
                                    props.onMembersFetch(await fetchAllMembers(user.ID))
                                    
                                    }, 1500);
                                    
                                    //fetch members

                                  }



                                  

                                  console.log("onclick" ,localStorage.getItem("state"))



                              
                                  //save and cache data for session here using redux (local storage in redux)
                                  }}>
                                Sign in

                              </Button>
                            </div>
                            <div className="text-center text-black-50 mt-3">
                              Don't have an account?{' '}
                              <Button
                                 tag={NavLink}
                                  to="/Register"
                                 
                                  className="text-first">
                                  Sign up
                                </Button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Col>
                    <Col lg="5" xl="6" className="d-flex">
                      <div className="hero-wrapper w-100 bg-composed-wrapper bg-premium-dark min-vh-lg-100">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div
                            className="bg-composed-wrapper--image opacity-5"
                            style={{ backgroundImage: 'url(' + hero8 + ')' }}
                          />
                          <div className="bg-composed-wrapper--bg bg-second opacity-6" />
                          <div className="bg-composed-wrapper--bg bg-deep-blue opacity-2" />
                          <div className="bg-composed-wrapper--content text-center p-5">
                            <div className="text-white px-0 px-lg-2 px-xl-4">
                            <img
                    src={illustration1}
                    alt="..."
                    className="m-auto img-fluid"
                    width = {400}

                  />
              
                              <div className="divider mx-auto border-1 my-5 border-light opacity-2 rounded w-25" />
                              <div>
                                <Button
                                 tag={NavLink}
                                 to="/HomePage"
                                  color="success"
                                  className="px-5 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none py-3 hover-scale-sm hover-scale-lg">
                                  <span className="btn-wrapper--label">
                                    Home Page
                                  </span>
                                  <span className="btn-wrapper--icon">
                                    <FontAwesomeIcon
                                      icon={['fas', 'home']}
                                    />
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="hero-footer pb-4">
                          <Nav pills className="nav-neutral-secondary">
                            <NavItem>
                              <NavLinkStrap
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="font-size-lg text-white-50"
                                id="FacebookTooltipExample7">
                                <FontAwesomeIcon icon={['fab', 'facebook']} />
                              </NavLinkStrap>
                              <UncontrolledTooltip
                                target="FacebookTooltipExample7"
                                container="body">
                                Facebook
                              </UncontrolledTooltip>
                            </NavItem>
                            <NavItem>
                              <NavLinkStrap
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="font-size-lg text-white-50"
                                id="btnTwitterTooltip">
                                <FontAwesomeIcon icon={['fab', 'twitter']} />
                              </NavLinkStrap>
                              <UncontrolledTooltip target="btnTwitterTooltip">
                                Twitter
                              </UncontrolledTooltip>
                            </NavItem>
                            <NavItem>
                              <NavLinkStrap
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="font-size-lg text-white-50"
                                id="btnGoogleTooltip">
                                <FontAwesomeIcon icon={['fab', 'google']} />
                              </NavLinkStrap>
                              <UncontrolledTooltip target="btnGoogleTooltip">
                                Google
                              </UncontrolledTooltip>
                            </NavItem>
                            <NavItem>
                              <NavLinkStrap
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="font-size-lg text-white-50"
                                id="btnInstagramTooltip">
                                <FontAwesomeIcon icon={['fab', 'instagram']} />
                              </NavLinkStrap>
                              <UncontrolledTooltip target="btnInstagramTooltip">
                                Instagram
                              </UncontrolledTooltip>
                            </NavItem>
                          </Nav>
                        </div> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
  loading : state.UserReducer.isFetching,
  Fname : state.UserReducer.First

  }
  
};

const mapDispatchToProps = (dispatch) => {
return {
  onLoginClick : () => {
    dispatch(requestLogin())
  } ,

  onLoginSuccess: (data) => {
    dispatch(receiveLogin(data))
  } ,
  onHouseHoldFetch : (HouseHolds) => {
    dispatch(loadHouseHolds(HouseHolds))
  } ,
  onMembersFetch : (Members) => {
    dispatch(loadMembers(Members))
  } ,
}
};

export default connect(mapStateToProps, mapDispatchToProps)(LivePreviewExample);
