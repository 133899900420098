import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  Col,
  Card,
  UncontrolledTooltip,
  Button,
  Input,
  Table
} from 'reactstrap';
import { BeatLoader } from 'react-spinners';
import { checkValidLocations } from 'utils/API';

export default function LivePreviewExample(props) {
  const [city, setCity] = useState('');
  const [state, setState] = useState('mi');
  const [res, setRes] = useState('');
  const [validCity, setValidCity] = useState({ found: false, name: '' });
  const [masterModsFetch, setMasterModsFetch] = useState(false);
  const [error, setError] = React.useState({ e: false, message: '' });

  const convertStateName = (name) => {
    var states = [
      ['Alabama', 'AL'],
      ['Alaska', 'AK'],
      ['American Samoa', 'AS'],
      ['Arizona', 'AZ'],
      ['Arkansas', 'AR'],
      ['Armed Forces Americas', 'AA'],
      ['Armed Forces Europe', 'AE'],
      ['Armed Forces Pacific', 'AP'],
      ['California', 'CA'],
      ['Colorado', 'CO'],
      ['Connecticut', 'CT'],
      ['Delaware', 'DE'],
      ['District Of Columbia', 'DC'],
      ['Florida', 'FL'],
      ['Georgia', 'GA'],
      ['Guam', 'GU'],
      ['Hawaii', 'HI'],
      ['Idaho', 'ID'],
      ['Illinois', 'IL'],
      ['Indiana', 'IN'],
      ['Iowa', 'IA'],
      ['Kansas', 'KS'],
      ['Kentucky', 'KY'],
      ['Louisiana', 'LA'],
      ['Maine', 'ME'],
      ['Marshall Islands', 'MH'],
      ['Maryland', 'MD'],
      ['Massachusetts', 'MA'],
      ['Michigan', 'MI'],
      ['Minnesota', 'MN'],
      ['Mississippi', 'MS'],
      ['Missouri', 'MO'],
      ['Montana', 'MT'],
      ['Nebraska', 'NE'],
      ['Nevada', 'NV'],
      ['New Hampshire', 'NH'],
      ['New Jersey', 'NJ'],
      ['New Mexico', 'NM'],
      ['New York', 'NY'],
      ['North Carolina', 'NC'],
      ['North Dakota', 'ND'],
      ['Northern Mariana Islands', 'NP'],
      ['Ohio', 'OH'],
      ['Oklahoma', 'OK'],
      ['Oregon', 'OR'],
      ['Pennsylvania', 'PA'],
      ['Puerto Rico', 'PR'],
      ['Rhode Island', 'RI'],
      ['South Carolina', 'SC'],
      ['South Dakota', 'SD'],
      ['Tennessee', 'TN'],
      ['Texas', 'TX'],
      ['US Virgin Islands', 'VI'],
      ['Utah', 'UT'],
      ['Vermont', 'VT'],
      ['Virginia', 'VA'],
      ['Washington', 'WA'],
      ['West Virginia', 'WV'],
      ['Wisconsin', 'WI'],
      ['Wyoming', 'WY']
    ];

    var i; // Reusable loop variable
    name = name.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    for (i = 0; i < states.length; i++) {
      if (states[i][0] == name) {
        return states[i][1];
      }
    }

    return null;
  };

  async function fetchData(state = '', city = '') {
    try {
      if (city) city = city.toLowerCase();
      if (state) state = state.toUpperCase();

      if (state && state.length > 2) {
        let check = convertStateName(state);
        console.log('check', check);
        if (!check) {
          setError({
            e: true,
            message:
              " Hmmm...I don't see that city listed. Please double check the spelling or contact your local municipalities to encourage their involvement. Please send this link for them to request to be added: https://911ready.info/first-responder-resources/"
          });
        }
        state = check.toUpperCase();
      }

      setMasterModsFetch(true);

      var mmods = {};

      if (state && !city) mmods = await checkValidLocations({ State: state });
      else if (state && city)
        mmods = await checkValidLocations({ State: state, City: city });

      setMasterModsFetch(false);

      return mmods;
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    props.setDisableRegister(!validCity.found);
  }, [validCity]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!city && !state) {
      setError({ e: true, message: 'Must provide  State / city AND state' });
      return;
    }

    if (city && !state) {
      setError({ e: true, message: 'Must provide a state with your city' });
      return;
    }

    let fetched = await fetchData(state, city);
    if (fetched.data.locations && Array.isArray(fetched.data.locations)) {
      setRes(fetched.data.locations);

      let validate = fetched.data.locations.find((d) => d.city === city);

      setValidCity({ found: validate !== undefined, name: validate });
    } else {
      setError({
        e: true,
        message:
          " Hmmm...I don't see that city listed. Please double check the spelling or contact your local municipalities to encourage their involvement."
      });
      props.setDisableRegister(true);
    }
  };

  return (
    <Col
      md="12"
      style={{
        maxWidth: '40rem',
        margin: '0 auto'
      }}>
      <Row>
        <Col md="12">
          <form onSubmit={onSubmit}>
            <Card className="card-box card-box-hover-rise p-4 card-box-border-bottom border-warning mb-1">
              <h3
                style={{ color: 'black' }}
                className="font-size-xl font-weight-bold">
                Is your city participating?
              </h3>
              <Row>
                <Col>
                  <Input
                    // onBlur={}
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                      setError({ e: false, message: '' });
                    }}
                    md="3"
                    name="cityInput"
                    placeholder="City"
                  />
                </Col>
                <Col>
                  <Input
                    // onBlur={}
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value);
                      setError({ e: false, message: '' });
                    }}
                    md="3"
                    name="stateInput"
                    placeholder="State"
                  />
                </Col>
              </Row>
              <Row
                style={{
                  alignSelf: 'center'
                }}>
                <Button
                  md={3}
                  className="mt-3 mb-3"
                  style={{
                    margin: '0 auto'
                  }}
                  outline
                  disabled={!state}
                  color="primary"
                  size="sm"
                  id="citystatesubmit">
                  {masterModsFetch ? (
                    <BeatLoader color={'white'} loading={true} />
                  ) : (
                    'Search'
                  )}
                </Button>
                {res ? (
                  <>
                    <Button
                      md={3}
                      className="mt-3 mb-3 ml-2"
                      style={{
                        margin: '0 auto'
                      }}
                      outline
                      onClick={() => {
                        setRes('');
                        setCity('');
                        setState('');
                        setError({ e: false, message: '' });
                        setValidCity({ found: false, name: '' });
                      }}
                      color="danger"
                      size="sm"
                      id="citystateClear">
                      Clear
                    </Button>
                    <UncontrolledTooltip target="citystateClear">
                      Clear all inputs and the table
                    </UncontrolledTooltip>
                  </>
                ) : (
                  <></>
                )}
              </Row>
              <UncontrolledTooltip target="citystatesubmit">
                Look up if we support your city or state
              </UncontrolledTooltip>
              {error.e && error.message ? (
                <p className="text-danger">{error.message}</p>
              ) : (
                <>
                  {res ? (
                    <Table
                      style={{
                        overflowX: 'auto'
                      }}>
                      <thead>
                        <tr>
                          <th style={{ maxWidth: '5px' }}>{'   '}</th>
                          <th>City</th>
                          <th>State</th>
                        </tr>
                      </thead>
                      <tbody>
                        {res.map((d, i) => (
                          <tr key={i}>
                            <td style={{ maxWidth: '5px' }}>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked)
                                    setValidCity({ found: true, name: d.city });
                                  else if (!e.target.checked)
                                    setValidCity({
                                      found: false,
                                      name: ''
                                    });
                                }}
                                type="checkbox"
                                name={`${d.city}-checkbox`}
                                id={`${d.city}-checkbox`}
                              />
                            </td>
                            <td>
                              {validCity.name === d.city ? (
                                <FontAwesomeIcon
                                  color="green"
                                  icon={['fas', 'check-circle']}
                                />
                              ) : (
                                <></>
                              )}{' '}
                              {d.city}
                            </td>
                            <td>{d.state}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Card>
          </form>
        </Col>
      </Row>
    </Col>
  );
}
