import React, {Fragment} from 'react';
import { Card, CustomInput, Table } from 'reactstrap'
import { PageTitle } from '../../layout-components';
import {connect} from 'react-redux'

import {fetchAllHouseHolds, fetchAllMembers} from '../../utils/API'
import {loadHouseHolds} from '../../reducers/Actions/houseHoldActions'
import {loadMembers} from '../../reducers/Actions/membersActions'
const FFRDeactivatedEntry = (props) => {

  //console.log("zobb" ,localStorage.getItem("state"))

  const [coordinates,setCoordinates] = React.useState([])
  React.useEffect(() => {

    async function fetchData() {
      props.onHouseHoldFetch(false)
      props.onMembersFetch(false)
      // here is where you make API call(s) or any side effects
      const houseHolds = await fetchAllHouseHolds(props.UID)

      const members = await fetchAllMembers(props.UID)
      setTimeout(() => {

        props.onHouseHoldFetch(houseHolds)
        props.onMembersFetch(members)
      }, 1000);

      let hh = []
      console.log(houseHolds)

      if(houseHolds && Array.isArray(houseHolds)){

        for(let i =0 ; i< houseHolds.length ; i++){

          hh[i] = {Name : houseHolds[i].Name , Latitude : houseHolds[i].Geo.Lat , Longitude : houseHolds[i].Geo.Lng}

        }

        setCoordinates(hh)
      }

    }

    fetchData()


  }, [] ) /** passing empty braces is necessary */

  return (
    <>
      <PageTitle
        titleHeading={`Welcome back ${props.FirstName}`} 
        titleDescription="This page displays all deactivated entries.">

      </PageTitle>

      <Fragment>

      <Card className="card-box mb-5">
        <div className="card-header py-3">
          <div className="card-header--title font-size-lg">
            Deactivated entries. 
          </div>
          <div className="card-header--actions">
          </div>
        </div>
        <div className="table-responsive-md">
        <Table hover className="text-nowrap mb-0">
            <thead>
              <tr>
                <th className="bg-white text-center">ID</th>
                <th className="bg-white text-center">Name</th>
                <th className="bg-white text-center">Date Deactivated</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="font-weight-bold text-center">
                  #453
                </td>
                <td className="text-center">
                  John Doe                 
                </td>
                <td className="text-center">
                  12/09/2021
                </td>
              </tr>
            </tbody>
          </Table>
       </div>
        
        </Card>  

      </Fragment>

    </>
  );
}
const MapStateToProps = state => {

  return {
    FirstName : state.UserReducer.First,
    UID : state.UserReducer.ID,
    isAuthenticated : state.UserReducer.isAuthenticated,
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    onHouseHoldFetch : (HouseHolds) => {
      dispatch(loadHouseHolds(HouseHolds))
    } ,
    onMembersFetch : (Members) => {
      dispatch(loadMembers(Members))
    }


  }};

export default connect(MapStateToProps,mapDispatchToProps)(FFRDeactivatedEntry);
