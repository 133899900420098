import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';
import {receiveLogout} from '../../reducers/Actions/logoutActions'
import { NavLink as NavLinkStrap } from 'reactstrap';
import {connect} from 'react-redux'
import {  LogOut } from 'react-feather';

import avatar3 from '../../assets/images/placeholder.jpg';

const HeaderUserbox = (props) => {
  return (
    <>
      <UncontrolledDropdown className="user-box position-relative mr-2">
        <DropdownToggle
          color="link"
          className="p-0 text-left d-flex align-items-center">
          <div className="d-block p-0 avatar-icon-wrapper">
            
            <div className="avatar-icon rounded">
              <img src={avatar3} alt="..." />
            </div>
          </div>
          <div className="d-none d-xl-block pl-2">
            <span className="text-success">
              <small>Account verified</small>
            </span>
            <div className="font-weight-bold">{props.First + ' ' + props.Last}</div>
          </div>
          <span className="pl-1 pl-xl-3">
            <FontAwesomeIcon
              icon={['fas', 'angle-down']}
              className="opacity-5"
            />
          </span>
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-lg overflow-hidden p-0">
         
        
           
          <div className="divider" />
          
          <div className="divider" />
          <Nav className="nav-neutral-danger nav-pills-rounded flex-column p-3">
            <NavItem>
              <NavLinkStrap onClick={(e) => props.LogOut()}>
                <div className="nav-link-icon">
                  <LogOut />
                </div>
                <span>Log out</span>
              </NavLinkStrap>
            </NavItem>
          </Nav>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};
const mapStateToProps = (state) => ({
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  isAuthenticated : state.UserReducer.isAuthenticated,
  First : state.UserReducer.First,
  Last : state.UserReducer.Last,
  UID : state.UserReducer.ID,
  Email : state.UserReducer.Email
});

const mapDispatchToProps = (dispatch) => ({
  
  LogOut : () => {dispatch(receiveLogout())},
  
 
});

export default connect(mapStateToProps,mapDispatchToProps)(HeaderUserbox);

