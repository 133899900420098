export const ADD_MEMBER = 'ADD_MEMBER'
export const LOAD_MEMBERS = 'LOAD_MEMBERS'
export const DELETE_MEMBER = 'DELETE_MEMBER'
export const UPDATE_MEMBER = 'UPDATE_MEMBER'


export function loadMembers(Members) {
  return {
    type: LOAD_MEMBERS,
    payload : { 
        Members
      
    }
  }
}

export function addMember(Member) {
  return {
    type: ADD_MEMBER,
    payload : {
        Member
    }
  }
}

export function deleteMember(MemberID) {
  return {
    type: DELETE_MEMBER,
    payload : {
        MemberID
    }
  }
}

export function updateMember(Member) {
    return {
      type: UPDATE_MEMBER,
      payload : {
        Member
      }
    }
  }