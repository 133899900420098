import React from 'react';
import { Row, Col } from 'reactstrap';
import { PageTitle } from '../../layout-components';
import ListMembers from '../../example-components/Members/MembersList';
import {connect} from 'react-redux'
import {fetchAllMembers} from '../../utils/API'
import {loadMembers} from '../../reducers/Actions/membersActions'
const Members = (props) => {

  React.useEffect(() => { 

    async function fetchData() {
       props.onMembersFetch(false)
      
        // here is where you make API call(s) or any side effects
        const members =  await fetchAllMembers(props.UID)
    
       
        setTimeout(() => {
       
          props.onMembersFetch(members)
       
        }, 500);
    
      }
    
      fetchData()
       
    
      }, [] ) /** passing empty braces is necessary */
  return (
    <>
      <PageTitle
        titleHeading={`Welcome back, ${props.FirstName}`}
        titleDescription="This page shows a list of your Registered family members">
       
      </PageTitle>
      

      <Row>
        <Col xl="5" className="d-flex">
          
        </Col>
        <Col xl="7">
         
         
         
        </Col>
        <Col xl="12">

         <ListMembers></ListMembers>
        </Col>
       
      </Row>
    
    </>
  );
}
const MapStateToProps = state => {

  return {
    FirstName : state.UserReducer.First,
    UID : state.UserReducer.ID,
    isAuthenticated : state.UserReducer.isAuthenticated,
    }
  
}

const mapDispatchToProps = (dispatch) => {
  return {
    onMembersFetch : (Members) => {
      dispatch(loadMembers(Members))
    } ,

  }};

export default connect(MapStateToProps,mapDispatchToProps)(Members);