import React, { Fragment } from 'react'
import { Card,Button,Table,Modal, ModalBody, ModalFooter,ModalHeader,DropdownMenu,CardBody,DropdownToggle,UncontrolledDropdown,Badge } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {loadAdmins,AddAdmin, AddMod} from 'reducers/Actions/roleActions'
import {ListAdmins,verifyEmailNotInUse,createAdmin} from 'utils/API'
import Validator from 'utils/Validator'
import { toast,Zoom } from 'react-toastify';
import {connect} from 'react-redux';
import { PageTitle } from 'layout-components';
import CreateAdmin from './CreateAdmin';
import moment from 'moment';
const  LivePreviewExample = (props) => {

  const [modal, setModal] = React.useState(false);
  const toggleModal = () => setModal(!modal);

  React.useEffect(() => {

    async function fetchData() {
     
      try {

        props.onAdminsFetch(false)

        const admins = await ListAdmins()
  
        console.log("arseee",admins)


        props.onAdminsFetch(admins)

        

      } catch (error) {
        console.error(error)
      }

    }

    fetchData()


  }, [] ) /** passing empty braces is necessary */

  const [ first, setFirstName] = React.useState('')
  const [ last, setLastName] = React.useState('')
  const [ email, setEmail] = React.useState('')
  const [ phone, setPhone] = React.useState('')
  const [emailIsAvail, setEmailAvail] = React.useState(true)
  const [loading, setLoading] = React.useState(false)

  console.log(props)

  return (
    <>
    <PageTitle
        titleHeading={`Welcome back ${props.FirstName}`}
        titleDescription="This page displays administrators.">

      </PageTitle>
      <Modal zIndex={2000} centered size="lg" isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Create a new Admin</ModalHeader>
                    <ModalBody>
                        <CreateAdmin
                        first = {first}
                        setFirstName = {setFirstName}
                        last = {last}
                        setLastName = {setLastName}
                        email = {email}
                        setEmail = {setEmail}
                        phone = {phone}
                        setPhone = {setPhone}
                        emailIsAvail = {emailIsAvail}
                        setEmailAvail = {setEmailAvail}
                        loading = {loading}
                        setLoading = {setLoading}
                         ></CreateAdmin>
                    </ModalBody>
                    <ModalFooter className = "p-3">
                    <Button color="danger" className="my-auto" onClick={toggleModal}>Discard and close</Button>
                        <Button color="primary" className="my-auto" onClick={async () => {

                              try {
                                
                              
                              setLoading(true)

                              if (!first || !last ||  !email || !phone){

                                toast.error('Please fill out all fields before creating your account', {
                                  position: "top-center",
                                  autoClose: 7000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  });

                                  setLoading(false)
                                  return
                              }

                              
                              if (!Validator.validateEmail(email)) {

                                toast.error('Please enter a valid email', {
                                  position: "top-center",
                                  autoClose: 7000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  });

                                  setLoading(false)
                                  return

                              }

                              if (!Validator.validatePhoneNumber(phone)) {

                                toast.error('Please enter a valid Phone number', {
                                  position: "top-center",
                                  autoClose: 7000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  });

                                  setLoading(false)
                                  return

                              }
                            

                              const inU = await verifyEmailNotInUse(email)

                              if (!inU.isAvail){

                                setEmailAvail(false)

                                toast.error('Email you are trying to sign up with is already associated to an existing account', {
                                  position: "top-center",
                                  autoClose: 10000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  });

                                setLoading(false)
                                return
                              }

                              const param = {
                                FirstName : first,
                                LastName : last,
                                Email : email,
                               Cell : phone
                              }


                              const upload = await createAdmin(param)
                              setTimeout(() => {
                                
                                setLoading(false)
                               
                               }, 1000);
  
                               if (upload){
  
                                  setEmail('')
                                  setFirstName('')
                                  setLastName('')
                                  setPhone('')
                                 
                                  props.onAdminAdd(upload.Account)

                                  setModal(false)

                                  toast.success(upload.Message, {
                                    containerId: 'B',
                                    transition: Zoom
                                  })
          
                               }
                             
                              console.log(upload)
                            } catch (error) {
                                setLoading(false)
                                toast.error(`Error Creating new Admin Account ${error}`, {
                                  containerId: 'B',
                                  transition: Zoom
                                })
                            }
                           
                        }}>Save and upload</Button>
                    </ModalFooter>
       </Modal>
       <Fragment>

<Card className="card-box mb-5">
    <div className="card-header pr-2">
        <div className="card-header--title" >
            List of Administrators
</div>
        <div className="card-header--actions">
        <Button   color="primary"onClick = {() => toggleModal()} >
          <span className= "m-2">Create new Administrator</span>
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon icon={['fas', 'plus-circle']} className="text-white" />
            </span>
        </Button>

        </div>
    </div>
    <CardBody>
        <div className="table-responsive-md">
            <Table hover borderless className="text-nowrap mb-0">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th className="text-left">Name</th>
                        <th className="text-center">Email</th>
                        <th className="text-center">Phone</th>
                        <th className="text-center">Created on</th>
                        <th className="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                {props.Admins ?   props.Admins.length > 0 ?  
    
    props.Admins.map((entry, index) => {
       return (
        <>

            <tr key= {index}>
              <td>
               <b style={{fontWeight:"bold"}}> {entry.ID} </b>
              </td>
              <td>
              <div className="d-flex align-items-center">
                
              <div className="avatar-icon-wrapper avatar-initials">
                  <div className="avatar-icon text-white bg-primary avatar-icon-xs">
                  {`${entry.FirstName[0]} ${entry.LastName[0]}`}
                  </div>
              </div>

                <div>
                  <a href="#/" onClick={e => e.preventDefault()} className="font-weight-bold text-black" title="...">
                  {`${entry.FirstName} ${entry.LastName}`} 
                  </a>
                    </div>
                    </div>
                     </td>
                      <td className="text-center">
                        <span>{entry.Email}</span>
                      </td>
                        <td  className="text-center">
                       <span> {entry.Cell}</span>
                      </td>
                      <td  className="text-center">
                       <span> {moment(entry.CreateDate).format('ddd MMM DD  YYYY')}</span>
                      </td>
                      <td className="text-center">
                                <UncontrolledDropdown>
                                    <DropdownToggle color="primary" size="lg" className="px-2 py-0 no-caret">
                                        <FontAwesomeIcon icon={['fas', 'ellipsis-h']} className="font-size-lg" />
                                    </DropdownToggle>
                                    <DropdownMenu right className="dropdown-menu overflow-hidden p-3">
                                        <Button size="sm" color="primary">Restrict</Button>
                                        <br></br>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </td>
                    </tr>

        
        </>) 
    } ): <div className="mx-auto my-auto"> <p> No Administrators to show here</p> </div> : <div className="mx-auto my-auto"> <p>Loading...</p> </div> } 
                    
                  
                    
                </tbody>
            </Table>
        </div>
    </CardBody>
    <div className="card-footer d-flex justify-content-between">
        
    </div>
</Card>
</Fragment>
    </>
  );
}

const MapStateToProps = state => {

  return {
   
    FirstName: state.UserReducer.First,
    Token : state.UserReducer.Token,
    Admins: state.UserReducer.Admins

  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    onAdminsFetch: (Admins) => {
      dispatch(loadAdmins(Admins))
    } ,
    onAdminAdd : (Admin) => {
      dispatch(AddAdmin(Admin))
    }


  }};



export default connect(MapStateToProps,mapDispatchToProps)(LivePreviewExample);