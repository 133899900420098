import React, { Fragment } from 'react';
import Loki from 'react-loki';
import clsx from 'clsx';
import { toast, Zoom } from 'react-toastify';
import Swal from 'sweetalert2';
// reactstrap components
import { Button } from 'reactstrap';
import CreateMemberStep1 from './CreateM1';
import CreateMemberStep2 from './CreateM2';
import CreateWaiver from './CreateWaiver';
import Pricing from './Pricing'
import Payment from './Payment';
import { connect } from 'react-redux';
import { addMember } from '../../../reducers/Actions/membersActions';
import {uploadImage,uploadFamilyMember} from 'utils/API';
import { GeneralModal } from './Modal';
const LivePreviewExample = (props) => {
  const [modal4, setModal4] = React.useState(false);
  const toggle4 = () => setModal4(!modal4);
  //for the waiver //
  const [accept, setAccept] = React.useState(false);
  const [personFor, setPerson] = React.useState(false);
  const [permission, setPerm] = React.useState(false);
  const [isPerson, setIsPerson] = React.useState(false);
  const [agree, setAgree] = React.useState(false);

  // for user info
  const [first, setFirst] = React.useState('');
  const [last, setLast] = React.useState('');
  const [sex, setSex] = React.useState(false);
  const [dob, setDob] = React.useState(null);
  const [feet, setFeet] = React.useState(false);
  const [inches, setInches] = React.useState(0);
  const [weight, setWeight] = React.useState('');
  const [mail, setMail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [image, setImage] = React.useState('');
  const [additional, setAdditional] = React.useState('');
  const [emailIsAvail, setEmailAvail] = React.useState(true)
  const [phoneIsAvail, setPhoneAvail] = React.useState(true)
  const [plan, selectedPlan] = React.useState(1)
  const [price, setPrice] = React.useState(false)
  const [Medical, setMedical] = React.useState({});
  const [paymentApproved, setPaymentApproval] = React.useState(false)
  const [generalModalSettings, setGMSettings] = React.useState(false)
  const handleCloseGeneralModal = () => {
    setGMSettings({...generalModalSettings, isOpen : false})
  };
  //homes selected

  const uploadData = async (orderId) =>  {
    
    try {
      
  
    

    const newMemberData = {
      Parent_ID: props.UID,
      First: first,
      Last: last,
      Sex: sex,
      Dob: dob,
      Feet: feet,
      Inches: inches,
      Weight: weight,
      OrderID : orderId,
      additional : additional
    };

    if (phone.trim()) newMemberData.Phone = phone.trim();

    if (mail.trim()) newMemberData.Mail = mail.trim();

    if (additional.trim())
      newMemberData.Additional = additional.trim();
    if (Medical) newMemberData.Medical = Medical;

    if (image) {
       await uploadImage(image).then((url)=>{

        newMemberData.img = url;

      })
      
    }

    let data = await uploadFamilyMember(newMemberData)
    setTimeout(() => {
      console.log(data)
     
       // props.onMemberCreate(data); // coming back as null
        setGMSettings({isOpen : false, message : ``, type : ''});
        window.location = '/Members';

        toast.success('New Member had been registered !', {
          containerId: 'B',
          transition: Zoom
        });
      
    }, 1000);



  } catch (error) {
    setGMSettings({isOpen : true, message : `An error occured, please try again later`, type : 'error'});
    }
  }

  const customSteps = [
    {
      label: 'Important information',
      number: '1',
      component: 
      <CreateWaiver
          accept={accept}
          setAccept={setAccept}
          personFor={personFor}
          setPerson={setPerson}
          permission={permission}
          setPerm={setPerm}
          isPerson={isPerson}
          setIsPerson={setIsPerson}
          agree={agree}
          setAgree={setAgree}
          
        />
      
    },
    {
      label: "Member's information",
      number: '2',
      component: (
        <CreateMemberStep1
          first={first}
          setFirst={setFirst}
          last={last}
          setLast={setLast}
          sex={sex}
          setSex={setSex}
          dob={dob}
          setDob={setDob}
          feet={feet}
          setFeet={setFeet}
          inches={inches}
          setInches={setInches}
          weight={weight}
          setWeight={setWeight}
          mail={mail}
          setMail={setMail}
          phone={phone}
          setPhone={setPhone}
          image={image}
          setImage={setImage}
          additional={additional}
          setAdditional={setAdditional}
        />
      )
    },
    {
      label: "Member's Medical information",
      number: '3',
      component: <CreateMemberStep2 Medical={Medical} setMedical={setMedical} />
    },
    {
      label: "Select a plan",
      number: '4',
      component: <Pricing setPrice = {setPrice} setPlan={selectedPlan} plan={plan}/>
    },
    {
      label: "Payment",
      number: '5',
      component: <Payment price = {price}  plan={plan} setPaymentApproval = {setPaymentApproval} upload = {uploadData} setModalSettings={setGMSettings} />
    }
  ];

  const _customRenderer = ({ currentStep }) => {
    const steps = customSteps.map((step, index) => {
      const isActive = currentStep === index + 1;

      return (
        <li key={index} className={clsx('card-box', { current: isActive })}>
          <a href="#/" onClick={(e) => e.preventDefault()}>
            <div className="label">{step.label}</div>
            <div className="step-indicator">{step.number}</div>
          </a>
        </li>
      );
    });

    return (
      <div className="horizontal">
        <ul className="steps-indicator">{steps}</ul>
      </div>
    );
  };

  const _customActions = ({
    isComplete,
    cantBack,
    isInFinalStep,
    backHandler,
    nextHandler,
    currentStep
  }) => {
    return (
      <>
        <div className="actions p-3">
          <Button
            outline
            color="primary"
            onClick={backHandler}
            disabled={cantBack || isComplete}>
            Previous
          </Button>
          
         {currentStep !==5  ?  <Button
            color="primary"
          
            onClick={
                nextHandler
              } 
              
              
              
            disabled={_validateInput(currentStep)}>
            {isInFinalStep ? 'Finish' : 'Next'}
          </Button> : null }
        </div>
      </>
    );
  };
  const _validateInput = (step) => {
    try {
      switch (step) {
        case 1:
          return !_validateInputByStep(
            [accept, personFor, permission, isPerson, agree],
            step
          );

        case 2:
          return !_validateInputByStep( //must pass in image and validate email and phone if its there
            [first.trim(), last.trim(), sex, dob, feet, inches, weight.trim(),emailIsAvail,phoneIsAvail, image],
            step
          );

        case 3:
          break;
        case 4:
          return !_validateInputByStep([plan,price],step)
        default:
      }
    } catch (error) {
      return false;
    }
  };
  const _validateInputByStep =  (concentParams, step) => {
    try {
      if (!Array.isArray(concentParams)) return false;

      switch (step) {
        case 1: {
          if (!concentParams[0]) {
          
            return false;
          }

          if (!concentParams[1] && !concentParams[2] && !concentParams[3]) {
            return false;
          }

          if (!concentParams[4]) {
            return false;
          }

          return true;
        }
        case 2: {
          if (
            !concentParams[0] ||
            !concentParams[1] ||
            !concentParams[2] ||
            !concentParams[3] ||
            !concentParams[4] ||
             concentParams[4] <= 0   ||
             concentParams[5] < 0    ||
             concentParams[2] == -1  ||
            !concentParams[6] ||
            !concentParams[7] || 
            !concentParams[8] ||
            !concentParams[9]
          ) {
            return false;
          }
          
         

          return true;
        }

        case 3:
          break;
        case 4:{

          if (!concentParams[0] ||  (concentParams[0]  !== 1 && concentParams[0]  !== 2))
            return false
          if(!concentParams[1]  && concentParams[0]  === 1){

            if (props.Members  && Array.isArray(props.Members))
              if(props.Members.length > 0)
                  setPrice(1)
              else
                setPrice(4)

          }
          if(!concentParams[1]  && concentParams[0] === 2){

            if (props.Members  && Array.isArray(props.Members))
              if(props.Members.length > 0)
                  setPrice(10)
              else
                setPrice(40)

          }
           return true 
        }
          
        default:
      }
    } catch (error) {
      return false;
    }
  };

  const _onFinish = () => {
    //UPLOAD EVERYTHING HERE // validate shit too

    setModal4(true);
  };

  return (
    <Fragment>
    <GeneralModal open={generalModalSettings.isOpen} type = {generalModalSettings.type} text = {generalModalSettings.message} onClose = {handleCloseGeneralModal}>
        <GeneralModal.GeneralModalContent>  
        </GeneralModal.GeneralModalContent>
    </GeneralModal>
      <div className="card card-box">
        <div className="wizard">
          <Loki
            steps={customSteps}
            renderSteps={_customRenderer}
            renderActions={_customActions}
            onFinish={_onFinish}
          />
        </div>
      </div>
    </Fragment>
  );
};

const MapStateToProps = (state) => {
  return {
    FirstName: state.UserReducer.First,
    UID: state.UserReducer.ID,
    isAuthenticated: state.UserReducer.isAuthenticated,
    Members : state.UserReducer.Members
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onMemberCreate: (Member) => {
      dispatch(addMember(Member));
    }
  };
};

export default connect(MapStateToProps, mapDispatchToProps)(LivePreviewExample);
