const { toast } = require('react-toastify');
const axios = require('axios');
const BASE_URL = process.env.REACT_APP_BASE_URL;
// TODO: PROXY HITS PROD BUT WE NEED IT TO HIT DIFF ENV BASED ON BRANCH

const notifyError = (message) =>
  toast.error(message, {
    position: 'top-right',
    autoClose: 7000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });

const notifySuccess = (message) =>
  toast.success(message, {
    position: 'top-right',
    autoClose: 7000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });

export async function login(Email, Password, remember) {
  let result = false;
  await axios
    .post(
      `${BASE_URL}/account/login`,
      {
        Email: Email,
        Password: Password
      },
      { 'Content-Type': 'application/json' }
    )
    .then((response) => {
      console.log(response.data)
      result = response.data.Account;
      result.JWT = response.data.JWT
      result.Role = response.data.Role.Tier;
      result.Location = {
        City: response.data.Role.Location,
        State: response.data.Role.StateP,
        Zip: response.data.Role.Zip,
        Street: response.data.Role.Street
      };
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result;
}
export async function register(Email, Password, Fname, Lname, Cell, Location) {
  let result;
  await axios
    .post(
      `${BASE_URL}/account`,
      {
        Email: Email,
        Password: Password,
        FirstName: Fname,
        LastName: Lname,
        Cell: Cell,
        Location: Location
      },
      { 'Content-Type': 'application/json' }
    )
    .then((response) => {
      result = response.data;
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result;
}
export async function verifyPhone(Cell) {
  let result;
  await axios
    .post(
      `${BASE_URL}/cellAuth`,
      {
        Cell
      },
      { 'Content-Type': 'application/json' }
    )
    .then((response) => {
      console.log(response.data);
      result = response.data.Message;
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result;
}

export async function verifyPhoneNotInUse(Cell) {
  let result;
  await axios
    .post(
      `${BASE_URL}/phoneAvail`,
      {
        Cell
      },
      { 'Content-Type': 'application/json' }
    )
    .then((response) => {
      console.log(response.data);
      result = response.data;
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result;
}

export async function verifyEmailNotInUse(Email) {
  let result;
  await axios
    .post(
      `${BASE_URL}/emailAvail`,
      {
        Email
      },
      { 'Content-Type': 'application/json' }
    )
    .then((response) => {
      console.log(response.data);
      result = response.data;
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result;
}
export async function RegisterThenLogin(
  Email,
  Password,
  Fname,
  Lname,
  Cell,
  Location
) {
  // add last param as location and verify

  try {
    const registerd = await register(
      Email,
      Password,
      Fname,
      Lname,
      Cell,
      Location
    );

    console.log(registerd);

    if (registerd && registerd.Message) {
      const logedin = await login(Email, Password);

      console.log(logedin);

      return logedin;
    }
  } catch (error) {
    console.log(error);
  }
}
export async function fetchAllHouseHolds(UID) {
  let result;
  await axios
    .post(
      `${BASE_URL}/household/all`,
      {
        UID
      },
      { 'Content-Type': 'application/json' }
    )
    .then((response) => {
      console.log(response.data.HouseHolds);
      result = response.data.HouseHolds;
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result;
}

export async function fetchAllMembers(UID) {
  let result;
  await axios
    .post(
      `${BASE_URL}/members/all`,
      {
        UID
      },
      { 'Content-Type': 'application/json' }
    )
    .then((response) => {
      console.log(response.data);
      result = response.data.Members;
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result;
}
export async function uploadFamilyMember(request) {
  
  const result = await axios
    .post(`${BASE_URL}/member`, request, { 'Content-Type': 'application/json' })
    .then((response) => {
      console.log(response.data);
      return response.data.Member;
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
      return false
    });

  return result;
}
export async function uploadImage(image) {
  // split the filename to get the name and type
  let fileParts = image.name.split('.');
  console.log(fileParts);
  let fileName = fileParts[0];
  let fileType = fileParts[1];

  let request_data = {
    fileName: fileName,
    fileType: fileType
  };

  let result;
  await axios
    .post(`${BASE_URL}/images/upload`, request_data)
    .then(async (response) => {
      console.log(response.data);
      result = response.data;

      const signedRequest = result.signedRequest;

      // Put the fileType in the headers for the upload
      var options = {
        headers: {
          'Content-Type': fileType
        }
      };

      await axios
        .put(signedRequest, image, options)
        .then((res) => {
          if (res) {
            return result.url;
          }
        })
        .catch((e) => {
          notifyError(e.response.data.Message);
        });
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result.url;
}
export async function uploadHouseHold(request) {
  let result;
  await axios
    .post(`${BASE_URL}/household`, request, {
      'Content-Type': 'application/json'
    })
    .then((response) => {
      console.log(response.data);
      result = response.data.HouseHold;
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result;
}
export async function updateAHouseHold(request) {
  let result;
  await axios
    .patch(`${BASE_URL}/household`, request, {
      'Content-Type': 'application/json'
    })
    .then((response) => {
      console.log(response.data.HouseHold);
      result = response.data.HouseHold;
    })
    .catch((e) => {
      notifyError(e);
    });

  return result;
}
export async function updateAMember(request) {
  let result;
  await axios
    .patch(`${BASE_URL}/member`, request, {
      'Content-Type': 'application/json'
    })
    .then((response) => {
      console.log(response.data.Member);
      result = response.data.Member;
    })
    .catch((e) => {
      notifyError(e);
    });

  return result;
}
export async function addMemberToHouseHold(request) {
  let result;
  await axios
    .post(`${BASE_URL}/household/members/add`, request, {
      'Content-Type': 'application/json'
    })
    .then((response) => {
      console.log(response.data);
      result = response.data.LinkRecord.ID;
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result;
}
export async function removeMemberToHouseHold(request) {
  let result;
  await axios
    .post(`${BASE_URL}/household/members/remove`, request, {
      'Content-Type': 'application/json'
    })
    .then((response) => {
      console.log(response.data);
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result;
}
export async function updateProfile(request) {
  let result;
  await axios
    .post(`${BASE_URL}/account/update`, request, {
      'Content-Type': 'application/json'
    })
    .then((response) => {
      console.log(response.data);
      result = response.data.Account;
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result;
}

export async function fetchUpdatedEntries(City = false, State = false) {
  let result;

  let params = {};

  if (City && State) {
    params = {
      City,
      State
    };
  }
  await axios
    .post(`${BASE_URL}/listUpdatedEntries`, params, {
      'Content-Type': 'application/json'
    })
    .then((response) => {
      console.log(response.data);
      result = response.data;
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result;
}

export async function fetchNewEntries(City = false, State = false) {
  let result;

  let params = {};

  if (City && State) {
    params = {
      City,
      State
    };
  }
  await axios
    .post(`${BASE_URL}/listNewEntries`, params, {
      'Content-Type': 'application/json'
    })
    .then((response) => {
      console.log(response.data);
      result = response.data;
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result;
}

//add check here if admin or other mf is calling it
export async function ListMods(City = false, State = false) {
  let result;

  let params = {};

  if (City && State) {
    params = {
      City,
      State
    };
  }
  await axios
    .post(`${BASE_URL}/mods/list`, params, {
      'Content-Type': 'application/json'
    })
    .then((response) => {
      console.log(response.data);
      result = response.data;
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });
  return result;
}

export async function ListMasterMods() {
  let result;
  await axios
    .post(
      `${BASE_URL}/mastermods/list`,
      {},
      { 'Content-Type': 'application/json' }
    )
    .then((response) => {
      console.log(response.data);
      result = response.data;
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result;
}

export async function ListUsers(cityName = false, State = false) {
  let result;

  let params = {};

  if (cityName && State) {
    params = {
      City: cityName,
      State: State
    };
  }
  await axios
    .post(`${BASE_URL}/users/list`, params, {
      'Content-Type': 'application/json'
    })
    .then((response) => {
      console.log(response.data);
      result = response.data;
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });
  return result;
}

export async function ListAdmins() {
  let result;
  await axios
    .post(`${BASE_URL}/admin/list`, {}, { 'Content-Type': 'application/json' })
    .then((response) => {
      console.log('HERER :', response);
      result = response.data;
    })
    .catch((e) => {
      console.log(e);
      notifyError(e.response.data.Message);
    });

  return result;
}

export async function createAdmin(Admin) {
  let result;
  await axios
    .post(`${BASE_URL}/create/admin`, Admin, {
      'Content-Type': 'application/json'
    })
    .then((response) => {
      console.log(response.data);
      result = response.data;
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result;
}

export async function TransferEntry(EID) {
  let result = false;
  await axios
    .post(
      `${BASE_URL}/markEntry`,
      {
        EntryID: EID
      },

      { 'Content-Type': 'application/json' }
    )
    .then((response) => {
      console.log(response.data);

      result = true;
      notifySuccess(response.data.Message);
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result;
}

export async function CreateMod(Mod) {
  let result;
  await axios
    .post(
      `${BASE_URL}/create/moderator`,
      Mod,

      { 'Content-Type': 'application/json' }
    )
    .then((response) => {
      console.log(response.data);
      result = response.data;
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result;
}
export async function createMasterMod(MasterMod) {
  let result;
  await axios
    .post(`${BASE_URL}/create/masterMod`, MasterMod, {
      'Content-Type': 'application/json'
    })
    .then((response) => {
      console.log(response.data);
      result = response.data;
    })
    .catch((e) => {
      notifyError(e.response.data.Message);
    });

  return result;
}

export async function recoverEmail(Email) {
  let result;

  await axios
    .post(`${BASE_URL}/pass/reset/request`, Email, {
      'Content-Type': 'application/json'
    })
    .then((response) => {
      console.log(response.data);
      result = response.data;
    })
    .catch((e) => {
      toast.error('API Error');
      console.log(e);
      console.log('res', e.response);
      notifyError(e.response.data.Message);
    });

  return result;
}
export async function changePassword(payload) {
  let result;

  await axios
    .post(`${BASE_URL}/pass/reset`, payload, {
      'Content-Type': 'application/json'
    })
    .then((response) => {
      console.log(response);
      result = response;
    })
    .catch((e) => {
      notifyError(`ERROR: ${e.response.data.Message}`);
    });

  return result;
}

export async function checkValidLocations(payload) {
  let result;

  await axios
    .post(`${BASE_URL}/locations`, payload, {
      'Content-Type': 'application/json'
    })
    .then((response) => {
      console.log(response);
      result = response;
    })
    .catch((e) => {
      notifyError(`ERROR: ${e.response.data.Message}`);
    });

  return result;
}