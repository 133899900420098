export const LOAD_MODS = 'LOAD_MODS'
export const LOAD_MASTER_MODS = 'LOAD_MASTER_MODS'
export const LOAD_ADMINS = 'LOAD_ADMINS'
export const ADD_MOD = 'ADD_MOD'
export const ADD_MASTER_MOD = 'ADD_MASTER_MOD'
export const ADD_ADMIN = 'ADD_ADMIN'


export function loadMods(Mods) {
  return {
    type: LOAD_MODS,
    payload : { 
      Mods
      
    }
  }
}

export function loadMasterMods(MasterMods) {
    return {
      type: LOAD_MASTER_MODS,
      payload : { 
        MasterMods
        
      }
    }
  }

  export function loadAdmins(Admins) {
    return {
      type: LOAD_ADMINS,
      payload : { 
        Admins
        
      }
    }
  }

  export function AddMod(Mod) {
    return {
      type: ADD_MOD,
      payload : { 
        Mod
        
      }
    }
  }

  export function AddMasterMod(MasterMod) {
    return {
      type: ADD_MASTER_MOD,
      payload : { 
        MasterMod
        
      }
    }
  }
  export function AddAdmin(Admin) {
    return {
      type: ADD_ADMIN,
      payload : { 
        Admin
        
      }
    }
  }