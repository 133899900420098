import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,Button,ListGroup,ListGroupItem,Badge,Modal,ModalBody,ModalHeader,Col
} from 'reactstrap';
import defaultAvatar from "../../../assets/images/placeholder.jpg";
import IosAdd from 'react-ionicons/lib/MdAddCircle';
import {updateMember} from '../../../reducers/Actions/membersActions'
import {updateHouseHold} from '../../../reducers/Actions/houseHoldActions'
import { FadeLoader,BounceLoader } from 'react-spinners';
import { connect } from 'react-redux';
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2';
import {addMemberToHouseHold,removeMemberToHouseHold} from 'utils/API';
import { toast, Zoom } from 'react-toastify';


function containsObject(obj, list) {
if(Array.isArray(list)){
  for (let i = 0; i < list.length; i++) {
      if (list[i].ID === obj.ID) {
          return true;
      }
  }

  
}
return false;
}
function numKeys(obj)
{
    var count = 0;
    for(var prop in obj)
    {
      if(obj[prop] == true)
        count++;
    }
    return count;
}
const  LivePreviewExample = (props) => {

  const [modal3, setModal3] = React.useState(false);
  const [noHouses, setModal4] = React.useState(false);
  const [payed, setModal5] = React.useState(false);

  
  const toggle3 = () => {

    if (modal3)
      selectUser(false)
    
    setModal3(!modal3);
  
  }

  const [user, selectUser] = React.useState(false)
  const [loading, setLoad] = React.useState(false)
 
 

  return (
    <Fragment>

   <SweetAlert
        show={noHouses}
        title="Covered locations"
        type = "warning"
        text="You must have at least 1 valid location for coverage"
        onConfirm={() => setModal4(false)}
      /> 

  <SweetAlert
        show={payed}
        title="Payment in progress"
        type = "warning"
        text="Please wait for your payment to be completed to add this member to a house hold"
        onConfirm={() => setModal5(false)}
      /> 

      {props.Members ? (
        props.Members.length > 0 ? (
          <Card className="card-box mb-5">
          <ListGroup flush>
              {props.Members.map((member, index) => {
                return (
                  <ListGroupItem className="text-center text-md-left p-4 d-block d-md-flex justify-content-between align-items-center">
                  <a href="#/" onClick={e => e.preventDefault()} className="avatar-icon-wrapper mr-0 mr-md-3">
                      <div className="d-block p-0 avatar-icon-wrapper m-0 d-50">
                          
                          <div className="rounded overflow-hidden">
                              <img alt="..." className="img-fluid" src={member.img ? member.img : defaultAvatar} style={{maxHeight : '90px', maxWidth:'auto'}} />
                          </div>
                      </div>
                  </a>
                  <div className="d-flex flex-grow-1 pl-1 flex-column">
                      <div className="d-flex flex-column flex-xl-row justify-content-between">
                          <div>
                              <a href="#/" onClick={e => e.preventDefault()} className="font-size-lg mx-0 my-3 my-xl-0 font-weight-bold btn text-black p-0">{member.First + ' ' + member.Last}</a>
                              <div className="d-block d-md-flex align-items-center my-2">
                                <div>
                                  <FontAwesomeIcon icon={['fas', 'home']} className="mr-2" />
                                  <span className="text-black-50 pr-4">{ Array.isArray(member.HouseHolds) && member.HouseHolds.length ? ` ${member.HouseHolds.length} house holds `: `Not assigned` } </span>
                                  </div>
                                  <div>
                                  <FontAwesomeIcon icon={['fas', 'notes-medical']} className="mr-2" />
                                  <span className="text-black-50 pr-4">{member.Medical ? ` ${numKeys(member.Medical)} Recorded Conditions `: `No Recorded Conditions` } </span>
                                  </div>
                                  <div>
                                {member.Mail ? <><FontAwesomeIcon icon={['fas', 'envelope']} className="mr-2" />
                                  <span className="text-black-50 pr-4">{member.Mail}</span> </>: null}  </div>
                                  <div>
                                  {member.Phone ? <><FontAwesomeIcon icon={['fas', 'phone']} className="mr-2" />
                                  <span className="text-black-50 pr-4">{member.Phone}</span> </>: null} </div>
                              </div>
                          </div>
                          <div className="pb-3 pt-1 py-xl-0">
                              <Button color="primary" size="sm" className="shadow-none mr-2 text-uppercase font-size-xs font-weight-bold" onClick= {()=>{window.location = `/Members/Edit/${member.ID}`}}>
                                  Edit
                                </Button>
                                <Button color="primary" size="sm" className="shadow-none mr-2 text-uppercase font-size-xs font-weight-bold" onClick= { () => {
                                  
                                  if (member.payed && member.payed === "COMPLETE"){
                                  
                                  toggle3(); 
                                  selectUser(member); 

                                  }
                                  else {

                                    setModal5(true)
                                  }
                                  
                                  
                                  }}>
                                  Assign <FontAwesomeIcon icon={['fas', 'home']} className="ml-2" />
                                </Button>

                          </div>
                      </div>
                      <div className="d-block d-xl-flex  align-items-center">
                         {member.Additional ? <p className="text-black-50 mb-0 font-size-sm pr-0 pr-xl-4">
                             {member.Additional}
                         </p>: null} 
                         {Array.isArray(member.HouseHolds) && member.HouseHolds.length ? member.HouseHolds.map((item,indexed)=>{
                           return (

                            <Badge key={indexed} className="m-1 text-primary" color="neutral-primary"> <FontAwesomeIcon icon={['fas', 'home']} className="mr-2" />{item.Name}</Badge>
                           )
                         }) : <Badge className="m-1 text-warning" color="neutral-warning">  {`Not Assigned`    }<FontAwesomeIcon icon={['fa', 'exclamation-circle']} className="ml-2" /> <FontAwesomeIcon icon={['fas', 'home']} className="ml-2" /></Badge> }
                         {member.payed && member.payed === "PENDING" ?
                          

                            <Badge className="m-1 text-danger float-left" color="neutral-danger"> <FontAwesomeIcon icon={['fas', 'credit-card']} className="mr-2" />{`Payment : Pending`}</Badge>
                           
                          : member.payed === "PROCESSING" ? <Badge className="m-1 text-warning float-left" color="neutral-warning">  {`Payment  : Processing` } <FontAwesomeIcon icon={['fas', 'credit-card']} className="ml-2" /></Badge> : 
                          member.payed === "COMPLETE" ? <Badge className="m-1 text-success float-left" color="neutral-success">  {`Payment  : Completed` } <FontAwesomeIcon icon={['fas', 'credit-card']} className="ml-2" /></Badge> : null}
                         
                      </div>
                  </div>
              </ListGroupItem>
                
              )})}
                </ListGroup>
                
            <div className="card-footer py-3 text-center">
              <Button
                color="primary"
                onClick={() => {

                  if(Array.isArray(props.HouseHolds)){

                      if (props.HouseHolds.length > 0)
                          window.location = `${window.location}/Add`;
                      else
                        setModal4(true)

                  }


                  
                }}>
                <span className="btn-wrapper--label">Register new Member</span>
                <span className="btn-wrapper--icon">
                  <IosAdd color="var(--light)" />
                </span>
              </Button>
              





            </div>
          </Card>
        ) : (
          <div className="text-center">
            {' '}
            <p>No Registered Family Members</p>
            <Button
              color="primary"
              className="m-2"
              onClick={() => {
                if(Array.isArray(props.HouseHolds)){

                  if (props.HouseHolds.length > 0)
                      window.location = `${window.location}/Add`;
                  else
                    setModal4(true)

              }
              }}>
              <span className="btn-wrapper--label">Begin Registering</span>
              <span className="btn-wrapper--icon">
                <IosAdd color="var(--light)" />
              </span>
            </Button>
          </div>
        )
      ) : (
        <div
        className="d-flex mx-auto align-items-center justify-content-center text-center"
          style={{ width: '72px', height: '5px' }}>
          <FadeLoader small color={'var(--primary)'} loading={true} />
        </div>
      )}
       <Modal zIndex={2000} centered size="lg" isOpen={modal3} toggle={toggle3}>
                    <ModalHeader toggle={toggle3}>House Hold Assignment for <br></br>{user.First + ' ' + user.Last}
                    
                    </ModalHeader>
                    {loading ? (
              <div
                className="d-flex mx-auto align-items-center justify-content-center"
                style={{ width: '150px', height: '80px' }}>
                <BounceLoader color={'var(--primary)'} loading={true} />
              </div>
            ) : null}
                    <ModalBody>
                      
                    <Col sm="12">             
                     <ListGroup flush >
                     {props.HouseHolds ?   props.HouseHolds.length > 0 ?  
                    
                    props.HouseHolds.map((house, index) => {

                      return (

                        <ListGroupItem className="justify-content-between align-items-center mb-2 " key= {index}>
                                            <div className="d-flex align-items-center">
                                                <div className="avatar-icon-wrapper mr-2">
                                                    <div className="avatar-icon"><FontAwesomeIcon icon={['fas', 'home']} className="ml-2" /></div>
                                                </div>
                                                <div className= "m-2">
                                                    <a href="#/" onClick={e => e.preventDefault()} className="font-weight-bold text-black" title="...">
                                                        {house.Name}
                                                    </a>
                                                    <span className="text-black-50 d-block">{house.Location}</span>
                                                </div>
                                            </div>
                                            <Button size="sm" color="success" className="ml-4" disabled = {containsObject(house,user.HouseHolds)} onClick = {async ()=>{
                                              //add

                                              console.log(containsObject(house,user.HouseHolds))
                                              setLoad(true);

  
                                              const newLinkToHouse = {
                                                UID : props.UID,
                                                MID : user.ID,
                                                HHID : house.ID
                                              }


                                               const linkRecord = await addMemberToHouseHold(newLinkToHouse);
                            
                                               let HouseToAdd = house
                                               let UserUpdate = user
                                               HouseToAdd.LinkingID = linkRecord

                                              if (Array.isArray(UserUpdate.HouseHolds)){


                                                UserUpdate.HouseHolds[ UserUpdate.HouseHolds.length] = HouseToAdd
                                              }
                                              else {
                                                UserUpdate.HouseHolds = []
                                                UserUpdate.HouseHolds[0] = HouseToAdd
                                              }

                                              if (Array.isArray(HouseToAdd.Members)){

                                                HouseToAdd.Members[HouseToAdd.Members.length] = UserUpdate
                                               
                                              }
                                              else {
                                                HouseToAdd.Members = []
                                                HouseToAdd.Members[0] = UserUpdate
                                              }

                                               
                                             
                                              setTimeout(() => {
                                                //update member
                                                if (linkRecord ) {
                                                  props.onMemberAdd(UserUpdate);
                                                  props.onHouseHoldUpdate(HouseToAdd)
                                                }
                            
                                                setLoad(false);
                                               
                          
                                                toast.success('Member assigned to house hold !', {
                                                  containerId: 'B',
                                                  transition: Zoom
                                                });
                                              }, 1000);

                                            }}>Add to</Button>
                                            
                                            <Button size="sm" color="danger" className="ml-4" disabled = {!containsObject(house,user.HouseHolds)} onClick = {async ()=>{
                                              //remove

                                              
                                              setLoad(true);

  
                                              const linkIDIndex = user.HouseHolds.findIndex(x => x.ID === house.ID);
                                              const newLinkToHouse = {
                                                UID : props.UID,
                                                MID : user.ID,
                                                LINKID : user.HouseHolds[linkIDIndex].LinkingID
                                              }


                                               const linkRecord = await removeMemberToHouseHold(newLinkToHouse);
                            
                                               let HouseToRemove = house
                                               let UserUpdate = user
                                               
                                               if (Array.isArray(UserUpdate.HouseHolds)){


                                                UserUpdate.HouseHolds = UserUpdate.HouseHolds.filter(item => item.ID !== HouseToRemove.ID) // remove the house here
                                              }
                                          

                                              if (Array.isArray(HouseToRemove.Members)){

                                                HouseToRemove.Members = HouseToRemove.Members.filter(item => item.ID !== user.ID) //remove member here
                                               
                                              }
                                            

                                               
                                             
                                              setTimeout(() => {
                                                //update member
                                                if (linkRecord ) {
                                                  props.onMemberAdd(UserUpdate);
                                                  props.onHouseHoldUpdate(HouseToRemove)
                                                }
                            
                                                setLoad(false);
                                               
                          
                                                toast.success('Member Removed from House Hold !', {
                                                  containerId: 'B',
                                                  transition: Zoom
                                                });
                                              }, 1000);

                                            }} >Remove </Button>
                                        </ListGroupItem>

                      )
                    }) : 'no House Holds'
                    
                    
                    : <div> <p>No Registered House Holds</p> 
                    <Button color="primary" className="m-2" onClick = {() => window.location = `${window.location}/Add`}>
                                 <span className="btn-wrapper--label">Add a House Hold</span>
                                 <span className="btn-wrapper--icon">
                                     <IosAdd color="var(--light)" />
                                 </span>
                             </Button>
                             </div>}
                      </ListGroup>
                            
                         
                        
                        </Col>


                    </ModalBody>
                  
                </Modal>
    </Fragment>
  );
};
const MapStateToProps = (state) => {
  return {
    FirstName: state.UserReducer.First,
    UID: state.UserReducer.ID,
    Members: state.UserReducer.Members,
    HouseHolds : state.UserReducer.HouseHolds
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onMemberAdd: (Member) => {
      dispatch(updateMember(Member));
    },
    onHouseHoldUpdate : (HouseHold) => {
      dispatch(updateHouseHold(HouseHold)) //change this to edit
    } ,
  };
};

export default connect(MapStateToProps, mapDispatchToProps)(LivePreviewExample);
