import React from 'react';
import { Row, Col } from 'reactstrap';


import EditHouseHold from '../../../example-components/HouseHolds/HouseHoldsEdit'


import {connect} from 'react-redux'
import Button from 'reactstrap/lib/Button';


const Households = (props) => {


    

  //console.log("zobb" ,localStorage.getItem("state"))


  return (
    <>
      <Row>
        <Col xl="5" className="d-flex">
          <Button color = {'danger'} onClick = {()=> {window.location = '/HouseHolds'}}>Cancel Changes</Button>
        </Col>
        <Col xl="7">
         
         
         
        </Col>
        <Col xl="12">

          <EditHouseHold></EditHouseHold>
         
        </Col>
       
      </Row>
    
    </>
  );
}
const MapStateToProps = state => {

  return {
    FirstName : state.UserReducer.First,
    UID : state.UserReducer.ID,
    isAuthenticated : state.UserReducer.isAuthenticated,
    }
  
}



export default connect(MapStateToProps,null)(Households);