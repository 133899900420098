import React, { Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';
import { connect } from 'react-redux';

//left side bar menus
import {
  LeftSidebar, //for users
  PresentationLayout, //do not touch this
  MODLeftSidebar, // for mods
  ADMINLeftSidebar //for admins
} from './layout-blueprints';

/************ FOR USERS ************************/
import Overview from './example-pages/Overview';
import Accounts from './example-pages/Accounts';
import Profile from './example-pages/Profile';
import PageLoginCover from './example-pages/PageLoginCover';
import PageRegisterCover from './example-pages/PageRegisterCover';
import PageRecoverCover from './example-pages/PageRecoverCover';
import PagePasswordChangeCover from './example-pages/PagePasswordChangeCover';
import Homepage from './example-pages/Homepage';
import HousHolds from './example-pages/HouseHold';
import MembersAdd from './example-pages/Members/MembersAdd';
import MembersEdit from './example-pages/Members/MembersEdit';
import HouseHoldsAdd from './example-pages/HouseHold/HouseHoldsAdd';
import HouseHoldsEdit from './example-pages/HouseHold/HouseHoldEdit';
import Members from './example-pages/Members';

//*************** FOR MODS && ADMINS *******************************
import NewEntries from './example-pages/ModeratorPortal/NewEntries';
import UpdatedEntries from './example-pages/ModeratorPortal/UpdatedEntries';

/********************************************************/

//*************** FOR MODS *******************************
import DeactivatedAccounts from './example-pages/ModeratorPortal/DeactivatedAccounts';
import DeletedAccounts from './example-pages/ModeratorPortal/DeletedAccounts';
/* • FOR MASTER MOD && ADMINS • */
import ListMods from './example-pages/ModeratorPortal/MasterMod/ListMods';
/* • FOR MASTER MOD • */
// import NewMod from './example-pages/ModeratorPortal/MasterMod/NewMod';
//*********************************************************

//***************FOR ADMINS***************
import ListUsers from './example-pages/AdminPortal/UserList';
import ListMasterModerators from './example-pages/AdminPortal/ListMasterModerators';
/* • FOR MASTER ADMINS • */
import ListAdmins from './example-pages/AdminPortal/MasterAdmin/AdminList';
//****************************************

import { receiveLogout } from 'reducers/Actions/logoutActions';

//***************for Engineers***************

//import OwnerPortal from './example-pages/OwnerPortal'

//import HouseHoldsAdd from 'example-components/HouseHolds/HouseHoldsAdd';

const Routes = (props) => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
      <>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Please wait while we load the live preview examples
            <span className="font-size-lg d-block text-dark">
              This live preview instance can be slower than a real production
              build!
            </span>
          </div>
        </div>
      </>
    );
  };

  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading />}>
        <Switch>
          {props.isAuthenticated ? (
            props.role === 'USER' ? (
              //Users routes//---------------------------------------------------------------------------------
              <LeftSidebar>
                {/* <Redirect from = "/" exact to="/Overview" /> */}
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route exact path="/Login">
                      <Redirect to="/Overview" />
                    </Route>
                    <Route exact path="/Register">
                      <Redirect to="/Overview" />
                    </Route>
                    <Route exact path="/">
                      <Redirect to="/Overview" />
                    </Route>
                    <Route exact path="/Overview" component={Overview} />
                    <Route exact path="/Accounts" component={Accounts} />
                    <Route exact path="/Profile" component={Profile} />
                    {/* <Route exact path="/Settings" component={Settings} /> */}
                    <Route
                      exact
                      path="/HouseHolds/Add"
                      component={HouseHoldsAdd}
                    />
                    <Route
                      exact
                      path="/HouseHolds/Edit/:id"
                      component={HouseHoldsEdit}
                    />{' '}
                    {/** window.location = `${window.location}/Edit/${<id_number>}` */}
                    <Route
                      exact
                      path="/Members/Edit/:id"
                      component={MembersEdit}
                    />{' '}
                    {/** window.location = `${window.location}/Edit/${<id_number>}` */}
                    <Route exact path="/Members" component={Members} />
                    <Route exact path="/HouseHolds" component={HousHolds} />
                    <Route exact path="/Members/Add" component={MembersAdd} />
                  </motion.div>
                </Switch>
              </LeftSidebar>
            ) : props.role === 'MASTER MOD' || props.role === 'MOD' ? (
              <MODLeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route exact path="/Login">
                      <Redirect to="/NewEntries" />
                    </Route>

                    <Route exact path="/">
                      <Redirect to="/NewEntries " />
                    </Route>

                    {/*Routes for master mod go in here*/}
                    {props.role === 'MASTER MOD' ? (
                      <>
                        <Route exact path="/ListMods" component={ListMods} />
                      </>
                    ) : null}

                    <Route exact path="/NewEntries" component={NewEntries} />
                    <Route
                      exact
                      path="/UpdatedEntries"
                      component={UpdatedEntries}
                    />
                    <Route
                      exact
                      path="/DeactivatedAccounts"
                      component={DeactivatedAccounts}
                    />
                    <Route
                      exact
                      path="/DeletedAccounts"
                      component={DeletedAccounts}
                    />
                  </motion.div>
                </Switch>
              </MODLeftSidebar>
            ) : //----------------------------------------------------------------------------------------------------

            //MIMI's portal//----------------------------------------------------------------------------------------------------

            props.role === 'MASTER ADMIN' || props.role === 'ADMIN' ? (
              <ADMINLeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route exact path="/Login">
                      <Redirect to="/NewEntries" />
                    </Route>

                    <Route exact path="/">
                      <Redirect to="/NewEntries " />
                    </Route>

                    {props.role === 'MASTER ADMIN' ? (
                      <>
                        <Route
                          exact
                          path="/ListAdmins"
                          component={ListAdmins}
                        />
                        {/**<Route exact path="/" component={} />//this is for the add admin route*/}
                      </>
                    ) : null}

                    <Route exact path="/NewEntries" component={NewEntries} />
                    <Route
                      exact
                      path="/UpdatedEntries"
                      component={UpdatedEntries}
                    />
                    <Route exact path="/UserList" component={ListUsers} />
                    <Route exact path="/ModeratorList" component={ListMods} />
                    <Route
                      exact
                      path="/MasterModeratorList"
                      component={ListMasterModerators}
                    />
                    {/**<Route exact path="/" component={} />//this is for the add Master Mod route*/}
                  </motion.div>
                </Switch>
              </ADMINLeftSidebar>
            ) : //END OF MIMI's portal//-------------------------------------------------------------------------------------------------------------

            //This is for the moderator routes//-------------------------------------------------------------------------------------------------
            props.role === 'ENGINEER' ? (
              <MODLeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route exact path="/Login">
                      <Redirect to="/FFRNewEntry" />
                    </Route>

                    <Route exact path="/">
                      <Redirect to="/FFRNewEntry" />
                    </Route>
                    <Route exact path="/FFRNewEntry" component={NewEntries} />
                    <Route exact path="/FFRUpdate" component={UpdatedEntries} />
                    <Route
                      exact
                      path="/FFRDeactivated"
                      component={DeactivatedAccounts}
                    />
                    <Route
                      exact
                      path="/FFRDelete"
                      component={DeletedAccounts}
                    />
                  </motion.div>
                </Switch>
              </MODLeftSidebar>
            ) : (
              props.LogOut()
            )
          ) : (
            <>
              <Switch>
                <Route
                  path={[
                    '/Homepage',
                    '/Login',
                    '/Register',
                    '/Recover',
                    '/Change'
                  ]}>
                  <PresentationLayout>
                    <Switch location={location} key={location.pathname}>
                      <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}>
                        <Route path="/Homepage" component={Homepage} />
                        <Route path="/Login" component={PageLoginCover} />
                        <Route path="/Register" component={PageRegisterCover} />
                        <Route path="/Recover" component={PageRecoverCover} />
                        <Route
                          path="/Change"
                          component={PagePasswordChangeCover}
                        />
                      </motion.div>
                    </Switch>
                  </PresentationLayout>
                </Route>
                <Redirect to="/Homepage" />
              </Switch>
            </>
          )}
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

const MapStateToProps = (state) => {
  return {
    isAuthenticated: state.UserReducer.isAuthenticated,
    role: state.UserReducer.Role
  };
};
const mapDispatchToProps = (dispatch) => ({
  LogOut: () => {
    dispatch(receiveLogout());
  }
});

export default connect(MapStateToProps, mapDispatchToProps)(Routes);
