import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Badge, UncontrolledTooltip, Button } from 'reactstrap';


import EditData from './EditData'
import avatar7 from '../../../assets/images/placeholder.jpg';
import {connect} from 'react-redux'
const  LivePreviewExample = (props) => {



  return (
    <>
      <Card className="card-box mb-5">
        <div className="card-tr-actions">
         
        </div>
        <div className="text-center pt-4">
        <div className="avatar-icon-wrapper avatar-initials avatar-icon-xl">
                  <div className="avatar-icon text-white bg-primary">
                  {`${props.First[0]}${props.Last[0]}`}
        </div>
        </div>
          <div>
            <Badge
              color="neutral-success"
              className="my-2 text-success font-size-md px-4 py-1 h-auto">
              Online
            </Badge>
          </div>
          <h3 className="font-weight-bold mt-3">{props.First + ' ' + props.Last}</h3>
          {/* <p className="mb-0 text-black-50">
            Senior Frontend Developer at <b>Google Inc.</b>
          </p> */}
          
          
         
         
          <EditData></EditData>
        
          
        </div>
      </Card>
    </>
  );
}

const MapStateToProps = (state) => {
  return {
    First: state.UserReducer.First,
    UID: state.UserReducer.ID,
    Last: state.UserReducer.Last,
    HouseHolds : state.UserReducer.HouseHolds
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
   
  };
};

export default connect(MapStateToProps, mapDispatchToProps)(LivePreviewExample);