import React, {Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	PayPalScriptProvider,
	PayPalButtons,
	usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { Container, Row, Col, Badge,Card, Button} from 'reactstrap';


 function LivePreviewExample(props) {

    const [active, setActive] = React.useState(1)
    return (
        <Fragment>

            <div className="bg-light py-3 py-xl-5">
                <div className="container py-3 py-xl-5">
                    <div className="d-block d-xl-flex mb-5 justify-content-between">
                        <div>
                            <h1 className="display-3 text-dark mb-2 font-weight-bold">
                                Plans & pricing
                            </h1>
                            <p className="font-size-lg text-black">
                            {active === 2 ? `Cost is $40.00 for the first member and $10.00 for each additional.` : `Cost is $4.00 for the first member and $1.00 for each additional.`}
                            <br/>
                            <hr/>
                            {props.Members  && Array.isArray(props.Members) ? <small>You currently have <strong>{props.Members.length}</strong> members registered</small> : null }
                            </p>
                        </div>
                        <div className="d-flex align-items-center">
                            <div role="group" className="btn-group-lg nav btn-group">
                                <Button  onClick={() =>  setActive(1)} active = {active  === 1 ? true : false} className={`btn-pill ${active  === 2 ? `text-dark` : false}`} outline color="primary">Monthly</Button>
                                <Button  onClick={() =>  setActive(2)} active = {active  === 2 ? true : false} className={`btn-pill ${active  === 1 ? `text-dark` : false}`} outline color="primary">Yearly</Button>
                            </div>
                        </div>
                    </div>
                    <Row className="d-flex align-items-center">
                        <Col xl="12">

                            {active  === 1 ? 
                            <Card className="mb-5 card-box-hover-rise card-box-hover" style={{border : props.plan  === 1 ? "3px solid purple" : null}}>
                                <div className="card-body px-4 pb-4 pt-3 text-center">
                                    <h3 className="display-4 my-3 font-weight-bold text-dark">
                                        Monthly
                                    </h3>
                                    <span className="display-2 font-weight-bold">
                                        <small className="font-size-lg">$</small>
                                        {props.Members  && Array.isArray(props.Members) && props.Members.length > 0 ?  `1` : `4`}
                                    </span>
                                    <p className="text-black-50 mb-0">
                                        monthly fee, for a single user
                                        <br/>
                                        {props.plan === 1 ? <strong>currently selected</strong> : null}
                                    </p>
                                    <Button  onClick={()=> {
                                         if(props.Members.length > 0){

                                            props.setPrice(1)
                                            props.setPlan(1)
                                        }
                                        else {
                                            props.setPrice(4)
                                            props.setPlan(1)
                                        }
                                     }} size="lg" color="success" block className="font-weight-bold text-uppercase my-4">
                                                Select this plan
                                            </Button>
                                </div>
                                    </Card> 
                                        : 
                                    <Card className="mb-5 card-box-hover-rise card-box-hover" style={{border : props.plan  === 2 ? "3px solid purple" : null}}>
                                            <div className="card-body px-4 pb-4 pt-3 text-center">
                                                <h3 className="display-4 my-3 font-weight-bold text-dark">
                                                    Yearly
                                                </h3>
                                            <span className="display-2 font-weight-bold">
                                                    <small className="font-size-lg">$</small>
                                                    {props.Members  && Array.isArray(props.Members) && props.Members.length > 0 ?  `10` : `40`}
                                            </span>
                                                <p className="text-black-50 mb-0">
                                                    Yearly fee, for a single user
                                                    <br/>
                                        {props.plan === 2 ? <strong>currently selected</strong> : null}
                                                </p>
                                                <Button  onClick={()=> { 
                                                    if(props.Members.length > 0){

                                                        props.setPrice(10)
                                                        props.setPlan(2)
                                                    }
                                                    else {
                                                        props.setPrice(40)
                                                        props.setPlan(2)
                                                    }
                                                }} size="lg" color="warning" block className="font-weight-bold text-uppercase my-4">
                                                Select this plan
                                            </Button>
                                            </div>
                                    </Card>
                                            } 
                        </Col>
                       
                    </Row>
                </div>
            </div>

        </Fragment>
    );
}

const MapStateToProps = state => {

    return {
      UID : state.UserReducer.ID,
      Members : state.UserReducer.Members
      }
    
  }
export default connect(MapStateToProps, null)(withRouter(LivePreviewExample));
