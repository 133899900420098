import React, {Fragment} from 'react'
import {Pagination,PaginationItem,PaginationLink,Card,Button,Table,Badge,UncontrolledTooltip} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ListUsers} from '../../utils/API'
import { PageTitle } from '../../layout-components';
import {connect} from 'react-redux'
import moment from 'moment';

const LivePreviewExample=(props)=> {


  const [Users,setUser] = React.useState(false);

  
  React.useEffect(() => {

    async function fetchData() {
     
      try {
          setUser(false);
          const users = await ListUsers()
          setUser(users)
          console.log(users);
      } catch (error) {
        console.error(error)
      }

    }

    fetchData()


  }, [] ) /** passing empty braces is necessary */



  return (
    <>
    <PageTitle
        titleHeading={`Welcome back ${props.FirstName}`} /**${props.FirstName}*/
        titleDescription="This page displays all users.">

      </PageTitle>
    <Fragment>

      <Card className="card-box mb-5">
        <div className="card-header py-3">
          <div className="card-header--title font-size-lg">
            List of Users
          </div>
        </div>
        <div className="divider" />
        <div className="table-responsive-md">
          <Table hover className="text-nowrap mb-0">
            <thead>
              <tr>
                <th className="bg-white text-center">ID</th>
                <th className="bg-white text-center">Name</th>
                <th className="bg-white text-center">Address</th>
                <th className="bg-white text-center">Members</th>
                <th className="bg-white text-center">User Since</th>
                <th className="bg-white text-center">Last Update</th>
                {/* <th className="bg-white text-center">Status</th> */}
              </tr>
            </thead>
            <tbody>

            
            {Users ?   Users.length > 0 ?  
    
            Users.map((entry, index) => {

              return(

              <tr>
              <td className="font-weight-bold text-center">
                {entry.ID}
              </td>
              <td className="text-center">
                {`${entry.FirstName}  ${entry.LastName}`}
              </td>
              <td className="text-center">{entry.LocationP.Street + " " + entry.LocationP.City + ", " + entry.LocationP.State + " " + entry.LocationP.Zip }</td>
              <td className="text-center">
                {Array.isArray(entry.Members) ? entry.Members.length : "None"}
              </td>
              <td className="text-center">
                {moment(entry.CreateDate).format('MMM DD  YYYY')}
              </td>
              <td className="text-center">
                {moment(entry.UpdateDate).format('MMM DD  YYYY')}
              </td>
              {/* <td className="text-center">
                <Badge color="neutral-dark" className="text-success">Active</Badge>
              </td> */}
            </tr>

              ) 

              }): null : null 
          
          } 

            </tbody>
          </Table>
        </div>
      </Card>

    </Fragment>
    </>
        );
}
const MapStateToProps = state => {

  return {
    FirstName : state.UserReducer.First,
    }
  
}
export default connect(MapStateToProps)(LivePreviewExample);