import React, { useState } from 'react';
// import { GoogleMap, Marker } from "react-google-maps"
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import {connect} from 'react-redux'


const MapComponent = compose(
  withProps({
    /**
     * Note: create and replace your own key in the Google console.
     * https://console.developers.google.com/apis/dashboard
     * The key "AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q" can be ONLY used in this sandbox (no forked).
     */
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyASasVewAMvMAq-sU7DaMskF3A-S52nQng&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={5} defaultCenter={{ lat: 42.331429, lng: -83.045753 }}>
    {/* {
      // <Marker color="#cdcdcd" position={{ lat: 42.331429, lng: -83.045753 }} />
    } */}
    {props.coordinates ? props.coordinates.map((host, i) => {
        if (host.Latitude && host.Longitude) {
          console.log("TEST", host.Latitude);
         return(<Marker
            key={i}
            position={{
              lat: host.Latitude,
              lng: host.Longitude
            }}
            title={host.name}
            pinColor={"#ffd1dc"}
          />)
        }
      }): null}
         {/* <Circle
                  defaultCenter={{
                    lat: parseFloat(place.latitude),
                    lng: parseFloat(place.longitude)
                  }}
                  radius={place.circle.radius}
                  options={place.circle.options}
                /> */}
  </GoogleMap>
));

const MapStateToProps = state => {

  return {
    FirstName : state.UserReducer.First,
   
    }
  
}



export default connect(MapStateToProps,null)(MapComponent);
