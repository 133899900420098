export const ADD_HOUSEHOLD = 'ADD_HOUSEHOLD'
export const LOAD_HOUSEHOLDS = 'LOAD_HOUSEHOLDS'
export const DELETE_HOUSEHOLD = 'DELETE_HOUSEHOLD'
export const UPDATE_HOUSEHOLD = 'UPDATE_HOUSEHOLD'


export function loadHouseHolds(HouseHolds) {
  return {
    type: LOAD_HOUSEHOLDS,
    payload : { 
      HouseHolds
      
    }
  }
}

export function addHouseHolds(HouseHold) {
  return {
    type: ADD_HOUSEHOLD,
    payload : {
      HouseHold
    }
  }
}

export function deleteHouseHolds(HouseHoldID) {
  return {
    type: DELETE_HOUSEHOLD,
    payload : {
      HouseHoldID
    }
  }
}

export function updateHouseHold(HouseHold) {
    return {
      type: UPDATE_HOUSEHOLD,
      payload : {
        HouseHold
      }
    }
  }