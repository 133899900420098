export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const UPDATE_USER = 'UPDATE_USER'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export function requestLogin() {
  return {
    type: LOGIN_REQUEST,
    
    payload : { 
      isFetching: false,
      
    }
  }
}

export function receiveLogin(user) {
  return {
    type: LOGIN_SUCCESS,
    payload : {
    isFetching: false,
    isAuthenticated: true,
     user
    }
  }
}

export function updateUserInfo(user) {
  return {
    type: UPDATE_USER,
    payload : {
     user
    }
  }
}

export function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    payload : {
      isFetching: false,
      isAuthenticated: false,
      message
    }
  }
}