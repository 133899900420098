import React, {Fragment} from 'react'
import {Card,Button,Table,Modal, ModalBody, ModalFooter,ModalHeader,DropdownMenu,DropdownToggle,UncontrolledDropdown,Badge,CardBody} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {loadMasterMods,AddMasterMod} from 'reducers/Actions/roleActions'
import {ListMasterMods,verifyEmailNotInUse,createMasterMod} from 'utils/API'
import Validator from 'utils/Validator'
import { toast,Zoom } from 'react-toastify'
import {connect} from 'react-redux';
import AddMasterModC from './AddMasterMod'
import { PageTitle } from 'layout-components';
import moment from 'moment';

 const LivePreviewExample = (props) => {

  const [modal, setModal] = React.useState(false);
  const toggleModal = () => setModal(!modal);

  React.useEffect(() => {

    async function fetchData() {
     
      try {

        props.onMasterModsFetch(false)

        const mmods = await ListMasterMods()
  
        props.onMasterModsFetch(mmods)

        console.log(mmods)

      } catch (error) {
        console.error(error)
      }

    }

    fetchData()


  }, [] ) /** passing empty braces is necessary */

  const [ first, setFirstName] = React.useState('')
  const [ last, setLastName] = React.useState('')
  const [ email, setEmail] = React.useState('')
  const [ phone, setPhone] = React.useState('')
  const [emailIsAvail, setEmailAvail] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const[primaryLocation, setLocation] = React.useState(null)
  return (
    <>
    <PageTitle
        titleHeading={`Welcome back ${props.FirstName}`} 
        titleDescription="This page displays first responder Master Moderators.">

      </PageTitle>

      <Modal zIndex={2000} centered size="lg" isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Create a new MASTER Moderator</ModalHeader>
                    <ModalBody>
                        <AddMasterModC
                        first = {first}
                        setFirstName = {setFirstName}
                        last = {last}
                        setLastName = {setLastName}
                        email = {email}
                        setEmail = {setEmail}
                        phone = {phone}
                        setPhone = {setPhone}
                        emailIsAvail = {emailIsAvail}
                        setEmailAvail = {setEmailAvail}
                        loading = {loading}
                        setLoading = {setLoading}
                        primaryLocation={primaryLocation}
                        setLocation = {setLocation}
                        ></AddMasterModC>
                    </ModalBody>
                    <ModalFooter className = "p-3">
                    <Button color="danger" className="my-auto" onClick={toggleModal}>Discard and close</Button>
                        <Button color="primary" className="my-auto" onClick={async ()=> {

                                      try {
                                        
                                      
                                      setLoading(true)

                                      if (!first || !last ||  !email || !phone){

                                        toast.error('Please fill out all fields before creating your account', {
                                          position: "top-center",
                                          autoClose: 7000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                          });

                                          setLoading(false)
                                          return
                                      }

                                      if (!primaryLocation || !primaryLocation.City ||  !primaryLocation.State || !primaryLocation.Zip){

                                        toast.error('Location Information is Required', {
                                          position: "top-center",
                                          autoClose: 7000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                          });

                                          setLoading(false)
                                          return
                                      }



                                      if (!Validator.validateEmail(email)) {

                                        toast.error('Please enter a valid email', {
                                          position: "top-center",
                                          autoClose: 7000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                          });

                                          setLoading(false)
                                          return

                                      }

                                      if (!Validator.validatePhoneNumber(phone)) {

                                        toast.error('Please enter a valid Phone number', {
                                          position: "top-center",
                                          autoClose: 7000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                          });

                                          setLoading(false)
                                          return

                                      }


                                      const inU = await verifyEmailNotInUse(email)

                                      if (!inU.isAvail){

                                        setEmailAvail(false)

                                        toast.error('Email you are trying to sign up with is already associated to an existing account', {
                                          position: "top-center",
                                          autoClose: 10000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                          });

                                        setLoading(false)
                                        return
                                      }

                                      const param = {
                                        FirstName : first,
                                        LastName : last,
                                        Email : email,
                                        Location : primaryLocation,
                                        Cell : phone
                                      }

                                      const upload = await createMasterMod(param)

                                      setTimeout(() => {
                                
                                        setLoading(false)
                                       
                                       }, 1000);
          
                                       if (upload){
          
                                          setEmail('')
                                          setFirstName('')
                                          setLastName('')
                                          setLocation(null)
                                          setPhone('')
                                         
                                          //use props to add to master mods

                                          props.onMasterAddMod(upload.Account)

                                          setModal(false)

                                          toast.success(upload.Message, {
                                            containerId: 'B',
                                            transition: Zoom
                                          })
                  
                                       }
                                     
                                      console.log(upload)
                                    } catch (error) {
                                        setLoading(false)
                                        toast.error(`Error Creating new Master Moderator Account ${error}`, {
                                          containerId: 'B',
                                          transition: Zoom
                                        })
                                    }


                        }}>Save and upload</Button>
                    </ModalFooter>
                </Modal>

                <Fragment>

<Card className="card-box mb-5">
    <div className="card-header pr-2">
        <div className="card-header--title" >
            List of Master Moderators
</div>
        <div className="card-header--actions">
        <Button   color="primary"onClick = {() => toggleModal()} >
          <span className= "m-2">Create new  Master Moderator</span>
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon icon={['fas', 'plus-circle']} className="text-white" />
            </span>
        </Button>

        </div>
    </div>
    <CardBody>
        <div className="table-responsive-md">
            <Table hover borderless className="text-nowrap mb-0">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th className="text-left">Name</th>
                        <th className="text-center">Email</th>
                        <th className="text-center">Phone</th>
                        <th className="text-center">Created on</th>
                        <th className="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                {props.MasterModerators ?   props.MasterModerators.length > 0 ?  
    
    props.MasterModerators.map((entry, index) => {
      return (
        <>

            <tr key= {index}>
              <td>
               <b style={{fontWeight:"bold"}}> {entry.ID} </b>
              </td>
              <td>
              <div className="d-flex align-items-center">
                
              <div className="avatar-icon-wrapper avatar-initials">
                  <div className="avatar-icon text-white bg-primary avatar-icon-xs">
                  {`${entry.FirstName[0]} ${entry.LastName[0]}`}
                  </div>
              </div>

                <div>
                  <a href="#/" onClick={e => e.preventDefault()} className="font-weight-bold text-black" title="...">
                  {`${entry.FirstName} ${entry.LastName}`} 
                  </a>
                    <span className="text-black-50 d-block"><Badge color="neutral-warning" className="text-warning px-4">{`${entry.LocationP.City}, ${entry.LocationP.Zip}`}</Badge></span>
                     </div>
                    </div>
                     </td>
                      <td className="text-center">
                        <span>{entry.Email}</span>
                      </td>
                        <td  className="text-center">
                       <span> {entry.Cell}</span>
                      </td>
                      <td  className="text-center">
                       <span> {moment(entry.CreateDate).format('ddd MMM DD  YYYY')}</span>
                      </td>
                      <td className="text-center">
                                <UncontrolledDropdown>
                                    <DropdownToggle color="primary" size="lg" className="px-2 py-0 no-caret">
                                        <FontAwesomeIcon icon={['fas', 'ellipsis-h']} className="font-size-lg" />
                                    </DropdownToggle>
                                    <DropdownMenu right className="dropdown-menu overflow-hidden p-3">
                                        <Button size="sm" color="primary">Restrict</Button>
                                        <br></br>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </td>
                    </tr>

        
        </>)
    } ): <div className="mx-auto my-auto"> <p> No Master Moderators to show here</p> </div> : <div className="mx-auto my-auto"> <p>Loading...</p> </div> } 
                    
                  
                    
                </tbody>
            </Table>
        </div>
    </CardBody>
    <div className="card-footer d-flex justify-content-between">
        
    </div>
</Card>
</Fragment>
    </>
        );
}

const MapStateToProps = state => {

  return {
   
    Location : state.UserReducer.Location,
    Token : state.UserReducer.Token,
    MasterModerators : state.UserReducer.MasterModerators,
    FirstName : state.UserReducer.First,
  } 

}

const mapDispatchToProps = (dispatch) => {
  return {
    onMasterModsFetch: (Mods) => {
      dispatch(loadMasterMods(Mods))
    } ,
    onMasterAddMod : (Mod) => {
      dispatch(AddMasterMod(Mod))
    }


  }};



export default connect(MapStateToProps,mapDispatchToProps)(LivePreviewExample);
