import React from 'react';

import PageLoginCover1 from '../../example-components/PageLoginCover/PageLoginCover1';
export default function PageLoginCover() {
  return (
    <>
      <PageLoginCover1 />
    </>
  );
}
