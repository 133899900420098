import React from 'react';

import PagePasswordChangeCover1 from '../../example-components/PagePasswordChangeCover/PagePasswordChangeCover1';
export default function PagePasswordChangeCover() {
  return (
    <>
      <PagePasswordChangeCover1 />
    </>
  );
}
