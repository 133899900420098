import React, {Fragment} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  Button,UncontrolledTooltip,Table,CardBody,Badge
} from 'reactstrap';
import defaultAvatar from "../../../assets/images/placeholder.jpg";
import moment from 'moment';
import {

  FadeLoader,

} from "react-spinners";
import OverviewMap from 'example-components/Overview/OverviewMap';
import {connect} from 'react-redux'
import IosAdd from 'react-ionicons/lib/MdAddCircle';

const  LivePreviewExample = (props) => {

    const [coordinates,setCoordinates] = React.useState([])

    React.useEffect(()=> {
    let hh = []
    if(props.HouseHolds && Array.isArray(props.HouseHolds)){

        for(let i =0 ; i< props.HouseHolds.length ; i++){
  
          hh[i] = {Name : props.HouseHolds[i].Name , Latitude : props.HouseHolds[i].Geo.Lat , Longitude : props.HouseHolds[i].Geo.Lng}
  
        }
  
        setCoordinates(hh)
      }
    },[])


    return (
<>
{props.HouseHolds ?   props.HouseHolds.length > 0  ? <OverviewMap
          coordinates = {coordinates}
           containerElement={<div style={{ height: `100px` }} />}
           mapElement={<div style={{ height: `80%` }} />} /> : null :null }


        <Fragment>
        {props.HouseHolds ?   props.HouseHolds.length > 0 ?  
        <Card className="card-box mt-3">
            <div className="card-header pr-2">
                <div className="card-header--title" >
                    List of your Active Locations
                    <br></br>
                    {props.isPrime !== undefined ? props.isPrime ? null : <div><b className="text-danger" style={{fontWeight:"bold"}}>No Primary Residence detected <FontAwesomeIcon
                          icon={['fas', 'exclamation-triangle']}
                          color= "orange"
                          
                          title="hb"
                          
                        /></b>  </div>: null}
            </div>
            
                <div className="card-header--actions">
                <Button color="primary" className="m-2" onClick = {() => window.location = `${window.location}/Add`}>
                                <span className="btn-wrapper--label">Add a House Hold</span>
                                <span className="btn-wrapper--icon">
                                    <IosAdd color="var(--light)" />
                                </span>
                            </Button>
      
                </div>
            </div>
            <CardBody>
                <div className="table-responsive-md">
                    <Table hover borderless className="text-nowrap mb-0">
                        <thead>
                            <tr>
                                
                                <th className="text-left">Name</th>
                                <th className="text-center">Address</th>
                                <th className="text-center">Members</th>
                                <th className="text-center">Create Date</th>
                                <th className="text-center">Edit</th>
                            </tr>
                        </thead>
                        <tbody className="mx-auto">
                        {props.HouseHolds ?   props.HouseHolds.length > 0 ?  
                            props.HouseHolds.map((entry, index) => {
              return (
                <>

                    <tr key= {index}>
                      <td>
                       <b style={{fontWeight:"bold", textTransform: 'capitalize'}}> {entry.Name} </b>
                       {entry.hasOwnProperty("isPrime") && entry.isPrime ?
                       <>
                      <Badge className="btn-pill px-4 py-1 ml-2 h-auto text-primary border-1 border-info"  id="TooltipExampleBasicTop"
                      color="neutral-primary" ><FontAwesomeIcon icon={['fas', 'star']} className="font-size-sm" /></Badge>
                      <UncontrolledTooltip placement="top" container="body"  popperClassName="tooltip-dark" target="TooltipExampleBasicTop">
                       <b> This is your registered Primary Location </b>
                    </UncontrolledTooltip>
                    </>
              : null}
                      </td>
                      
                              <td className="text-center">
                                <span style={{ textTransform: 'capitalize'}}>{entry.Location}</span>
                              </td>
                                <td  className="text-center">
                                
                                <div className="avatar-wrapper-overlap d-flex justify-content-center"> 
                                    {entry.Members && Array.isArray(entry.Members) && entry.Members.length > 0 ? entry.Members.map((member, index) => {
                                return (
                                <div className="avatar-icon-wrapper avatar-icon-sm" key={index}>
                                    <div className="avatar-icon"><img alt="..." src={member.img ? member.img : defaultAvatar} /></div>
                                </div>)
                                        
                                    }) : 
                                    <Badge className="px-3 py-1 ml-2 h-auto text-warning border-1 border-warning" 
                                            color="neutral-warning" >None<FontAwesomeIcon
                                            icon={['fas', 'exclamation-triangle']}
                                            color= "warning"
                                            className="ml-2"
                                          /></Badge>
                                    }
                                </div>
                              </td>
                              <td  className="text-center">
                               <span style={{ textTransform: 'capitalize'}}> {moment(entry.CreateDate).format('ddd MMM DD  YYYY')}</span>
                              </td>
                              <td className="text-center">
                        <Button size="sm" color="link" id="btnViewDetailsTooltip" onClick= {() => {window.location = `${window.location}/Edit/${entry.ID}`}}  >
                            <FontAwesomeIcon icon={['fas', 'edit']} className="font-size-lg" />
                        </Button>
                        <UncontrolledTooltip target="btnViewDetailsTooltip">
                            Edit House Hold
                        </UncontrolledTooltip>
                    </td>
                            </tr>

                
                </>)
                
            } ): 
            <div className="text-center"> <p>No Registered House Holds</p> 
            
                     </div>
                     : <div className="text-center"> <p>Loading</p> 
            
                     </div>} 
                            
                          
                            
                        </tbody>
                    </Table>
                </div>
            </CardBody>
            <div className="card-footer d-flex justify-content-between">
                
            </div>
        </Card>
        :
        <div className="text-center"> <p>No Registered House Holds</p> 
            <Button color="primary" className="m-2" onClick = {() => window.location = `${window.location}/Add`}>
                         <span className="btn-wrapper--label">Add a House Hold</span>
                         <span className="btn-wrapper--icon">
                             <IosAdd color="var(--light)" />
                         </span>
                     </Button>
                     </div>
        :<div className="d-flex mx-auto align-items-center justify-content-center text-center" style={{width: '72px', height: '5px'}}>
        <FadeLoader small color={'var(--primary)'} loading={true}/>
    </div>}
        </Fragment>
        </>

  );
}
const MapStateToProps = state => {

  return {
    FirstName : state.UserReducer.First,
    HouseHolds : state.UserReducer.HouseHolds,
    isPrime : state.UserReducer.isPrime
    }
  
}



export default connect(MapStateToProps,null)(LivePreviewExample);