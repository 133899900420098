import React from 'react';
import CellAuth from './CellAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';

import { BounceLoader } from 'react-spinners';
import { connect } from 'react-redux';
import illustration1 from '../../../assets/images/911Ready_logo.png';
import hero4 from '../../../assets/images/hero-bg/hero-2.jpg';
import { NavLink } from 'react-router-dom';
import { verifyEmailNotInUse, RegisterThenLogin } from 'utils/API';
import Validator from 'utils/Validator';
import { stateLabelValues } from 'utils/states';
import { toast } from 'react-toastify';
import {
  requestLogin,
  receiveLogin
} from '../../../reducers/Actions/loginActions';
const LivePreviewExample = (props) => {
  const [first, setFirstName] = React.useState('');
  const [last, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPassConf] = React.useState('');
  const [emailIsAvail, setEmailAvail] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [primaryResidence, setResidence] = React.useState(null);

  console.log(phone);
  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <Row className="min-vh-100 no-gutters">
                    <Col lg="7" xl="6" className="d-flex align-items-center">
                      <Col md="11" lg="11" xl="11" className="mx-auto">
                        <div className="py-4">
                          <div className="text-center">
                            <h3 className="display-4 mb-2 font-weight-bold">
                              Create account
                            </h3>
                          </div>
                          {loading ? (
                            <div className="text-center py-4 rounded bg-secondary my-4">
                              <div
                                className="d-flex mx-auto align-items-center justify-content-center"
                                style={{ width: '150px', height: '80px' }}>
                                <BounceLoader
                                  color={'var(--primary)'}
                                  loading={true}
                                />
                              </div>
                            </div>
                          ) : null}
                          <FormGroup>
                            <label className="font-weight-bold">
                              First name
                            </label>
                            <Input
                              placeholder="Enter your first name"
                              type="text"
                              value={first}
                              onChange={(e) => {
                                setFirstName(e.target.value);
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label className="font-weight-bold">
                              Last name
                            </label>
                            <Input
                              placeholder="Enter your last name"
                              type="text"
                              value={last}
                              onChange={(e) => {
                                setLastName(e.target.value);
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label className="font-weight-bold">
                              Email address
                            </label>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>@</InputGroupText>
                              </InputGroupAddon>

                              <Input
                                placeholder="Email"
                                type="mail"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                  setEmailAvail(true);
                                }}
                                invalid={
                                  !Validator.validateEmail(email) ||
                                  !emailIsAvail
                                }
                              />
                              {!Validator.validateEmail(email) ? (
                                <FormFeedback>Invalid email</FormFeedback>
                              ) : null}
                              {!emailIsAvail ? (
                                <FormFeedback>
                                  Email is already tied to an existing account
                                </FormFeedback>
                              ) : null}
                            </InputGroup>
                          </FormGroup>
                          <FormGroup>
                            <label className="font-weight-bold">
                              Mobile Phone (optional)
                            </label>
                            <CellAuth setPhone={setPhone}></CellAuth>
                          </FormGroup>
                          <FormGroup>
                            <div className="d-flex justify-content-between mg-b-5">
                              <label className="font-weight-bold">
                                Password
                              </label>
                            </div>
                            <Input
                              placeholder="Enter your password"
                              type="password"
                              invalid={
                                !Validator.validatePasswordRules(password)
                                  .isValid
                              }
                              value={password}
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                            />
                            {Validator.validatePasswordRules(password).messages
                              ? Validator.validatePasswordRules(
                                  password
                                ).messages.map((mes, ind) => {
                                  return <FormFeedback>{mes}</FormFeedback>;
                                })
                              : null}
                          </FormGroup>

                          <FormGroup>
                            <div className="d-flex justify-content-between mg-b-5">
                              <label className="font-weight-bold">
                                Confirm Password
                              </label>
                            </div>
                            <Input
                              placeholder="Confirm your password"
                              type="password"
                              invalid={
                                !Validator.passwordsMatch(
                                  password,
                                  passwordConfirmation
                                )
                              }
                              value={passwordConfirmation}
                              onChange={(e) => {
                                setPassConf(e.target.value);
                              }}
                            />
                            <FormFeedback>Passwords do not match</FormFeedback>
                          </FormGroup>
                          <hr></hr>
                          <p className="font-size-lg  text-danger">
                            **Required data**
                          </p>
                          <div className="col-12">
                            <Label for="form-2-zip">
                              House Address{' '}
                              <span className="icon-danger">*</span>
                            </Label>
                            <Input
                              invalid={false}
                              id="form-2-zip"
                              placeholder=" Enter Street Address"
                              required={true}
                              value={
                                primaryResidence && primaryResidence.Street
                                  ? primaryResidence.Street
                                  : ''
                              }
                              onChange={(e) => {
                                setResidence({
                                  ...primaryResidence,
                                  Street: e.target.value
                                });
                              }}
                            />
                            <FormFeedback>required field</FormFeedback>
                          </div>
                          <Row>
                            <Col md="6" className="mb-3">
                              <Label for="form-2-city">
                                City <span className="icon-danger">*</span>
                              </Label>
                              <Input
                                invalid={false}
                                id="form-2-city"
                                placeholder="City"
                                required=""
                                value={
                                  primaryResidence && primaryResidence.City
                                    ? primaryResidence.City
                                    : ''
                                }
                                onChange={(e) => {
                                  setResidence({
                                    ...primaryResidence,
                                    City: e.target.value
                                  });
                                }}
                              />
                              <FormFeedback>required field</FormFeedback>
                            </Col>

                            <div className="col-md-3 mb-3">
                              <FormGroup>
                                <Label for="State">
                                  State <span className="icon-danger">*</span>
                                </Label>
                                <select
                                  className="form-control"
                                  id="inputState"
                                  value={
                                    primaryResidence && primaryResidence.State
                                      ? primaryResidence.State
                                      : null
                                  }
                                  onChange={(e) => {
                                    setResidence({
                                      ...primaryResidence,
                                      State: e.target.value
                                    });
                                  }}>
                                  <option>Choose...</option>
                                  {stateLabelValues
                                    ? stateLabelValues.map((state, index) => (
                                        <option key={index} value={state.value}>
                                          {state.label}
                                        </option>
                                      ))
                                    : null}
                                </select>
                              </FormGroup>
                              <FormFeedback>required field</FormFeedback>
                            </div>

                            <div className="col-md-3 mb-3">
                              <Label for="form-2-zip">
                                Zip <span className="icon-danger">*</span>
                              </Label>
                              <Input
                                invalid={
                                  primaryResidence && primaryResidence.Zip
                                    ? !Validator.validateZipCode(
                                        primaryResidence.Zip
                                      )
                                    : false
                                }
                                id="form-2-zip"
                                placeholder="Zip"
                                required=""
                                value={
                                  primaryResidence && primaryResidence.Zip
                                    ? primaryResidence.Zip
                                    : ''
                                }
                                onChange={(e) => {
                                  setResidence({
                                    ...primaryResidence,
                                    Zip: e.target.value
                                  });
                                }}
                              />
                              <FormFeedback>
                                Please enter a valid Zip code
                              </FormFeedback>
                            </div>
                          </Row>

                          <div className="form-group mb-5">
                            By clicking the <strong>Create account</strong>{' '}
                            button below you agree to our terms of service and
                            privacy statement.
                          </div>

                          <Button
                            color="primary"
                            size="lg"
                            block={true}
                            className="mb-3"
                            onClick={async () => {
                              //make sure shit is all here

                              setLoading(true);

                              if (
                                !first ||
                                !last ||
                                !password ||
                                !passwordConfirmation ||
                                !primaryResidence.Street ||
                                !primaryResidence.Zip ||
                                !primaryResidence.State ||
                                !primaryResidence.City
                              ) {
                                toast.error(
                                  'Please fill out all fields before creating your account',
                                  {
                                    position: 'top-center',
                                    autoClose: 7000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined
                                  }
                                );

                                setLoading(false);
                                return;
                              }

                              if (!Validator.validateEmail(email)) {
                                toast.error('Please enter a valid email', {
                                  position: 'top-center',
                                  autoClose: 7000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined
                                });

                                setLoading(false);
                                return;
                              }

                              if (
                                !Validator.passwordsMatch(
                                  password,
                                  passwordConfirmation
                                )
                              ) {
                                toast.error('Passwords do not match', {
                                  position: 'top-center',
                                  autoClose: 7000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined
                                });

                                setLoading(false);
                                return;
                              }

                              if (
                                !Validator.validatePasswordRules(password)
                                  .isValid
                              ) {
                                toast.error(
                                  'Password does not meet minimum security requirements',
                                  {
                                    position: 'top-center',
                                    autoClose: 7000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined
                                  }
                                );

                                setLoading(false);
                                return;
                              }

                              if (
                                !Validator.validateZipCode(primaryResidence.Zip)
                              ) {
                                toast.error('Please enter a valid Zip code', {
                                  position: 'top-center',
                                  autoClose: 7000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined
                                });

                                setLoading(false);
                                return;
                              }

                              // if (!phone){

                              //   toast.error('Please verify your phone number', {
                              //     position: "top-center",
                              //     autoClose: 7000,
                              //     hideProgressBar: false,
                              //     closeOnClick: true,
                              //     pauseOnHover: true,
                              //     draggable: true,
                              //     progress: undefined,
                              //     });
                              //     return
                              // }
                              const inU = await verifyEmailNotInUse(email);

                              if (!inU.isAvail) {
                                setEmailAvail(false);

                                toast.error(
                                  'Email you are trying to sign up with is already associated to an existing account',
                                  {
                                    position: 'top-center',
                                    autoClose: 10000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined
                                  }
                                );

                                setLoading(false);
                                return;
                              }

                              props.onLoginClick();
                              const user = await RegisterThenLogin(
                                email,
                                password,
                                first,
                                last,
                                phone,
                                primaryResidence
                              );

                              setTimeout(() => {
                                setLoading(false);
                              }, 1000);

                              if (user) {
                                props.onLoginSuccess(user);
                              }
                            }}>
                            Create Account
                          </Button>
                        </div>
                      </Col>
                    </Col>
                    <Col lg="5" xl="6" className="d-flex">
                      <div className="hero-wrapper w-100 bg-composed-wrapper bg-dark min-vh-lg-100">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div
                            className="bg-composed-wrapper--image opacity-5"
                            style={{ backgroundImage: 'url(' + hero4 + ')' }}
                          />
                          <div className="bg-composed-wrapper--bg bg-second opacity-5" />
                          <div className="bg-composed-wrapper--bg bg-deep-sky opacity-2" />
                          <div className="bg-composed-wrapper--content text-center p-5">
                            <div className="text-white px-0 px-lg-2 px-xl-4">
                              <img
                                src={illustration1}
                                alt="..."
                                className="m-auto img-fluid"
                                width={400}
                              />

                              <div className="divider mx-auto border-1 my-5 border-light opacity-2 rounded w-25" />
                              <div>
                                <Button
                                  tag={NavLink}
                                  to="/Homepage"
                                  color="success"
                                  className="px-5 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none py-3 hover-scale-sm hover-scale-lg">
                                  <span className="btn-wrapper--label">
                                    home page
                                  </span>
                                  <span className="btn-wrapper--icon">
                                    <FontAwesomeIcon icon={['fas', 'home']} />
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="hero-footer pb-4">
                          <Nav pills className="nav-neutral-secondary">
                            <NavItem>
                              <NavLinkStrap
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="font-size-lg text-white-50"
                                id="FacebookTooltipExample7">
                                <FontAwesomeIcon icon={['fab', 'facebook']} />
                              </NavLinkStrap>
                              <UncontrolledTooltip
                                target="FacebookTooltipExample7"
                                container="body">
                                Facebook
                              </UncontrolledTooltip>
                            </NavItem>
                            <NavItem>
                              <NavLinkStrap
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="font-size-lg text-white-50"
                                id="btnTwitterTooltip">
                                <FontAwesomeIcon icon={['fab', 'twitter']} />
                              </NavLinkStrap>
                              <UncontrolledTooltip target="btnTwitterTooltip">
                                Twitter
                              </UncontrolledTooltip>
                            </NavItem>
                            <NavItem>
                              <NavLinkStrap
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="font-size-lg text-white-50"
                                id="btnGoogleTooltip">
                                <FontAwesomeIcon icon={['fab', 'google']} />
                              </NavLinkStrap>
                              <UncontrolledTooltip target="btnGoogleTooltip">
                                Google
                              </UncontrolledTooltip>
                            </NavItem>
                            <NavItem>
                              <NavLinkStrap
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="font-size-lg text-white-50"
                                id="btnInstagramTooltip">
                                <FontAwesomeIcon icon={['fab', 'instagram']} />
                              </NavLinkStrap>
                              <UncontrolledTooltip target="btnInstagramTooltip">
                                Instagram
                              </UncontrolledTooltip>
                            </NavItem>
                          </Nav>
                        </div> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.UserReducer.isFetching,
    Fname: state.UserReducer.First
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoginClick: () => {
      dispatch(requestLogin());
    },

    onLoginSuccess: (data) => {
      dispatch(receiveLogin(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LivePreviewExample);
