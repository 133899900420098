import React, { useState } from 'react';

import clsx from 'clsx';
import {connect} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  FormFeedback,
  Col,
  Container,
  Label,
  FormGroup,
  Input,
  Button,Modal
} from 'reactstrap';
import { stateLabelValues } from 'utils/states';
import {updateUserInfo} from '../../../reducers/Actions/loginActions'
import Validator from 'utils/Validator';

import {BounceLoader} from "react-spinners";

import { toast, Zoom } from 'react-toastify';

const ProfileEditForm = (props) => {


    const [modal4, setModal4] = React.useState(false);
    
    const[primaryResidence, setResidence] = React.useState({
        City : props.Location.City,
        State : props.Location.State,
        Street : props.Location.Street,
        Zip : props.Location.Zip

    })
    
  
    const toggle4 = () => {
      
      
      
      setModal4(!modal4); 
  
  
    }
    const [isLoading, setLoad] = React.useState(false)

    return (
        <>
        <Container>
              <div className="text-uppercase font-weight-bold text-primary pt-4 font-size-sm">
              Primary Residence Information
              </div>
              <div className="py-4">
                <Row>
              <Col md="6" className="mb-3">
                        <Label for="form-2-street" className="font-weight-bold" >House Address <span className="icon-danger">*</span></Label>
                        <Input invalid={false} id="form-2-street" placeholder=" Enter Street Address" required={true} style={{ textTransform: 'capitalize'}}
                        
                        value = {primaryResidence && primaryResidence.Street ? primaryResidence.Street : ""}
                        onChange = {(e) =>{
                          setResidence({...primaryResidence, Street : e.target.value})
                         
                        }}/>
                            <FormFeedback>required field</FormFeedback>
                    </Col>
                    <Col md="6" className="mb-3">
                        <Label for="form-2-city" className="font-weight-bold" style={{ textTransform: 'capitalize'}}>City <span className="icon-danger">*</span></Label>
                        <Input invalid={false} id="form-2-city" placeholder="City" required=""
                        value = {primaryResidence && primaryResidence.City ? primaryResidence.City : ""}
                        onChange = {(e) =>{
                          setResidence({...primaryResidence, City : e.target.value})
                         
                        }}/>
                            <FormFeedback>required field</FormFeedback>
                    </Col>
                    </Row>
                          <Row>
                    
                    
                          <Col md="6" className="mb-3">
              <FormGroup>
                <Label for="State" className="font-weight-bold" >State <span className="icon-danger">*</span></Label>
                  <select className="form-control" id="inputState" value = { primaryResidence && primaryResidence.State ? primaryResidence.State : null} onChange = {(e)=>{setResidence({...primaryResidence, State : e.target.value})}}>
                    <option >Choose...</option>
                    {stateLabelValues ? stateLabelValues.map((state,index)=>

                         <option key = {index} value={state.value}>{state.label}</option>

                    ): null}
                  </select>
              </FormGroup>
              <FormFeedback>required field</FormFeedback>
            </Col>
                      
                            
                    
            <Col md="6" className="mb-3">
                        <Label for="form-2-zip" className="font-weight-bold" >Zip <span className="icon-danger">*</span></Label>
                        <Input 
                         invalid = {primaryResidence && primaryResidence.Zip ? !Validator.validateZipCode(primaryResidence.Zip):false}
                         id="form-2-zip" placeholder="Zip" required=""
                        value = {primaryResidence && primaryResidence.Zip ? primaryResidence.Zip : ""}
                        onChange = {(e) =>{
                          setResidence({...primaryResidence, Zip : e.target.value})
                         
                        }}/>
                            <FormFeedback>Please enter a valid Zip code</FormFeedback>
                    </Col>
                </Row>

              </div>
            </Container>
            <div className="divider mb-4" />
            
            <div className="divider mt-5 mb-4" />
            <Container className="d-flex align-items-center justify-content-end">
              <div className="py-4">
                <Button color="success" size="lg" className="font-weight-bold" disabled = {true}
                onClick = {toggle4 }>
                  Save changes
                </Button>
              </div>
            </Container>





        <Modal zIndex={2000} centered isOpen={modal4} toggle={toggle4}>
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-first text-first m-0 d-130">
                <FontAwesomeIcon
                  icon={['far', 'keyboard']}
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">
              Are you sure you want to confirm the updated information for this member ?
            </h4>
            <p className="mb-0 text-black-50">
              You can always change it later.
            </p>
            {isLoading ? (
              <div
                className="d-flex mx-auto align-items-center justify-content-center"
                style={{ width: '150px', height: '80px' }}>
                <BounceLoader color={'var(--primary)'} loading={true} />
              </div>
            ) : null}
            <div className="pt-4">
              <Button
                onClick={toggle4}
                color="neutral-secondary"
                className="btn-pill text-danger mx-1">
                <span className="btn-wrapper--label">Cancel</span>
              </Button>
              <Button
                color="first"
                className="btn-pill mx-1"
                
                onClick={async () => {
                 
                    setLoad(true)
                    const data = {
                        ID : props.UID
                    }

                   
                    setTimeout(() => {
                   
                
                      setLoad(false);
                      toggle4()
                      
                
                      toast.success('Your info has been updated !', {
                        containerId: 'B',
                        transition: Zoom
                      });

                    }, 1000);
                  }}>
                <span className="btn-wrapper--label">Update</span>
              </Button>
            </div>
          </div>
        </Modal>
        </>
    )

}
const MapStateToProps = (state) => {
    return {
      Location: state.UserReducer.Location,
      UID: state.UserReducer.ID,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
     onUpdateUserInfo : (info) => {
         dispatch(updateUserInfo(info))
     }
    };
  };
  
  export default connect(MapStateToProps, mapDispatchToProps)(ProfileEditForm);