import React, {Fragment} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {BounceLoader} from "react-spinners";
import {connect} from 'react-redux'
import Geocode from "react-geocode";
import { toast, Zoom } from 'react-toastify';
// reactstrap components
import { Button,Modal,Input,Label } from 'reactstrap';
import OverviewMap from 'example-components/Overview/OverviewMap';
import {updateAHouseHold} from 'utils/API';
import {updateHouseHold} from '../../../reducers/Actions/houseHoldActions'
import {  useParams
} from "react-router-dom";
// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyASasVewAMvMAq-sU7DaMskF3A-S52nQng");

// set response language. Defaults to english.
Geocode.setLanguage("en");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("us");

// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
Geocode.setLocationType("ROOFTOP");


const  LivePreviewExample = (props) => {

  const [modal4, setModal4] = React.useState(false);
  const toggle4 = () => {
    
    if(modal4)
      setCoords(null)
      setCons(false)
    
    setModal4(!modal4); 


  }

  let { id } = useParams();



  const [isLoading, setLoad] = React.useState(false)
  const [streetk, setStreet]= React.useState('')
  const [cityk, setCity]= React.useState('')
  const [zipk, setZip]= React.useState('')
  const [statek, setState]= React.useState('')
  const [name, setName]= React.useState('')
  const [description, setDescription]= React.useState('')
  const [coords, setCoords] = React.useState(null)
  const [cons, setCons] = React.useState(false)

  const fetchHouseDetails = () => {

    if (props.HouseHolds && Array.isArray(props.HouseHolds)){

      const homeData = props.HouseHolds.filter(item => item.ID === id)
    
      const data = homeData[0]

      setCity(data.City)
      setState(data.State)
      setStreet(data.Street)
      setZip(data.Zip)
      setCoords(data.Geo)
      setName(data.Name)
      setDescription(data.Description)
      
    }



  }

  React.useEffect(() => {fetchHouseDetails()},[])
    


  const  getPosition = (position) => {
    console.log(position.coords.latitude, position.coords.longitude);

    Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
      (response) => {
        let city, state, zip,streetNum, streetName;
        for (let i = 0; i < response.results[0].address_components.length; i++) {
          for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                setCity(city)
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].short_name;
                setState(state)
                break;
              case "postal_code":
                zip = response.results[0].address_components[i].long_name;
                setZip(zip)
                break;
              case "street_number":
                  streetNum = response.results[0].address_components[i].long_name;
                  
                  break;  
              case "route":
                    streetName = response.results[0].address_components[i].long_name;
                    
                    break;        
            }
          }
        }
        setStreet(`${streetNum}  ${streetName}`)
        
      },
      (error) => {
        console.error(error);
      }
    );

  }
  return (
    <>
      <Fragment>
        
                <div className="wizard-steps horizontal">
                    <div className="p-4">
                        <h5 className="font-size-xl font-weight-bold">Edit this House Hold</h5>
                        <div className= {"m-2"} >
                        <h6 className="font-size-md mt-2">Enter Your House Hold Information below</h6>
                        <Button   color= {"warning btn-sm m-2"} onClick = {() => {
                          //hit delete endpoint here
                          }}>Delete this house hold </Button>
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputAddress2" style={{textTransform : "capitalize"}}>Street Address <span className="icon-danger">*  </span></label>
                            <input disabled={true} className="form-control" id="inputAddress2" placeholder="ex : 123 st " type="text" value= {streetk} onChange= {(e) => setStreet(e.target.value)}/>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputCity" style={{textTransform : "capitalize"}}>City <span className="icon-danger">*  </span></label>
                                <input disabled={true} className="form-control" id="inputCity" type="text" value= {cityk} onChange= {(e)=>setCity(e.target.value)}/>
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputState">State <span className="icon-danger">*  </span></label>
                                <select  disabled={true} className="form-control" id="inputState" value = {statek} onChange = {(e)=>{setState(e.target.value)}}>
                                    <option >Choose...</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">District of Columbia</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                </select>
                            </div>
                            <div className="form-group col-md-2">
                                <label htmlFor="inputZip">Zip <span className="icon-danger">*  </span></label>
                                <input  disabled={true} className="form-control" id="inputZip" type="text" value= {zipk} onChange= {(e)=>{setZip(e.target.value)}}/>
                            </div>
                        </div>
                        <h5 className="font-size-xl font-weight-bold">Name This House Hold</h5>
                        <label htmlFor="householdname" style={{textTransform : "capitalize"}}>House Name <span className="icon-danger">*  </span></label>
                            <input className="form-control" id="householdname" placeholder="House 1" type="text" value= {name} onChange= {(e) => setName(e.target.value)}/>

                      <h5 className="font-size-xl font-weight-bold mt-3 mb-2">Addtional Information</h5>
                        <label htmlFor="addinfo">(optional)</label>
                            <Input
                      className="textarea-limited"
                      maxLength="150"
                      placeholder="Additional information here..."
                      rows="7"
                      type="textarea"
                      defaultValue = {''}
                      value = {description}
                      onChange = {(e) => {

                       setDescription(e.target.value)

                      }}/>

                            <Button disabled= {!cityk || !statek || !streetk|| !zipk || !name} color="primary" className= "btn-block m-4 w-50 mx-auto" onClick = {()=> {
                        
                                toggle4()

                            
                              
                                  Geocode.fromAddress(`${streetk} ${cityk} ${statek} ${zipk}`).then(
                                  (response) => {
                                    const { lat, lng } = response.results[0].geometry.location;
                                    console.log(lat, lng);
                                    setCoords([{name : name, Latitude : lat, Longitude : lng}])
                                  },
                                  (error) => {
                                    console.error(error);
                                  }
                                );
                              
                              

                            }} >
                                <span className="btn-wrapper--label">Update House Hold</span>
                                <span className="btn-wrapper--icon">
                                    
                                </span>
                            </Button>
                    </div>

                    <Modal zIndex={2000} centered isOpen={modal4} toggle={toggle4}>
      <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-first text-first m-0 d-130">
                  <FontAwesomeIcon icon={['far', 'keyboard']} className="d-flex align-self-center display-3"/>
              </div>
          </div>
          <h4 className="font-weight-bold mt-4">Are you sure you want to confirm the information for this House Hold ?</h4>
          <p className="mb-0 text-black-50 m-2">{streetk + ' ' + cityk + ' ' + statek + ' ' + zipk}</p>

          <Label className="font-weight-bold m-2" for="">
              &emsp;&emsp;&emsp;&emsp;
          <Input type="checkbox" value = {cons} onChange= { (e) =>{ setCons(!cons) }}/>
          The information i provided is legitimate <span className="icon-danger">*  </span>
                            </Label>

          <OverviewMap coordinates = {coords && coords.length > 0 ? coords:false}></OverviewMap>
          {isLoading ?   <div className="d-flex mx-auto align-items-center justify-content-center" style={{width: '150px', height: '80px'}}>
                            <BounceLoader color={'var(--primary)'} loading={true}/>
                        </div> : null }
          <div className="pt-4">
              <Button onClick={toggle4} color="neutral-secondary" className="btn-pill text-danger mx-1">
                      <span className="btn-wrapper--label">
                          Cancel
                      </span>
              </Button>
              <Button  color="first" className="btn-pill mx-1" onClick= { async ()=> {


                  setLoad(true)

                  if(!cons){
                    toast.warn('Please Verify that the information below is legitimate', {
                      containerId: 'B',
                      transition: Zoom
                    });
                    setLoad(false)
                    return
                  }

                  if (!cityk.trim() || !statek.trim() || !streetk.trim() || !zipk.trim() || !name.trim()){
                    setLoad(false)

                    toast.warn('Please populate required fields', {
                      containerId: 'B',
                      transition: Zoom
                    });
               
                    
                    return
                  }


                    const updateHouse = {
                      UID : props.UID,
                      ID : id,
                      Name : name,
                      City : cityk,
                      Street : streetk,
                      Zip : zipk,
                      State : statek,
                      Location : streetk + ' ' + cityk + ' ' + statek + ' ' + zipk,
                      Geo : {Lat : coords[0].Latitude , Lng : coords[0].Longitude}

                    }

                    const request = await updateAHouseHold(updateHouse)
                

                    setTimeout(() => {
                      
                     

                      setLoad(false)  

                      if (request ){
                      window.location = '/HouseHolds'

                      toast.success('House Hold has been updated !', {
                          containerId: 'B',
                          transition: Zoom
                        });


                        props.onHouseHoldUpdate(request) //redux update house hold here

                      }
                    }, 1000);

                    

              }}>
                      <span className="btn-wrapper--label">
                          Upload Changes
                      </span>
              </Button>
          </div>
      </div></Modal>
                </div>
            </Fragment>
   </>
  );
}
const MapStateToProps = state => {

  return {
    UID : state.UserReducer.ID,
    HouseHolds : state.UserReducer.HouseHolds
    }
  
}

const mapDispatchToProps = (dispatch) => {
  return {
    onHouseHoldUpdate : (HouseHold) => {
      dispatch(updateHouseHold(HouseHold)) //change this to edit
    } ,

   

  }};

export default connect(MapStateToProps,mapDispatchToProps)(LivePreviewExample);