export const LOAD_NEW_ENTRIES = 'LOAD_NEW_ENTRIES'
export const DELETE_NEW_ENTRY = 'DELETE_NEW_ENTRY'
export const LOAD_UPDATED_ENTRIES = 'LOAD_UPDATED_ENTRIES'
export const DELETE_UPDATED_ENTRY = 'DELETE_UPDATED_ENTRY'

export function loadUpdatedEntries(UpdatedEntries) {
  return {
    type: LOAD_UPDATED_ENTRIES,
    payload : { 
      UpdatedEntries
      
    }
  }
}

export function deleteUpdatedEntry(EntryID) {
  return {
    type: DELETE_UPDATED_ENTRY,
    payload : {
        EntryID
    }
  }
}
export function loadNewEntries(NewEntries) {
  return {
    type: LOAD_NEW_ENTRIES,
    payload : { 
        NewEntries
      
    }
  }
}

export function deleteNewEntry(EntryID) {
  return {
    type: DELETE_NEW_ENTRY,
    payload : {
        EntryID
    }
  }
}
