import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  CardHeader,
  Button,
  UncontrolledTooltip
} from 'reactstrap';
import defaultAvatar from "../../../assets/images/placeholder.jpg";


import {

  PropagateLoader,GridLoader

} from "react-spinners";
import {connect} from 'react-redux'

const  LivePreviewExample = (props) => {
  console.log(props.Members)
  return (
    <>
      <Card className="w-100 mb-5">
        <CardHeader className="d-flex align-items-center justify-content-between card-header-alt p-4">
          <div>
            <h6 className="font-weight-bold font-size-lg mb-1 text-black">
              My Family Overview
            </h6>
            <p className="text-black-50 mb-0">Family Summary</p>
          </div>

        </CardHeader>
        <div className="divider" />
        <div className="divider" />
        <div className="mx-auto mt-3 text-center avatar-wrapper-overlap ">

        {props.Members ?  ( props.Members.length > 0 ? props.Members.map((member, index)=> {

          return (
            <div className="avatar-icon-wrapper avatar-icon-lg">
            <div className="avatar-icon">
              <img alt="..." src={  member.img ? member.img : defaultAvatar } />
            </div>
          </div>
          )
        }) : 
        <div className="d-flex align-items-center justify-content-center">
        <Button
        onClick = {()=> {window.location = '/Members/Add'}}
          color="neutral-primary"
          id="CreateNewWallet"
          className="d-60 d-flex align-items-center justify-content-center p-0 rounded-pill mx-4">
          <FontAwesomeIcon
            icon={['fas', 'plus']}
            className="font-size-lg"
          />
        </Button>
        <UncontrolledTooltip target="CreateNewWallet">
          Add New Member
        </UncontrolledTooltip>
      </div> ): <div className="d-flex mx-auto align-items-center justify-content-center" style={{width: '150px', height: '80px'}}>
           <GridLoader color={'var(--primary)'} loading={true}/>
       </div>}

    </div>
        <small className=" mt-4 text-center font-weight-bold text-muted text-uppercase">

        {props.Members ?  ( props.Members.length > 0 ? `${props.Members.length} family members` : 
           'No family members registered yet' ): <div className="d-flex mx-auto align-items-center justify-content-center" style={{width: '150px', height: '80px'}}>
           <PropagateLoader color={'var(--primary)'} loading={true}/>
       </div>}
          
        </small>
        <div className="divider mt-5" />
        <div className="divider" />
       
        <div className="card-footer p-3 text-center">
          <Button size="sm" className="py-2 px-4" color="primary" onClick = {()=> {window.location = '/Members'}}>
            <span className="btn-wrapper--label text-uppercase font-weight-bold">
              View all Family members
            </span>
          </Button>
        </div>
      </Card>
    </>
  );
}
const MapStateToProps = state => {

  return {
    FirstName : state.UserReducer.First,
    Members : state.UserReducer.Members
    }
  
}



export default connect(MapStateToProps,null)(LivePreviewExample);