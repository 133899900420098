import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  Col,
  FormGroup,
  Input,
  UncontrolledTooltip,
  Nav,
  NavItem,
  Button,
  InputGroup,
  InputGroupText
} from 'reactstrap';
import { NavLink as NavLinkStrap } from 'reactstrap';

import hero6 from './../../../assets/images/hero-bg/hero-4.jpg';

import { changePassword } from '../../../utils/API';

import Validator from 'utils/Validator';
// import Validator from '../../../';

export default function PagePasswordChangeCover(props) {
  const [showPass, setShowPass] = useState(0);
  const [uID, setUID] = useState(0);
  const [returnHome, setReturnHome] = useState(0);

  const [issues, setIssues] = useState([]);

  const [loading, setLoading] = useState(0);

  const toggleShow = () => setShowPass(!showPass);
  const search = useLocation().search;

  useEffect(() => {
    // TODO: Fix getting param from link
    const id = new URLSearchParams(search).get('id');

    if (id) {
      setUID(id);
    } else {
      toast.error('Issue grabbing Account Email');
    }
  }, []);

  const handleChange = async () => {
    let password = document.getElementById('pass').value;
    if (!password) {
      toast.error('No password to set');
      return;
    }

    if (!uID) {
      toast.error('Issue grabbing Account Email');
      return;
    }

    const passReq = Validator.validatePasswordRules(password);

    if (!passReq.isValid) {
      toast.error('Password does not meet minimum security requirements');
      passReq.messages.map(toast.error);
      setIssues(passReq.messages);
      return;
    }

    setLoading(1);

    let payload = {
      ID: uID ? uID : '',
      Password: password
    };

    let res = await changePassword(payload);

    console.log('dsd', res);

    if (res && res.status === 200) {
      toast.success('Password Updated');
      setReturnHome(1);
    }

    setLoading(0);
  };

  const redirectHome = () => (window.location = '/');

  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <Row className="min-vh-100 no-gutters">
                    <Col lg="7" xl="6" className="d-flex align-items-center">
                      <Col md="10" lg="8" xl="7" className="mx-auto">
                        <div className="py-4">
                          <div className="text-center mb-5">
                            <h1 className="display-4 mb-1 font-weight-bold">
                              Recover Password
                            </h1>
                            <p className="font-size-lg mb-0 text-black-50">
                              Forgot your password? No worries, we're here to
                              help!
                            </p>
                          </div>
                          {returnHome ? (
                            <div>
                              <FormGroup>
                                <label className="font-weight-bold">
                                  Return Home
                                </label>
                                <div className="text-center mb-5">
                                  <Button
                                    onClick={redirectHome}
                                    className="btn-block text-uppercase font-weight-bold font-size-sm mt-4"
                                    color="primary">
                                    Return Home
                                  </Button>
                                </div>
                              </FormGroup>
                            </div>
                          ) : (
                            <div>
                              <FormGroup>
                                <label className="font-weight-bold">
                                  New Password
                                </label>
                                <InputGroup className="mb-3">
                                  <Input
                                    id="pass"
                                    bsSize="lg"
                                    placeholder="******************"
                                    type={showPass ? 'text' : 'password'}
                                    autoComplete="new-password"
                                    disabled={loading ? true : false}
                                  />
                                  <Button
                                    color="primary"
                                    id="basic-addon1"
                                    onClick={toggleShow}>
                                    {/* <i class="far fa-eye"></i> */}
                                    <FontAwesomeIcon icon={['far', 'eye']} />
                                  </Button>
                                </InputGroup>
                                {issues.length ? (
                                  issues.map((d, i) => (
                                    <label
                                      style={{
                                        color: 'red',
                                        fontSize: '.8rem',
                                        border: '1px solid red',
                                        padding: '7px',
                                        borderRadius: '13px'
                                      }}
                                      key={i}
                                      className="font-weight-bold">
                                      {d}
                                    </label>
                                  ))
                                ) : (
                                  <></>
                                )}
                              </FormGroup>
                              <div className="text-center mb-5">
                                <Button
                                  className="btn-block text-uppercase font-weight-bold font-size-sm mt-4"
                                  color="primary"
                                  onClick={handleChange}
                                  disabled={loading ? true : false}>
                                  {loading ? 'Loading...' : 'Set Password'}
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Col>
                    <Col lg="5" xl="6" className="d-flex">
                      <div className="hero-wrapper w-100 bg-composed-wrapper bg-happy-green min-vh-lg-100">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div
                            className="bg-composed-wrapper--image opacity-7"
                            style={{
                              backgroundImage: 'url(' + hero6 + ')'
                            }}
                          />
                          <div className="bg-composed-wrapper--bg bg-second opacity-7" />
                          <div className="bg-composed-wrapper--bg bg-premium-dark opacity-4" />
                          <div className="bg-composed-wrapper--content text-center p-5">
                            <div className="text-white px-0 px-lg-2 px-xl-4">
                              <h1>911READY</h1>
                              <div className="divider mx-auto border-1 my-5 border-light opacity-2 rounded w-25" />
                              <div>
                                <Button
                                  onClick={() =>
                                    (window.location = '/Homepage')
                                  }
                                  color="first"
                                  className="px-5 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none py-3 hover-scale-sm hover-scale-lg">
                                  <span className="btn-wrapper--label">
                                    Home
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="hero-footer pb-4">
                          {/* <Nav pills className="nav-neutral-secondary">
                            <NavItem>
                              <NavLinkStrap
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="font-size-lg text-white-50"
                                id="FacebookTooltipExample7">
                                <FontAwesomeIcon icon={['fab', 'facebook']} />
                              </NavLinkStrap>
                              <UncontrolledTooltip
                                target="FacebookTooltipExample7"
                                container="body">
                                Facebook
                              </UncontrolledTooltip>
                            </NavItem>
                            <NavItem>
                              <NavLinkStrap
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="font-size-lg text-white-50"
                                id="btnTwitterTooltip">
                                <FontAwesomeIcon icon={['fab', 'twitter']} />
                              </NavLinkStrap>
                              <UncontrolledTooltip target="btnTwitterTooltip">
                                Twitter
                              </UncontrolledTooltip>
                            </NavItem>
                            <NavItem>
                              <NavLinkStrap
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="font-size-lg text-white-50"
                                id="btnGoogleTooltip">
                                <FontAwesomeIcon icon={['fab', 'google']} />
                              </NavLinkStrap>
                              <UncontrolledTooltip target="btnGoogleTooltip">
                                Google
                              </UncontrolledTooltip>
                            </NavItem>
                            <NavItem>
                              <NavLinkStrap
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="font-size-lg text-white-50"
                                id="btnInstagramTooltip">
                                <FontAwesomeIcon icon={['fab', 'instagram']} />
                              </NavLinkStrap>
                              <UncontrolledTooltip target="btnInstagramTooltip">
                                Instagram
                              </UncontrolledTooltip>
                            </NavItem>
                          </Nav> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
