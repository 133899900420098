import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  UPDATE_USER
} from './Actions/loginActions';
import { LOGOUT_SUCCESS } from './Actions/logoutActions';
import {
  LOAD_NEW_ENTRIES,
  LOAD_UPDATED_ENTRIES,
  DELETE_NEW_ENTRY,
  DELETE_UPDATED_ENTRY
} from './Actions/entryActions';
import {
  LOAD_HOUSEHOLDS,
  ADD_HOUSEHOLD,
  DELETE_HOUSEHOLD,
  UPDATE_HOUSEHOLD
} from './Actions/houseHoldActions';
import {
  LOAD_MEMBERS,
  ADD_MEMBER,
  UPDATE_MEMBER,
  DELETE_MEMBER
} from './Actions/membersActions';
import {
  LOAD_ADMINS,
  LOAD_MASTER_MODS,
  LOAD_MODS,
  ADD_ADMIN,
  ADD_MASTER_MOD,
  ADD_MOD
} from './Actions/roleActions';

const initialStore = {
  //this shits basic info for any account
  ID: '',
  Token: '',
  Email: '',
  isAuthenticated: localStorage.getItem('state') ? true : false,
  First: '',
  Last: '',
  Role: '',
  Cell: '',
  isFetching: false,
  Members: false,
  HouseHolds: false,
  Location: '',
  isPrime: true,

  //shits for admins and mods here

  NewEntries: false,
  UpdatedEntries: false,

  Moderators: false,
  MasterModerators: false,
  Admins: false
};

export default function reducer(store = initialStore, action) {
  let { type, payload } = action;

  switch (type) {
    case LOGIN_REQUEST:
      return Object.assign({}, store, {
        isFetching: true,
        isAuthenticated: false
      });

    case LOGIN_SUCCESS:
      return Object.assign({}, store, {
        isFetching: false,
        isAuthenticated: true,
        Email: payload.user.Email,
        Token: payload.user.JWT,
        First: payload.user.FirstName,
        Last: payload.user.LastName,
        Cell: payload.user.Cell.replaceAll('+1', ''),
        Role: payload.user.Role,
        ID: payload.user.ID,
        Location: payload.user.Location
      });
    case LOAD_MEMBERS:
      return Object.assign({}, store, {
        Members: payload.Members
      });

    case LOAD_NEW_ENTRIES:
      return Object.assign({}, store, {
        NewEntries: payload.NewEntries
      });
    case LOAD_UPDATED_ENTRIES:
      return Object.assign({}, store, {
        UpdatedEntries: payload.UpdatedEntries
      });

    case LOAD_MODS:
      return Object.assign({}, store, {
        Moderators: payload.Mods
      });

    case LOAD_MASTER_MODS:
      return Object.assign({}, store, {
        MasterModerators: payload.MasterMods
      });

    case LOAD_ADMINS:
      return Object.assign({}, store, {
        Admins: payload.Admins
      });

    case DELETE_NEW_ENTRY:
      return Object.assign({}, store, {
        NewEntries: store.NewEntries.filter(
          (item) => item.ID !== payload.EntryID
        )
      });

    case DELETE_UPDATED_ENTRY:
      return Object.assign({}, store, {
        UpdatedEntries: store.UpdatedEntries.filter(
          (item) => item.ID !== payload.EntryID
        )
      });

    case ADD_MOD:
      return Object.assign({}, store, {
        Moderators: [...store.Moderators, payload.Mod]
      });

    case ADD_MASTER_MOD:
      return Object.assign({}, store, {
        MasterModerators: [...store.MasterModerators, payload.MasterMod]
      });

    case ADD_ADMIN:
      return Object.assign({}, store, {
        Admins: [...store.Admins, payload.Admin]
      });

    case ADD_MEMBER:
      return Object.assign({}, store, {
        Members: [...store.Members, payload.Member]
      });

    case UPDATE_MEMBER:
      let membersCopy = [...store.Members];
      const index = store.Members.findIndex((x) => x.ID == payload.Member.ID);

      membersCopy[index] = payload.Member;

      return Object.assign({}, store, {
        Members: membersCopy
      });
    case UPDATE_USER:
      return Object.assign({}, store, {
        First: payload.user.First ? payload.user.First : store.First,
        Last: payload.user.Last ? payload.user.Last : store.Last,
        Cell: payload.user.Cell ? payload.user.Cell : store.Cell,
        Email: payload.user.Email ? payload.user.Email : store.Email
      });

    case UPDATE_HOUSEHOLD:
      let houseCopy = [...store.HouseHolds];
      const index2 = store.HouseHolds.findIndex(
        (x) => x.ID == payload.HouseHold.ID
      );

      houseCopy[index2] = payload.HouseHold;

      return Object.assign({}, store, {
        HouseHolds: houseCopy
      });

    case DELETE_MEMBER:
      return Object.assign({}, store, {
        Members: store.Members.filter((item) => item.ID !== payload.MemberID)
      });

    case LOAD_HOUSEHOLDS:
      let hasPrime = true;

      console.log(payload.HouseHolds);

      if (Array.isArray(payload.HouseHolds)) {
        const i = payload.HouseHolds.findIndex(
          (x) => x.hasOwnProperty('isPrime') && x.isPrime == true
        );

        console.log('index', i);
        if (i >= 0) hasPrime = true;
        else hasPrime = false;
      }

      return Object.assign({}, store, {
        HouseHolds: payload.HouseHolds,
        isPrime: hasPrime
      });
    case ADD_HOUSEHOLD:
      return Object.assign({}, store, {
        HouseHolds: [...store.HouseHolds, payload.HouseHold]
      });

    case DELETE_HOUSEHOLD:
      return Object.assign({}, store, {
        Members: store.HouseHolds.filter(
          (item) => item.ID !== payload.HouseHoldID
        )
      });

    case LOGIN_FAILURE:
      return Object.assign({}, store, {
        isFetching: false,
        isAuthenticated: false
      });

    case LOGOUT_SUCCESS:
      localStorage.removeItem('state');
      return Object.assign({}, store, {
        ID: '',
        Token: '',
        Email: '',
        isAuthenticated: false,
        First: '',
        Last: '',
        Role: '',
        Cell: '',
        Members: [],
        HouseHolds: [],
        isFetching: false
      });

    default:
      return store;
  }
}
