import React from 'react';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

// reactstrap components
import { Container, Row, Col, Input, Label, UncontrolledTooltip} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const AddProduct = (props) => {

  const [other, setOther] = React.useState("")
  const [agree, setAgree] = React.useState(false);
  return (
    <>
    <div className="main">
        <Container  className= "p-3">
          <h2>Medical Information</h2>
          <h5>Special Needs and/or Disabilities: (check all that apply)</h5>
          <Row>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" onChange = {() => {    props.setMedical({...props.Medical, "DNR" : !props.Medical.DNR});}} />
                Do Not Resuscitate (DNR) 
                <FontAwesomeIcon
                    icon={['fas', 'info-circle']}
                    id={'dnrTT'}
                    className="ml-2"
                  />
                  <UncontrolledTooltip
                    trigger="hover"
                    popperClassName="tooltip-second"
                    placement="right"
                    container="body"
                    target="dnrTT">
                    <b className="float-left">Requirements :</b> 
                    <br></br>
                    <br></br>
                    <ul>
                      <li className="float-left">You must have all paperwork present </li>
                      <br></br>
                      <br></br>
                      <li className="float-left "> Your paperwork must comply with Michigan's Do Not Resuscitate procedure act</li>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br><br></br>
                    </ul>
                  </UncontrolledTooltip>
              </Label>
            </Col>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" onChange = {() => {    props.setMedical({...props.Medical, "AutismASD" : !props.Medical.AutismASD});}}/>
                Autism/ASD
              </Label>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox"  onChange = {() => {    props.setMedical({...props.Medical, "Bipolar" : !props.Medical.Bipolar});}}/>
                Bipolar Disorder
              </Label>
            </Col>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" onChange = {() => {    props.setMedical({...props.Medical, "BrainInjury" : !props.Medical.BrainInjury});}} />
                Brain Injury / TBI
              </Label>
            </Col>
          </Row>
          <Row>
          <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" onChange = {() => {    props.setMedical({...props.Medical, "HearingImpairment" : !props.Medical.HearingImpairment});}}/>
                Hearing Impairments
              </Label>
            </Col>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" onChange = {() => {    props.setMedical({...props.Medical, "MedicalPumps" : !props.Medical.MedicalPumps});}} />
                Medical Pumps / Ventilators
              </Label>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" onChange = {() => {    props.setMedical({...props.Medical, "LimbsMissingOrPartiallyMissing" : !props.Medical.LimbsMissingOrPartiallyMissing});}}/>
                Limb(s) missing or partially missing
              </Label>
            </Col>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" onChange = {() => {    props.setMedical({...props.Medical, "VisuallyImpaired" : !props.Medical.VisuallyImpaired});}}/>
                Visually Impaired
              </Label>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" onChange = {() => {    props.setMedical({...props.Medical, "MobilityImpairmentRequiringWheelChair" : !props.Medical.MobilityImpairmentRequiringWheelChair});}} />
                Mobility impairment(s) 

                <FontAwesomeIcon
                    icon={['fas', 'info-circle']}
                    id={'mobileTT'}
                    className="ml-2"
                  />
                  <UncontrolledTooltip
                    trigger="hover"
                    popperClassName="tooltip-second"
                    placement="right"
                    container="body"
                    target="mobileTT">
                    requiring wheel chair
                  </UncontrolledTooltip>
              </Label>
            </Col>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" onChange = {() => {    props.setMedical({...props.Medical, "MentalHealthProblem" : !props.Medical.MentalHealthProblem});}} />
                Mental health problems 

<FontAwesomeIcon
    icon={['fas', 'info-circle']}
    id={'mhTT'}
    className="ml-2"
  />
  <UncontrolledTooltip
    trigger="hover"
    popperClassName="tooltip-second"
    placement="right"
    container="body"
    target="mhTT">
    <b className="float-left">Including :</b> 
    <br></br>
    <ul>
      <li className="float-left">Bi-polar </li>
      <br></br>
      <li className="float-left"> PTSD </li>
      <br></br>
      <li className="float-left"> schizophrenia </li> 
      <br></br>
      <li className="float-left"> violent tendencies</li>
      <br></br>
    </ul>
  </UncontrolledTooltip>
              </Label>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" onChange = {() => {    props.setMedical({...props.Medical, "NonVerbal" : !props.Medical.NonVerbal});}}/>
                Non-Verbal (speech and language delays)
              </Label>
            </Col>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" onChange = {() => {    props.setMedical({...props.Medical, "Obesity" : !props.Medical.Obesity});}} />
                Obesity (over 250lbs)
              </Label>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input type="checkbox" onChange = {() => {    props.setMedical({...props.Medical, "AlzheimersDementia" : !props.Medical.AlzheimersDementia});}} />
                Alzheimer's or Dementia
              </Label>
            </Col>
            <Col xs="6">
              <Label className="font-weight-bold" for="">
                &emsp;&emsp;&emsp;&emsp;
                <Input  type="checkbox" value={agree} onChange = {() => {  setAgree(!agree);   props.setMedical({...props.Medical, "Other" : other});}} /> 
                Other
                <Input
                  type="textarea"
                  onChange = {(e) =>{
                    
                    if(agree){
                      setOther(e.target.value)
                      props.setMedical({...props.Medical, "Other" : other});
                    
                    
                    }
                    
                    
                    }}
                  name="other"
                  id="other"
                  disabled={!agree}
                  placeholder="door code, location and combination of hide-a-key ect..."
                />
              </Label>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default connect(null, null)(withRouter(AddProduct));
