import React from 'react';

import { Row, Col, Container, Input } from 'reactstrap';

import Slider from 'react-slick';
import illustration1 from '../../../assets/images/illustrations/pack2/firstResponders.svg';

export default function LivePreviewExample() {
  return (
    <>
      <div className="z-over pb-5" style={{ marginTop: '-50px' }}>
        <Container className="pb-3 pb-xl-5">
          <Row>
            <Col xl="6" className="d-none d-xl-flex align-items-center">
              <img alt="..." className="w-100" src={illustration1} />
            </Col>
            <Col xl="6">
              <div className="py-0 pb-5 py-xl-5">
                <div className="pr-0 pr-xl-5">
                  <h1 className="display-3 mb-3 mt-2 font-weight-bold">
                    What exactly is our mission ?
                  </h1>
                  <p className="font-size-lg font-weight-bold text-black-50">
                    To ensure that first responders arrive to emergency
                    situations prepared and ready to extract or assist people
                    with specific needs
                  </p>
                </div>
                <div className="d-block mt-4">
                  <Slider
                    slidesToShow={2}
                    slidesToScroll={2}
                    dots={true}
                    className="slick-slider slider-dots-outside slick-slider-left">
                    <div>
                      <div className="feature-box pr-4">
                        <h3 className="font-size-lg font-weight-bold my-3">
                          <a
                            className="text-first"
                            href="#/"
                            onClick={(e) => e.preventDefault()}
                            title="">
                            Your Part
                          </a>
                        </h3>
                        <p className="text-black-50 font-weight-bold mb-3">
                          Provide us with any crucial medical information or
                          records that we should know upon arrival
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="feature-box pr-4">
                        <h3 className="font-size-lg font-weight-bold my-3">
                          <a
                            className="text-first"
                            href="#/"
                            onClick={(e) => e.preventDefault()}
                            title="">
                            Our Part
                          </a>
                        </h3>
                        <p className="text-black-50 font-weight-bold mb-3">
                          We ensure that first responders are well prepared to
                          address any situation in the most efficient manner
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="feature-box pr-4">
                        <h3 className="font-size-lg font-weight-bold my-3">
                          <a
                            className="text-first"
                            href="#/"
                            onClick={(e) => e.preventDefault()}
                            title="">
                            Data Privacy
                          </a>
                        </h3>
                        <p className="text-black-50 font-weight-bold mb-3">
                          Any data you provide is stored and encrypted in our
                          servers. We <b>only</b> share information with your
                          local Police/Fire/Medical Department.
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="feature-box pr-4">
                        <h3 className="font-size-lg font-weight-bold my-3">
                          <a
                            className="text-first"
                            href="#/"
                            onClick={(e) => e.preventDefault()}
                            title="">
                            End Goal
                          </a>
                        </h3>
                        <p className="text-black-50 font-weight-bold mb-3">
                          We are striving to ensure that our first responders
                          can do their best by efficiently addressing any
                          emergency situation they are called upon
                        </p>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
