import React from 'react';
import {PropagateLoader,FadeLoader} from "react-spinners";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardHeader, UncontrolledTooltip, Button } from 'reactstrap';
import {connect} from 'react-redux'
import {loadHouseHolds} from '../../../reducers/Actions/houseHoldActions'
const LivePreviewExample = (props) => {



  return (
    <>
      <Card className="mb-5">
        <CardHeader className="d-flex align-items-center justify-content-between card-header-alt p-4">
          <div>
            <h6 className="font-weight-bold font-size-lg mb-1 text-black">
              {`My House Holds`}
            </h6>
            <p className="text-black-50 mb-0">Latest created house hold list.</p>
          </div>
          <div className="d-flex align-items-center">
            <small>
            {props.HouseHolds ?  ( props.HouseHolds.length > 0 ? `${props.HouseHolds.length} Registered House Holds` : 
           'No Registered House Holds' ): <div className="d-flex mx-auto align-items-center justify-content-center" style={{width: '72px', height: '5px'}}>
           <FadeLoader small color={'var(--primary)'} loading={true}/>
       </div>}
            </small>
          </div>
        </CardHeader>
        <div className="divider" />
        <div className="divider" />
        <div className="p-4 d-flex justify-content-center flex-wrap">

          {props.HouseHolds ? (props.HouseHolds.length > 0  ? props.HouseHolds.map((house, index) => {

            return (

              <div className="p-3">
              <Button
                outline
                color="dark"
                block
                className="text-left rounded shadow-none py-3 px-3 px-xl-5">
                <div className="d-flex">
                  <div className="d-30 text-white d-flex align-items-center btn-pill justify-content-center rounded-pill mr-3 bg-first">
                    <FontAwesomeIcon icon={['fas', 'home']} />
                  </div>
                  <div>
                    <div className="font-size-lg font-weight-bold">
                      {house.Name}
                    </div>
                    <div className="opacity-8">
                      <span className="opacity-8">{house.Members ? house.Members.length : 0 }</span> Members
                    </div>
                  </div>
                </div>
              </Button>
            </div>
            ) 
          }) :null) :  
          null}
     
          <div className="p-3 d-flex align-items-center justify-content-center">
            <Button
            onClick= {()=> {window.location = '/HouseHolds/Add'}}
              color="neutral-primary"
              id="CreateNewHHt"
              className="d-60 d-flex align-items-center justify-content-center p-0 rounded-pill mx-4">
              <FontAwesomeIcon
                icon={['fas', 'plus']}
                className="font-size-lg"
              />
            </Button>
            <UncontrolledTooltip target="CreateNewHHt">
              Create new House Hold
            </UncontrolledTooltip>
          </div>
        </div>
        <div className="divider" />
        <div className="divider" />
        <div className="card-footer bg-secondary p-3 text-center">
          <Button size="sm" className="py-2 px-4" color="primary" onClick = {()=> {window.location = '/HouseHolds'}}>
            <span className="btn-wrapper--label text-uppercase font-weight-bold">
              View all House Holds
            </span>
          </Button>
        </div>
      </Card>
    </>
  );
}
const MapStateToProps = state => {

  return {
    FirstName : state.UserReducer.First,
    HouseHolds : state.UserReducer.HouseHolds,
    UID : state.UserReducer.ID
    }
  
}

const mapDispatchToProps = (dispatch) => {
  return {
    onHouseHoldFetch : (Members) => {
      dispatch(loadHouseHolds(Members))
    } ,

  }};



export default connect(MapStateToProps,mapDispatchToProps)(LivePreviewExample);